import { Component, OnInit } from '@angular/core';
import { CheckedBag } from 'src/app/model/checked-bag';
import { ChekcedBagService } from 'src/app/services/chekced-bag.service';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { User } from 'src/app/model/user';
import { CheckedBagHelper } from 'src/app/model/checked-bag-helper';
import { NgForm } from '@angular/forms';
import { ErrorModel } from 'src/app/model/error-model';

declare var $: any;
@Component({
  selector: 'app-checked-bag',
  templateUrl: './checked-bag.component.html',
  styleUrls: ['./checked-bag.component.css']
})
export class CheckedBagComponent implements OnInit {

  checkedBagObj = new CheckedBag();
  checkedBagList: CheckedBag[] = [];
  airlineKeysList = [];
  cabinDefinition :string = '';
  route:string = '';
  airline: AirlineSummary[] = [];
  cabinDefnOth:string;
  p: number = 1;
  p1: number = 1;
  userInfo = new User();
  checkedBagHelper = new CheckedBagHelper();
  searchKey = '';
  checkedBagListPrev: any;
  statusMsg: string;
  checkedBagApprovedList: any;
  checkedBagPendingList: any;
  checkedBagApprovedListPrev: string;
  checkedBagPendingListPrev: string;
  flag1:boolean=false;
  airlineCodeReq: boolean = false;
  cabinDefinitionReq: boolean = false;
  routeReq: boolean = false;
  baggagePieceWeightConceptReq: boolean = false;
  baggageMaxDimensionsCmsReq: boolean = false;
  cabinDefinitions = ['Business', 'Economy', 'Economy Basic', 'Economy Light', 'First', 'Premium Economy', 'Others'];
  routeTypes = ['International', 'Domestic', 'Intercontinental', 'Europe', 'Latin America', 'Others'];
  
  routeOth:string;
  routeType:string = '';
  cabinDefn:string = '';
  rejectionJustification: string = '';
  baggagePiecesVal: boolean = false;
  baggageMaxWeightKgVal: boolean = false;
  rejectActionCheck: boolean = false;
  checkedBagObjPrev = new CheckedBag();
  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  parentIdList = [303];
  constructor(private servObj: ChekcedBagService) { }

  ngOnInit() {
    this.onLoad();
  }
  onLoad() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.servObj.loadData(this.userInfo).subscribe(
      data => {
        let checkedBagPendingList = data['Pending'];
        let checkedBagApprovedList = data['Approved'];
        this.checkedBagApprovedListPrev = JSON.stringify(checkedBagApprovedList);
        this.checkedBagPendingListPrev = JSON.stringify(checkedBagPendingList);
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        let airKeys = [];
        for (let index = 0; index < this.airline.length; index++) {
          airKeys.push(this.airline[index].airlineCode);
        }
        if (checkedBagPendingList) {
          for (let i = 0; i < checkedBagPendingList.length; i++) {
            if (this.parentIdList.indexOf(checkedBagPendingList[i].id) < 0) {
              this.checkedBagPendingList = checkedBagPendingList;
            }
          }
        }
        if (checkedBagApprovedList) {
          for (let i = 0; i < checkedBagApprovedList.length; i++) {
            if (this.parentIdList.indexOf(checkedBagApprovedList[i].id) < 0) {
              this.checkedBagApprovedList = checkedBagApprovedList;
            }
          }
        }
        this.airlineKeysList = airKeys.sort();
      }

    );
  }
  editMethod(param) {
    let obj = Object.assign({}, param);
    this.checkedBagObjPrev = obj;
    this.checkedBagObj = param;
    this.checkOthers(param);
    this.flag1=true;
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.checkedBagObjPrev = obj;
    this.checkedBagObj = param;
  }

  saveAction(checkedBagForm: NgForm) {
    let manValid = false;
    if (this.checkedBagObj.route === 'Others') {
      if (this.routeType) {
        this.checkedBagObj.route = this.routeType;
        manValid = true;
      } else {
        manValid = false;
      }
    } else {
      manValid = true;
    }
    if (this.checkedBagObj.cabinDefinition === 'Others') {
      if (this.cabinDefn) {
        this.checkedBagObj.cabinDefinition = this.cabinDefn;
        manValid = true;
      } else {
        manValid = false;
      }
    } else {
      manValid = true;
    }
    if (manValid) {
      if (this.checkedBagObj.baggagePieceWeightConcept) {
        if (this.checkedBagObj.baggagePieceWeightConcept === 'Piece') {
          if (this.checkedBagObj.baggagePieces) {
            this.baggagePiecesVal = false;
            manValid = true;
          } else {
            this.baggagePiecesVal = true;
            manValid = false;
          }

        } else if (this.checkedBagObj.baggagePieceWeightConcept === 'Weight') {
          if (this.checkedBagObj.baggageMaxWeightKg) {
            this.baggageMaxWeightKgVal = false;
            manValid = true;
          } else {
            this.baggageMaxWeightKgVal = true;
            manValid = false;
          }
        }
      }
    }
    if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
      if (this.checkedBagObj && this.checkedBagObj.id > 0 && this.checkedBagObj.userFlag === 'A') {
        this.checkedBagObj.parentId = this.checkedBagObj.id;
        this.checkedBagObj.id = 0;
      }
    }
    if(this.flag1){
      this.checkedBagObj.userFlag  = 'edit';
      this.flag1=false;
      }
      else
      {
        this.checkedBagObj.userFlag  = 'add';
        this.flag1=false;
      }
    
   
    this.checkedBagHelper.checkedBaggage = this.checkedBagObj;
    if (this.checkedBagObjPrev && this.checkedBagObjPrev.id > 0) {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObjPrev;
    } else {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObj;
    }
    this.checkedBagHelper.userDetails = this.userInfo;
    if (checkedBagForm.valid && manValid) {
      this.servObj.saveAction(this.checkedBagHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.checkedBagPendingList = data['Pending'];
            this.checkedBagApprovedList = data['Approved'];
            this.checkedBagApprovedListPrev = JSON.stringify(this.checkedBagApprovedList);
            this.checkedBagPendingListPrev = JSON.stringify(this.checkedBagPendingList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      for (let x in checkedBagForm['_directives']) {
        if (checkedBagForm['_directives'][x]['errors'] && checkedBagForm['_directives'][x]['errors']['required']) {
          if (checkedBagForm['_directives'][x]['name'] === 'airlineCode') {
            this.airlineCodeReq = true;
          }
         
          if (checkedBagForm['_directives'][x]['name'] === 'cabinDefinition') {
            this.cabinDefinitionReq = true;
          }
          
          
          if (checkedBagForm['_directives'][x]['name'] === 'baggagePieceWeightConcept') {
            this.baggagePieceWeightConceptReq = true;
          }
          if (checkedBagForm['_directives'][x]['name'] === 'baggageMaxDimensionsCms') {
            this.baggageMaxDimensionsCmsReq = true;
          }
          else{
            this.baggageMaxDimensionsCmsReq = false;
          }
        }
      }
    }
  }

  deleteAction() {
    this.checkedBagHelper.checkedBaggage = this.checkedBagObj;
    this.checkedBagHelper.userDetails = this.userInfo;
    if (this.checkedBagObjPrev && this.checkedBagObjPrev.id > 0) {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObjPrev;
    } else {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObj;
    }
    this.servObj.deleteAction(this.checkedBagHelper).subscribe(
      data => {
        if (data['Status']) {
          this.checkedBagPendingList = data['Pending'];
          this.checkedBagApprovedList = data['Approved'];
          this.checkedBagApprovedListPrev = JSON.stringify(this.checkedBagApprovedList);
          this.checkedBagPendingListPrev = JSON.stringify(this.checkedBagPendingList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }

        
      }
    );
  }

  addDataIcon(checkedBagForm: NgForm) {
    this.checkedBagObj = new CheckedBag();
    this.checkedBagObjPrev = new CheckedBag();
    this.checkedBagObj.baggagePieceWeightConcept = 'Piece';
    this.baggageMaxDimensionsCmsReq = false;
    this.baggagePieceWeightConceptReq = false;
    this.cabinDefinitionReq = false;
    this.airlineCodeReq = false;
    if (this.userInfo.userType === 'AUTHOR') {
      this.checkedBagObj.airlineCode = this.userInfo.airlineId;
    }
    for (let x in checkedBagForm['control']['controls']) {
      if (x) {
        checkedBagForm['controls'][x].markAsPristine;
        checkedBagForm['controls'][x].markAsUntouched;
        checkedBagForm['controls'][x].valid;
      }
    }
  }

  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.checkedBagPendingList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.checkedBagPendingList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.baggageMaxDimensionsCms && el.baggageMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.baggageMaxWeightKg && el.baggageMaxWeightKg.toLowerCase().includes(value)) ||
              (el.baggagePieceWeightConcept && el.baggagePieceWeightConcept.toLowerCase().includes(value)) ||
              (el.baggagePieces && el.baggagePieces.toLowerCase().includes(value)) ||
              (el.baggageStarGoldAdditionalAllowance && el.baggageStarGoldAdditionalAllowance.toLowerCase().includes(value)) ||
              (el.cabinDefinition && el.cabinDefinition.toLowerCase().includes(value)) ||
              (el.comments && el.comments.toLowerCase().includes(value)) ||
              (el.infantMaxDimensionsCms && el.infantMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.infantMaxWeightKg && el.infantMaxWeightKg.toLowerCase().includes(value)) ||
              (el.infantNoSeat && el.infantNoSeat.toLowerCase().includes(value)) ||
              (el.route && el.route.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.checkedBagPendingList = opt;
      } else {
        let list = JSON.parse(this.checkedBagPendingListPrev);
        this.checkedBagPendingList = list;
      }
    } else if (param === 'approved') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.checkedBagApprovedList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.checkedBagApprovedList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.baggageMaxDimensionsCms && el.baggageMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.baggageMaxWeightKg && el.baggageMaxWeightKg.toLowerCase().includes(value)) ||
              (el.baggagePieceWeightConcept && el.baggagePieceWeightConcept.toLowerCase().includes(value)) ||
              (el.baggagePieces && el.baggagePieces.toLowerCase().includes(value)) ||
              (el.baggageStarGoldAdditionalAllowance && el.baggageStarGoldAdditionalAllowance.toLowerCase().includes(value)) ||
              (el.cabinDefinition && el.cabinDefinition.toLowerCase().includes(value)) ||
              (el.comments && el.comments.toLowerCase().includes(value)) ||
              (el.infantMaxDimensionsCms && el.infantMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.infantMaxWeightKg && el.infantMaxWeightKg.toLowerCase().includes(value)) ||
              (el.infantNoSeat && el.infantNoSeat.toLowerCase().includes(value)) ||
              (el.route && el.route.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.checkedBagApprovedList = opt;
      } else {
        let list = JSON.parse(this.checkedBagApprovedListPrev);
        this.checkedBagApprovedList = list;
      }
    }
  }

  downloadExcel(param) {
    this.servObj.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'CheckedBaggage.xls');
      }
    );
  }


  validationClear(param) {/* 
    if ( param === 'equipmentCodeIATA' ) {
      if(this.checkedBagObj.equipmentCodeIATA){
        this.equipmentCodeIATAValidation = false;
      } else {
        this.equipmentCodeIATAValidation = true;
      }
    } else if ( param === 'equipmentAircraftName' ) {
      if(this.checkedBagObj.equipmentAircraftName){
        this.equipmentAircraftNameValidation = false;
      } else {
        this.equipmentAircraftNameValidation = true;
      }
    }   */
  }

  cancelAction(checkedBagForm: NgForm) {
    if (this.checkedBagObj && this.checkedBagObj.id > 0) {
      let obj = Object.assign({}, this.checkedBagObjPrev);
      this.checkedBagObj = obj;
    } else {
      this.checkedBagObj = new CheckedBag();
      if (this.userInfo.userType === 'AUTHOR') {
        this.checkedBagObj.airlineCode = this.userInfo.airlineId;
        this.checkedBagObj.baggagePieceWeightConcept = 'Piece';
      }
    }
    this.baggageMaxDimensionsCmsReq = false;
    this.baggagePieceWeightConceptReq = false;
    this.routeReq = false;
    this.cabinDefinitionReq = false;
    this.airlineCodeReq = false;
    this.baggageMaxWeightKgVal = false;
    this.cabinDefnOth='';
  this.routeOth='';
  this.cabinDefinition = '';
  this.route='';
  this.routeType='';
      this.cabinDefn='';
    this.baggagePiecesVal = false;
    
  }
  closeAction(checkedBagForm: NgForm) {
    this.checkedBagObj = new CheckedBag();
    this.checkedBagObj.baggagePieceWeightConcept = 'Piece';
    this.baggageMaxDimensionsCmsReq = false;
    this.baggagePieceWeightConceptReq = false;
    this.cabinDefinitionReq = false;
    this.airlineCodeReq = false;
    this.baggageMaxWeightKgVal = false;
    this.routeType='';
      this.cabinDefn='';
    this.cabinDefinition = '';
  this.route='';
    this.baggagePiecesVal = false;
    if (this.checkedBagPendingListPrev) {
      this.checkedBagPendingList = JSON.parse(this.checkedBagPendingListPrev);
    }
    if (this.checkedBagApprovedListPrev) {
      this.checkedBagApprovedList = JSON.parse(this.checkedBagApprovedListPrev);
    }
    for (let x in checkedBagForm['control']['controls']) {

      if (x) {
        checkedBagForm['controls'][x].markAsPristine;
        checkedBagForm['controls'][x].markAsUntouched;
      }
    }
  }
  approveAction(param) {
    this.checkedBagObj = param;
    this.checkedBagHelper.checkedBaggage = this.checkedBagObj;
    this.checkedBagHelper.userDetails = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      if (this.checkedBagObjPrev && this.checkedBagObjPrev.id > 0) {
        this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObjPrev;
      } else {
        this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObj;
      }
      this.checkedBagHelper.checkedBaggage.userFlag = 'app';
      this.servObj.saveAction(this.checkedBagHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.checkedBagPendingList = data['Pending'];
            this.checkedBagApprovedList = data['Approved'];
            this.checkedBagApprovedListPrev = JSON.stringify(this.checkedBagApprovedList);
            this.checkedBagPendingListPrev = JSON.stringify(this.checkedBagPendingList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.servObj.deleteAction(this.checkedBagHelper).subscribe(
        data => {
          if (data['Status']) {
            this.checkedBagPendingList = data['Pending'];
            this.checkedBagApprovedList = data['Approved'];
            this.checkedBagApprovedListPrev = JSON.stringify(this.checkedBagApprovedList);
            this.checkedBagPendingListPrev = JSON.stringify(this.checkedBagPendingList);
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.checkedBagHelper.checkedBaggage = this.checkedBagObj;
    this.checkedBagHelper.userDetails = this.userInfo;
    this.checkedBagObj.userFlag = 'rej';
    if (this.checkedBagObjPrev && this.checkedBagObjPrev.id > 0) {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObjPrev;
    } else {
      this.checkedBagHelper.checkedBaggagePrev = this.checkedBagObj;
    }
    if (this.rejectionJustification) {

      this.servObj.saveAction(this.checkedBagHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.checkedBagPendingList = data['Pending'];
            this.checkedBagApprovedList = data['Approved'];
            this.checkedBagApprovedListPrev = JSON.stringify(this.checkedBagApprovedList);
            this.checkedBagPendingListPrev = JSON.stringify(this.checkedBagPendingList);
            $('#rejectionId').modal('toggle');
            this.statusMsg = 'Mail Sent';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  checkOthers(param: CheckedBag) {
    if (param.cabinDefinition !== 'Business' && param.cabinDefinition !== 'Economy'
      && param.cabinDefinition !== 'Economy Basic' && param.cabinDefinition !== 'Economy Light' && param.cabinDefinition !== 'First'
      && param.cabinDefinition !== 'Premium Economy') {
      this.cabinDefn = param.cabinDefinition;
      this.checkedBagObj.cabinDefinition = 'Others';
    }
    if (param.route !== 'International' && param.route !== 'Domestic' && param.route !== 'Intercontinental'
      && param.route !== 'Europe' && param.route !== 'Latin America') {
      this.routeType = param.route;
      this.checkedBagObj.route = 'Others';
    }
  }
  pieceOrWeight() {
    this.baggageMaxWeightKgVal = false;
    this.baggagePiecesVal = false;
    
  }
  triggerValidation(param) {
    if (param === 'piece') {
      if (this.checkedBagObj.baggagePieceWeightConcept === 'Piece') {
        if (this.checkedBagObj.baggagePieces) {
          this.baggagePiecesVal = false;
        } else {
          this.baggagePiecesVal = true;
        }
      }
    } else if (param === 'weight') {
      if (this.checkedBagObj.baggagePieceWeightConcept === 'Weight') {
        if (this.checkedBagObj.baggageMaxWeightKg) {
          this.baggageMaxWeightKgVal = false;
        } else {
          this.baggageMaxWeightKgVal = true;
        }
      }
    }
    if(param==='airlineCode')
    {

      if(this.checkedBagObj.airlineCode==='airlineCode')
      {
        this.airlineCodeReq=false;
      }
      else{
        this.airlineCodeReq=true;
      }
    }
    if(param==='cabinDefinition')
    {

      if(this.checkedBagObj.cabinDefinition==='cabinDefinition')
      {
        this.cabinDefinitionReq=false;
      }
      else{
        this.cabinDefinitionReq=true;
      }
    }
    if(param==='baggageMaxDimensionsCms')
    {

      if(this.checkedBagObj.cabinDefinition==='baggageMaxDimensionsCms')
      {
        this.baggageMaxDimensionsCmsReq=false;
      }
      else{
        this.baggageMaxDimensionsCmsReq=true;
      }
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.checkedBagObjPrev = obj;
    this.checkedBagObj = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.checkedBagPendingListPrev) {
      this.checkedBagPendingList = JSON.parse(this.checkedBagPendingListPrev);
    }
    if (this.checkedBagApprovedListPrev) {
      this.checkedBagApprovedList = JSON.parse(this.checkedBagApprovedListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.checkedBagApprovedListPrev) {
        let list = JSON.parse(this.checkedBagApprovedListPrev);
        this.checkedBagApprovedList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.checkedBagPendingListPrev) {
        let list = JSON.parse(this.checkedBagPendingListPrev);
        this.checkedBagPendingList = list;
        this.searchData(param);
      }
    }
  }
}
