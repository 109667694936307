import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/model/user';
import { AdduserService } from 'src/app/services/adduser.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ErrorModel } from 'src/app/model/error-model';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './adduser.component.html'
})
export class AdduserComponent implements OnInit {

  adduserObj = new User();
  userList: User[] = [];
  userListPrev: any;
  airlineKeysList = [];
  searchKey = '';
  p: number = 1;
  statusMsg: string;
  airline: any;
  userInfo = new User();
  adduserObjPrev: any;
  uiNamesWAuth = ['ALL', 'Aircraft Type', 'Airline Summary', 'Airport Atlas', 'Call Center List', 'Carry-On Bag', 'Checked Bag',
    'FFP Benefits', 'FFP Overview', 'Special Checked Baggage'];
  uiNamesFAuth = ['Airline Summary', 'Call Center List', 'Carry-On Bag', 'Checked Bag',
    'FFP Benefits', 'FFP Overview', 'Special Checked Baggage'];
  uiNames = [];
  uiNamesList = [];
  uiNameList: string;
  userEmailIdReq: boolean = false;
  userEmailNameReq: boolean = false;
  userTypeReq: boolean = false;
  airlineIdReq: boolean = false;
  errorModel = new ErrorModel();
  searchAirlineTrigger: boolean = false;
  emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  userEmailRegexVal: boolean = false;
  uiNameReq: boolean;
  constructor(private userService: AdduserService, private router: Router) { }
  ngOnInit() {
    this.loadFunction();
  }

  loadFunction() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.userService.loadUsersFunction().subscribe(
      data => {
        let users = [];
        users = data['UserDetailsList'];
        this.userList = users.sort();
        this.userListPrev = JSON.stringify(this.userList);
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        let airkeys = [];
        if (this.airline && this.airline.length > 0) {
          for (let i = 0; i < this.airline.length; i++) {
            airkeys.push(this.airline[i].airlineCode);
          }
          this.airlineKeysList = airkeys.sort();
        }
      }
    );
  }

  adduserAction(param) {
    //this.adduserObj = param;
    if (!this.adduserObj.userPassword) {
      this.adduserObj.userPassword = 'star123';
    }
    if (this.adduserObj.userEmailId && this.emailRegex.test(this.adduserObj.userEmailId)) {
      this.userEmailRegexVal = false;
    } else {
      this.userEmailRegexVal = true;
    }
    this.adduserObj.uiName = this.uiNameList;
    this.uiNamesList = [];
    if (this.adduserObj.userEmailId && this.adduserObj.userEmailId.length <= 149 &&
      this.adduserObj.userName && this.adduserObj.userName.length <= 49 &&
      this.adduserObj.userType && this.adduserObj.airlineId && !this.userEmailRegexVal &&
      this.adduserObj.uiName) {
      this.userService.adduserFunction(this.adduserObj).subscribe(
        data => {
          if (data['Status']) {
            this.userList = data['UserDetailsList'];
            this.userListPrev = JSON.stringify(this.userList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            $('#duplicatonId').modal('toggle');
          }
          this.searchKey = '';
        }
      );
    } else {
      if (this.adduserObj.userEmailId) {
        this.userEmailIdReq = false;
      } else {
        this.userEmailRegexVal = false;
        this.userEmailIdReq = true;
      }
      if (this.adduserObj.userName) {
        this.userEmailNameReq = false;
      } else {
        this.userEmailNameReq = true;
      }
      if (this.adduserObj.userType) {
        this.userTypeReq = false;
      } else {
        this.userTypeReq = true
      }
      if (this.adduserObj.airlineId) {
        this.airlineIdReq = false;
      } else {
        this.airlineIdReq = true;
      }
      if (this.adduserObj.uiName) {
        this.uiNameReq = false;
      } else {
        this.uiNameReq = true;
      }

    }
  }
  addDataIcon(param) {
    this.adduserObj = new User();
    this.uiNamesList = [];
    for (let i = 0; i < this.uiNamesWAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
    for (let i = 0; i < this.uiNamesFAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
  }
  deleteAction() {
    this.userService.deleteUserFunction(this.adduserObj).subscribe(
      data => {
        if (data['Status']) {
          this.userList = data['UserDetailsList'];
          this.userListPrev = JSON.stringify(this.userList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
          this.searchKey = '';
        }
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  searchData() {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (this.searchKey) {
      if (!this.searchAirlineTrigger) {
        opt = this.userList.filter(function (el, idx, array) {
          if ((el.airlineId && el.airlineId.toLowerCase().includes(value))) {
            return el;
          }
        });
      } else {
        opt = this.userList.filter(function (el, idx, array) {
          if ((el.airlineId && el.airlineId.toLowerCase().includes(value)) ||
            (el.userEmailId && el.userEmailId.toLowerCase().includes(value)) ||
            (el.userName && el.userName.toLowerCase().includes(value)) ||
            (el.userType && el.userType.toLowerCase().includes(value))) {
            return el;
          }
        });
      }
      this.p = 1;
      this.userList = opt;
    } else {
      let list = JSON.parse(this.userListPrev);
      this.userList = list;
    }
  }
  userTypeChange() {
    if (this.adduserObj.userType) {
      if (this.adduserObj.userType !== 'AUTHOR') {
        this.adduserObj.airlineId = 'ALL';
      } else if (this.adduserObj.userType === 'AUTHOR') {
        this.adduserObj.airlineId = '';
      }
    }
    this.uiNameList = '';
    this.uiNamesList = [];
    for (let i = 0; i < this.uiNamesWAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
    for (let i = 0; i < this.uiNamesFAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
  }
  editMethod(param) {
    let obj = Object.assign({}, param);
    this.adduserObjPrev = obj;
    this.adduserObj = param;
    this.setCheckedValues(param);
  }
  setCheckedValues(param: User) {
    this.uiNameReq = true;
    if (param.uiName) {
      let uinames = param.uiName.split(';');
      if (param.userType === 'AUTHOR') {
        for (let i = 0; i < this.uiNamesFAuth.length; i++) {
          for (let j = 0; j < uinames.length; j++) {
            if (uinames[j] === this.uiNamesFAuth[i]) {
              
             // if(this.uiNameList.indexOf(uinames[j]) === -1){
                this.uiNames[i] = true;
                this.uiNamesList.push(uinames[j]);
              //}
              //this.uiNamesList.push(uinames[j]);
              if(uinames[j] === 'ALL') {
                this.uiNamesList.splice(this.uiNamesList.indexOf(uinames[j]), 1);
              }
            }
          }
        }
      } else if (param.userType !== 'AUTHOR') {
        for (let i = 0; i < this.uiNamesWAuth.length; i++) {
          for (let j = 0; j < uinames.length; j++) {
            if (uinames[j] === this.uiNamesWAuth[i]) {
              
              //if(this.uiNameList.indexOf(uinames[j]) === -1){
                this.uiNames[i] = true;
                this.uiNamesList.push(uinames[j]);
              //}
              //this.uiNamesList.push(uinames[j]);
            }
          }
        }
        for (let i = 0; i < this.uiNamesWAuth.length; i++) {
          if (param.uiName === 'ALL') {
            this.uiNames[i] = true;
            if (i > 0) {
              $("#chkBox" + i).prop('disabled', true);
            }
          } else {
            $("#chkBox" + i).prop('disabled', false);
          }
        }
      }
    }
  }
  deleteConfirmation(param) {
    this.adduserObj = param;
  }
  cancelAction() {
    if (this.adduserObj && this.adduserObj.userId > 0) {
      let obj = Object.assign({}, this.adduserObjPrev);
      this.adduserObj = obj;
      //this.uiNames.fill(false);
      this.uiNamesList = [];
      this.uiNameList = '';
      for (let i = 0; i < this.uiNamesWAuth.length; i++) {
        this.uiNames[i] = false;
        $("#chkBox" + i).prop('disabled', false);
      }
      for (let i = 0; i < this.uiNamesFAuth.length; i++) {
        this.uiNames[i] = false;
        $("#chkBox" + i).prop('disabled', false);
      }
      //this.setCheckedValues(obj);
    } else {
      this.adduserObj = new User();
      for (let i = 0; i < this.uiNamesWAuth.length; i++) {
        this.uiNames[i] = false;
        $("#chkBox" + i).prop('disabled', false);
      }
      for (let i = 0; i < this.uiNamesFAuth.length; i++) {
        this.uiNames[i] = false;
        $("#chkBox" + i).prop('disabled', false);
      }
    }
    this.userEmailIdReq = false;
    this.userEmailNameReq = false;
    this.userTypeReq = false;
    this.airlineIdReq = false;
    this.userEmailRegexVal = false;
  }
  closeAction() {
    this.adduserObj = new User();
    this.adduserObj.userId = 0;
    this.uiNamesList = [];
    this.userEmailIdReq = false;
    this.userEmailNameReq = false;
    this.userTypeReq = false;
    this.airlineIdReq = false;
    this.userEmailRegexVal = false;
    if (this.userListPrev) {
      this.userList = JSON.parse(this.userListPrev);
    }
    for (let i = 0; i < this.uiNamesWAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
    for (let i = 0; i < this.uiNamesFAuth.length; i++) {
      this.uiNames[i] = false;
      $("#chkBox" + i).prop('disabled', false);
    }
  }
  tierBenefitsChecked(checked, val, idx) {
    this.uiNameList = '';
    if (checked) {
      this.uiNameReq = false;
      this.uiNamesList.push(val);
    } else {
      this.uiNamesList.splice(this.uiNamesList.indexOf(val), 1);
    }
    for (let i = 0; i < this.uiNamesList.length; i++) {
      if (i === this.uiNamesList.length - 1) {
        this.uiNameList += this.uiNamesList[i];
      } else {
        this.uiNameList += this.uiNamesList[i] + ';';
      }
    }
    if (val === 'ALL') {
      if (checked) {
        for (let i = 0; i < this.uiNamesWAuth.length; i++) {
          this.uiNames[i] = true;
          if (i > 0) {
            $("#chkBox" + i).prop('disabled', true);
          }
        }
      } else {
        this.uiNameReq = true;
        for (let i = 0; i < this.uiNamesWAuth.length; i++) {
          this.uiNames[i] = false;
          $("#chkBox" + i).prop('disabled', false);
        }
      }
      this.uiNameList = 'ALL';
    }
    //this.uiNamesList = [];
  }
  triggerValidation(param) {
    if (param === 'uemail') {
      if (this.adduserObj.userEmailId) {
        this.userEmailIdReq = false;
      } else {
        this.userEmailRegexVal = false;
        this.userEmailIdReq = true;
      }
    }
    if (param === 'uname') {
      if (this.adduserObj.userName) {
        this.userEmailNameReq = false;
      } else {
        this.userEmailNameReq = true;
      }
    }
    if (param === 'uytpe') {
      if (this.adduserObj.userType) {
        this.userTypeReq = false;
      } else {
        this.userTypeReq = true
      }
    }
    if (param === 'aircode') {
      if (this.adduserObj.airlineId) {
        this.airlineIdReq = false;
      } else {
        this.airlineIdReq = true;
      }
    }
  }
  resetList() {
    if (this.userListPrev) {
      this.userList = JSON.parse(this.userListPrev);
    }
  }
  triggerSearchAirline() {
    if (this.userListPrev) {
      let list = JSON.parse(this.userListPrev);
      this.userList = list;
      this.searchData();
    }
  }
  emailValidation() {
    if (this.adduserObj.userEmailId && this.emailRegex.test(this.adduserObj.userEmailId)) {
      this.userEmailRegexVal = false;
    } else {
      this.userEmailRegexVal = true;
    }
  }
}
@Pipe({ name: 'replaceUnderscore' })
export class ReplaceSemiColonPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/;/g, "\n") : value;
  }
}