import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';


declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user = new User();
  uname: string;
  pwd: string;
  statusMsg: string;
  passwordReq: boolean = false;
  usernameReq: boolean = false;
  username1Req: boolean = false;
  username:string;

  emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  passwordRegexVal: boolean = false;
  constructor(private loginService: LoginService, private route: Router) { }

  ngOnInit() {
  }
  loginFunction(loginForm: NgForm) {
    if(this.user.userEmailId && this.emailRegex.test(this.user.userEmailId)){
      this.passwordRegexVal = false;
    } else {
      this.passwordRegexVal = true;
    }
    if (loginForm.valid && !this.passwordRegexVal) {
      this.loginService.loginFunction(this.user).subscribe(
        data => {
          if (data['Status']) {
            this.loginService.userInfo = data['Details'];
            sessionStorage.setItem('UserInfo', JSON.stringify(data['Details']));
            this.route.navigateByUrl('/home');
          } else {
            this.user.userPassword = '';
            this.user.airlineId = '';
            this.user.uiName = '';
            this.user.userId = 0;
            this.user.userName = '';
            this.user.userType = '';
            this.statusMsg = 'Incorrect username or password.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } 
    else {
      for (let x in loginForm['_directives']) {
        if (loginForm['_directives'][x]['errors'] && loginForm['_directives'][x]['errors']['required']) {
          if (loginForm['_directives'][x]['name'] === 'password') {
            this.passwordReq = true;
          }
          if (loginForm['_directives'][x]['name'] === 'username') {
            this.usernameReq = true;
          }
        }
      }
    }
    
  }
  cancelFunction() {
    this.user = new User();
    this.passwordReq = false;
    this.usernameReq = false;
    this.passwordRegexVal = false;
  }

  emailValidation() {
    if(this.user.userEmailId && this.emailRegex.test(this.user.userEmailId)){
      this.passwordRegexVal = false;
    } else {
      this.passwordRegexVal = true;
    }
  }


}
