import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { NgForm } from '@angular/forms';
import { CarryonBag } from 'src/app/model/carryon-bag';
import { CarryonBaggageService } from 'src/app/services/carryon-bag.service';
import { CarryonBagHelper } from 'src/app/model/carryon-bag-helper';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { Router } from '@angular/router';
import { ErrorModel } from 'src/app/model/error-model';

declare var $: any;
@Component({
  selector: 'app-carryon-bag',
  templateUrl: './carryon-bag.component.html'
})
export class CarrryonBagComponent implements OnInit {

  carryonBagObj = new CarryonBag();
  carryonBagObjPrev = new CarryonBag();
  carryonBagList: CarryonBag[] = [];
  p: number = 1;
  p1: number = 1;
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  flag1:boolean=false;
  equipmentAircraftlength: boolean;
  rejectActionCheck: boolean;
  userInfo = new User();
  carryonBagHelper = new CarryonBagHelper();
  searchKey = '';
  carryonBagListPrev: CarryonBag[] = [];
  approvedCarryonBaggageList: CarryonBag[] = [];
  pendingCarryonBaggageList: CarryonBag[] = [];
  pendingCarryonBaggageListPrev: any;
  approvedCarryonBaggageListPrev: any;
  statusMsg: string = '';
  airlineCodeValidation: boolean;
  cabinValidation: boolean;
  cabinlength: boolean;
  routeValidation: boolean;
  routelength: boolean;

  baggagePieceWeightConceptValidation: boolean;
  baggagePieceWeightConceptlength: boolean;
  baggagePiecesValidation: boolean;
  baggagePieceslength: boolean;
  baggageMaxDimensionsCmsValidation: boolean;
  baggageMaxDimensionsCmslength: boolean;
  baggageMaxWeightKgValidation: boolean;
  baggageMaxWeightKglength: boolean;
  baggageStarGoldAdditionalAllowanceValidation: boolean;
  baggageStarGoldAdditionalAllowancelength: boolean;
  baggageStarGoldAdditionalAllowance:boolean;
  personalItemsValidation: boolean;
  personalItemslength: boolean;
  infantNoSeatValidation: boolean;
  infantNoSeatlength: boolean;
  infantWithSeatValidation: boolean;
 
  infanWithSeatlength: boolean;
 
  commentsValidation: boolean;
  commentslength: boolean;
  baggageStarGoldAdditionalAllowancePattern: boolean;
  regEx = /^[a-zA-Z0-9]+$/g;
  cabinDefinitions = ['Business', 'Economy', 'Economy Basic', 'Economy Light', 'First', 'Premium Economy', 'Others'];
  routeTypes = ['International', 'Domestic', 'Intercontinental', 'Europe', 'Latin America', 'Others'];
  routeType:string = '';
  cabinDefn:string = '';

  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  constructor(private carryonBaggageService: CarryonBaggageService, private router: Router) { }

  airlineKeysList = [];
  airline: AirlineSummary[] = [];

  ngOnInit() {
    this.profileInfoClose();
    this.onLoad();
  }
  onLoad() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.carryonBaggageService.loadData(this.userInfo).subscribe(
      data => {
        this.carryonBagObj.baggagePieceWeightConcept = 'Piece';
        this.pendingCarryonBaggageList = data['Pending'];
        this.approvedCarryonBaggageList = data['Approved'];
        this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
        this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
        this.paginationTrigger('pending');
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        let airKeys = [];
        if (this.airline && this.airline.length) {
          for (let index = 0; index < this.airline.length; index++) {
            airKeys.push(this.airline[index].airlineCode);
          }
        }
        this.airlineKeysList = airKeys.sort();
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list, usrType?: any) {

    let obj = Object.assign({}, param);
    this.carryonBagObjPrev = obj;
    this.carryonBagObj = param;
    this.checkOthers(param);
    this.flag1=true;
    
  }
  

  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.carryonBagObjPrev = obj;
    this.carryonBagObj = param;
  }
  saveAction(carryonBagForm: NgForm, status?: any) {

    /* if(this.carryonBagObj.id===0){
      if(this.carryonBagObj.baggagePieceWeightConcept==='Piece'){
        this.carryonBagObj.baggageMaxWeightKg = '';
      } else if(this.carryonBagObj.baggagePieceWeightConcept==='Weight') {
        this.carryonBagObj.baggagePieces = '';
      }
    } */
    if (this.carryonBagObj.route === 'Others') {
      this.carryonBagObj.route = this.routeType;
    }
    if (this.carryonBagObj.cabinDefinition === 'Others') {
      this.carryonBagObj.cabinDefinition = this.cabinDefn;
    }
    if ((this.carryonBagObj.airlineCode &&
      this.carryonBagObj.cabinDefinition && this.carryonBagObj.cabinDefinition.length <= 100 &&
      this.carryonBagObj.route && this.carryonBagObj.route.length <= 100 &&
      this.carryonBagObj.baggagePieceWeightConcept &&
      this.carryonBagObj.baggageMaxDimensionsCms && this.carryonBagObj.baggageMaxDimensionsCms.length <= 100 &&
      ((this.carryonBagObj.baggagePieceWeightConcept === 'Piece' && this.carryonBagObj.baggagePieces
        && this.carryonBagObj.baggagePieces.length <= 100) || (this.carryonBagObj.baggagePieceWeightConcept === 'Weight' &&
          this.carryonBagObj.baggageMaxWeightKg && this.carryonBagObj.baggageMaxWeightKg.length <= 100))) && (
        ((this.carryonBagObj.personalItems && this.carryonBagObj.personalItems.length <= 100) ||
          (this.carryonBagObj.infantNoSeat && this.carryonBagObj.infantNoSeat.length <= 200) ||
         
          (this.carryonBagObj.infantWithSeat && this.carryonBagObj.infantWithSeat.length <= 100) ||
          (this.carryonBagObj.comments && this.carryonBagObj.comments.length <= 1000)) ||
        !((this.carryonBagObj.personalItems ) ||
          (this.carryonBagObj.infantNoSeat) ||
          (this.carryonBagObj.infantWithSeat ) ||
          (this.carryonBagObj.comments )))) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.carryonBagObj && this.carryonBagObj.id > 0 && this.carryonBagObj.userFlag === 'A') {
          this.carryonBagObj.parentId = this.carryonBagObj.id;

         this.carryonBagObj.id=0;
        }
      }
      this.carryonBagHelper.carryonBaggage = this.carryonBagObj;
      if (this.carryonBagObjPrev && this.carryonBagObjPrev.id > 0) {
        this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObjPrev;
      } else {
        this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObj;
      }
      this.carryonBagHelper.userDetails = this.userInfo;
      if(this.flag1){
      this.carryonBagHelper.carryonBaggage.userFlag = 'edit';
      this.flag1=false;
      }
      else
      {
        this.carryonBagHelper.carryonBaggage.userFlag = 'add';
        this.flag1=false;
      }
      this.carryonBaggageService.saveAction(this.carryonBagHelper, 'ejb').subscribe(
        data => {
          if (data['Status']) {
            this.pendingCarryonBaggageList = data['Pending'];
            this.approvedCarryonBaggageList = data['Approved'];
            this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
            this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
           
          }
        }
      );
    } else {
      if (!this.carryonBagObj.airlineCode) {
        this.airlineCodeValidation = true;
      }
      else {
        this.airlineCodeValidation = false;
      }
      
      if (!this.carryonBagObj.cabinDefinition) {
        this.cabinValidation = true;
       
           
          } else {
            this.cabinValidation = false;
          }
        
      
     
      if (!this.carryonBagObj.route) {
        this.routeValidation = true;
       
      } 
      else {
        this.routeValidation = false;
      } 
      
      if (this.carryonBagObj.cabinDefinition.length > 100) {
        this.cabinlength = true;
      } else {
        this.cabinlength = false;
      }


      /* if (!this.carryonBagObj.route) {
        this.routeValidation = true;
      } else {
        this.routeValidation = false;
      } */
      if (!this.carryonBagObj.baggagePieceWeightConcept) {
        this.baggagePieceWeightConceptValidation = true;
      } else {
        this.baggagePieceWeightConceptValidation = false;
      }
      if (!this.carryonBagObj.baggagePieces) {
        this.baggagePiecesValidation = true;
      } else {
        this.baggagePiecesValidation = false;
      }
      if (!this.carryonBagObj.baggageMaxDimensionsCms) {
        this.baggageMaxDimensionsCmsValidation = true;
      } else {
        this.baggageMaxDimensionsCmsValidation = false;
      }
      if (!this.carryonBagObj.baggageMaxWeightKg) {
        this.baggageMaxWeightKgValidation = true;
      } else {
        this.baggageMaxWeightKgValidation = false;
      }
      if (!this.carryonBagObj.baggageStarGoldAdditionalAllowance) {
        this.baggageStarGoldAdditionalAllowanceValidation = true;
      } else {
        this.baggageStarGoldAdditionalAllowanceValidation = false;
      }
      if (!this.carryonBagObj.infantNoSeat) {
        this.infantNoSeatValidation = true;
      } else {
        this.infantNoSeatValidation = false;
      }
      if (!this.carryonBagObj.infantWithSeat) {
        this.infantWithSeatValidation = true;
      } else {
        this.infantWithSeatValidation = false;
      }
      if (!this.carryonBagObj.comments) {
        this.commentsValidation = true;
      } else {
        this.commentsValidation = false;
      }
    }
  }

  deleteAction() {
    this.carryonBagHelper.carryonBaggage = this.carryonBagObj;
    this.carryonBagHelper.userDetails = this.userInfo;
    if (this.carryonBagObjPrev && this.carryonBagObjPrev.id > 0) {
      this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObjPrev;
    } else {
      this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObj;
    }
    this.carryonBaggageService.deleteAction(this.carryonBagHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingCarryonBaggageList = data['Pending'];
          this.approvedCarryonBaggageList = data['Approved'];
          this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
          this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }

  addDataIcon(carryonBagForm: NgForm) {
    this.carryonBagObj = new CarryonBag();
    this.carryonBagObjPrev = new CarryonBag();
    this.carryonBagObj.baggagePieceWeightConcept = 'Piece';
    this.carryonBagObj.id = 0;
    this.airlineCodeValidation = false;
    this.cabinValidation = false;
    
    this.routeValidation = false;
    this.baggagePieceWeightConceptValidation = false;
    this.baggagePiecesValidation = false;
    this.baggageMaxDimensionsCmsValidation = false;
    this.baggageMaxWeightKgValidation = false;
    this.baggageStarGoldAdditionalAllowanceValidation = false;
    this.personalItemsValidation = false;
    this.infantNoSeatValidation = false;
    this.infantWithSeatValidation = false;
    this.commentsValidation = false;
    if (this.userInfo.userType === 'AUTHOR') {
      this.carryonBagObj.airlineCode = this.userInfo.airlineId;
    }
    carryonBagForm.control.markAsUntouched;
    carryonBagForm.control.markAsPristine;
  }

  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param == 'pending') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingCarryonBaggageList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.pendingCarryonBaggageList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.cabinDefinition && el.cabinDefinition.toLowerCase().includes(value)) ||
              (el.route && el.route.toLowerCase().includes(value)) ||
              (el.baggagePieceWeightConcept != null && el.baggagePieceWeightConcept && el.baggagePieceWeightConcept.toLowerCase().includes(value)) ||
              (el.baggagePieces != null && el.baggagePieces && el.baggagePieces.toLowerCase().includes(value)) ||
              (el.baggageMaxDimensionsCms != null && el.baggageMaxDimensionsCms && el.baggageMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.baggageMaxWeightKg != null && el.baggageMaxWeightKg && el.baggageMaxWeightKg.toLowerCase().includes(value)) ||
              (el.baggageStarGoldAdditionalAllowance != null && el.baggageStarGoldAdditionalAllowance && el.baggageStarGoldAdditionalAllowance.toLowerCase().includes(value)) ||
              (el.personalItems != null && el.personalItems && el.personalItems.toLowerCase().includes(value)) ||
              (el.infantNoSeat != null && el.infantNoSeat && el.infantNoSeat.toLowerCase().includes(value)) ||
              (el.infantWithSeat != null && el.infantWithSeat && el.infantWithSeat.toLowerCase().includes(value)) ||
              (el.comments != null && el.comments && el.comments.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingCarryonBaggageList = opt;
      } else {
        let list = JSON.parse(this.pendingCarryonBaggageListPrev);
        this.pendingCarryonBaggageList = list;
      }
    }
    else if (param == 'approved') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedCarryonBaggageList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.approvedCarryonBaggageList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.cabinDefinition && el.cabinDefinition.toLowerCase().includes(value)) ||
              (el.route && el.route.toLowerCase().includes(value)) ||
              (el.baggagePieceWeightConcept != null && el.baggagePieceWeightConcept && el.baggagePieceWeightConcept.toLowerCase().includes(value)) ||
              (el.baggagePieces != null && el.baggagePieces && el.baggagePieces.toLowerCase().includes(value)) ||
              (el.baggageMaxDimensionsCms != null && el.baggageMaxDimensionsCms && el.baggageMaxDimensionsCms.toLowerCase().includes(value)) ||
              (el.baggageMaxWeightKg != null && el.baggageMaxWeightKg && el.baggageMaxWeightKg.toLowerCase().includes(value)) ||
              (el.baggageStarGoldAdditionalAllowance != null && el.baggageStarGoldAdditionalAllowance && el.baggageStarGoldAdditionalAllowance.toLowerCase().includes(value)) ||
              (el.personalItems != null && el.personalItems && el.personalItems.toLowerCase().includes(value)) ||
              (el.infantNoSeat != null && el.infantNoSeat && el.infantNoSeat.toLowerCase().includes(value)) ||
              (el.infantWithSeat != null && el.infantWithSeat && el.infantWithSeat.toLowerCase().includes(value)) ||
              (el.comments != null && el.comments && el.comments.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedCarryonBaggageList = opt;
      } else {
        let list = JSON.parse(this.approvedCarryonBaggageListPrev);
        this.approvedCarryonBaggageList = list;
      }
    }
  }
  downloadExcel(param) {
    this.carryonBaggageService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'CarryonBaggage.xls');

      }
    );
  }
  validationClear(param) {
    if (param === 'airlineCode') {
      if (this.carryonBagObj.airlineCode) {
        this.airlineCodeValidation = false;
      } else {
        this.airlineCodeValidation = true;
      }
    }
    if (param === 'cabinDefinition') {
      if (this.carryonBagObj.cabinDefinition) {
        this.cabinValidation = false;
       
      } else {
        this.cabinValidation = true;
        
      }
      if (this.carryonBagObj.cabinDefinition.length <= 100) {
        this.cabinlength = false;
      } else {
        this.cabinlength = true;
      }
    }
    if (param === 'route') {
      if (this.carryonBagObj.route) {
        this.routeValidation = false;
        
      } else {
        this.routeValidation = true;
      }
      if (this.carryonBagObj.route.length <= 100) {
        this.routelength = false;
      } else {
        this.routelength = true;
      }
    }
    if (param === 'baggagePieceWeightConcept') {
      if (this.carryonBagObj.baggagePieceWeightConcept) {
        this.baggagePieceWeightConceptValidation = false;
      } else {
        this.baggagePieceWeightConceptValidation = true;
      }
      if (this.carryonBagObj.baggagePieceWeightConcept.length <= 100) {
        this.baggagePieceWeightConceptlength = false;
      } else {
        this.baggagePieceWeightConceptlength = true;
      }
    }
    if (param === 'baggagePieces') {
      if (this.carryonBagObj.baggagePieceWeightConcept === 'Piece') {
        if (this.carryonBagObj.baggagePieces) {
          this.baggagePiecesValidation = false;
        } else {
          this.baggagePiecesValidation = true;
        }
        if (this.carryonBagObj.baggagePieces.length <= 100) {
          this.baggagePieceslength = false;
        } else {
          this.baggagePieceslength = true;
        }
        
      } 
        this.baggagePieceslength = false;
        this.baggagePiecesValidation = false;
      }
    
    if (param === 'baggageMaxDimensionsCms') {
      if (this.carryonBagObj.baggageMaxDimensionsCms) {
        this.baggageMaxDimensionsCmsValidation = false;
      } else {
        this.baggageMaxDimensionsCmsValidation = true;
      }
      if (this.carryonBagObj.baggageMaxDimensionsCms.length <= 100) {
        this.baggageMaxDimensionsCmslength = false;
      } else {
        this.baggageMaxDimensionsCmslength = true;
      }
    }
    if (param === 'baggageMaxWeightKg') {
      if (this.carryonBagObj.baggagePieceWeightConcept === 'Weight') {
        if (this.carryonBagObj.baggageMaxWeightKg) {
          this.baggageMaxWeightKgValidation = false;
        } else {
          this.baggageMaxWeightKgValidation = true;
        }
        if (this.carryonBagObj.baggageMaxWeightKg.length <= 100) {
          this.baggageMaxWeightKglength = false;
        } else {
          this.baggageMaxWeightKglength = true;
        }
        
      } else {
        this.baggageMaxWeightKgValidation = false;
        this.baggageMaxWeightKglength = false;
      }
    }
    if (param === 'baggageStarGoldAdditionalAllowance') {
      let regBoolean = this.regEx.test(this.carryonBagObj.baggageStarGoldAdditionalAllowance);
      if (this.carryonBagObj.baggageStarGoldAdditionalAllowance.length <= 100) {
        this.baggageStarGoldAdditionalAllowancelength = false;
      } else {
        this.baggageStarGoldAdditionalAllowancelength = true;
      }
      if (regBoolean) {
        this.baggageStarGoldAdditionalAllowancePattern = false;
      } else {
        this.baggageStarGoldAdditionalAllowancePattern = true;
      }
    }
    if (param === 'personalItems') {
      if (this.carryonBagObj.personalItems.length <= 100) {
        this.personalItemslength = false;
      } else {
        this.personalItemslength = true;
      }
    }
    if (param === 'infantNoSeat') {
      if (this.carryonBagObj.infantNoSeat.length <= 200) {
        this.infantNoSeatlength = false;
      } else {
        this.infantNoSeatlength = true;
      }
    }
    if (param === 'infantWithSeat') {
      if (this.carryonBagObj.infantWithSeat.length <= 100) {
        this.infanWithSeatlength = false;
      } else {
        this.infanWithSeatlength = true;
      }
    }
    if (param === 'comments') {
      if (this.carryonBagObj.comments.length <= 1000) {
        this.commentslength = false;
      } else {
        this.commentslength = true;
      }
    }
  }

  cancelAction() {
    if (this.carryonBagObj.id > 0) {
      let obj = Object.assign({}, this.carryonBagObjPrev);
      this.carryonBagObj = obj;
    } else {
      this.carryonBagObj.airlineCode = '';
      this.carryonBagObj.cabinDefinition = '';
      this.carryonBagObj.route = '';
      this.carryonBagObj.baggageMaxDimensionsCms = '';
      this.carryonBagObj.baggageMaxWeightKg = '';
      this.carryonBagObj.baggagePieceWeightConcept = 'Piece';
      this.carryonBagObj.baggagePieces = '';
      this.carryonBagObj.baggageStarGoldAdditionalAllowance = '';
      this.carryonBagObj.personalItems = '';
      this.carryonBagObj.infantNoSeat = '';
      this.carryonBagObj.infantWithSeat = '';
      this.carryonBagObj.comments = '';
     
      this.carryonBagObj.id = 0;
    
  
      this.routeType='';
      this.cabinDefn='';
      
      if (this.userInfo.userType === 'AUTHOR') {
        this.carryonBagObj.airlineCode = this.userInfo.airlineId;
      }
    }
    this.cabinlength = false;
    this.routeValidation = false;
    this.cabinValidation=false;
    this.routelength = false;
    this.baggagePieceWeightConceptValidation = false;
    this.baggagePieceWeightConceptlength = false;
    this.baggagePiecesValidation = false;
    this.baggagePieceslength = false;
    this.baggageMaxDimensionsCmsValidation = false;
    this.baggageMaxDimensionsCmslength = false;
    this.baggageMaxWeightKgValidation = false;
    this.baggageMaxWeightKglength = false;
    this.baggageStarGoldAdditionalAllowanceValidation = false;
    this.baggageStarGoldAdditionalAllowancelength = false;
    this.personalItemsValidation = false;
    this.personalItemslength = false;
    this.infantNoSeatValidation = false;
    this.infantNoSeatlength = false;
    this.infantWithSeatValidation = false;
    this.infanWithSeatlength = false;
    this.commentsValidation = false;
    this.commentslength = false;
    this.baggageStarGoldAdditionalAllowancePattern = false;
    this.airlineCodeValidation = false;
    
   
  }
  closeAction() {
    this.carryonBagObj.id = 0;
    this.cabinlength = false;
    this.routeValidation = false;
    
    this.routelength = false;
    this.baggagePieceWeightConceptValidation = false;
    this.baggagePieceWeightConceptlength = false;
    this.baggagePiecesValidation = false;
    this.baggagePieceslength = false;
    this.baggageMaxDimensionsCmsValidation = false;
    this.baggageMaxDimensionsCmslength = false;
    this.baggageMaxWeightKgValidation = false;
    this.baggageMaxWeightKglength = false;
    this.baggageStarGoldAdditionalAllowanceValidation = false;
    this.baggageStarGoldAdditionalAllowancelength = false;
    this.personalItemsValidation = false;
    this.cabinDefn='';
    this.routeType='';
    this.personalItemslength = false;
    this.infantNoSeatValidation = false;
    this.infantNoSeatlength = false;
    this.infantWithSeatValidation = false;
    this.infanWithSeatlength = false;
    this.commentsValidation = false;
    
    this.commentslength = false;
    this.baggageStarGoldAdditionalAllowancePattern = false;
    this.airlineCodeValidation = false;
    this.cabinValidation = false;
    this.carryonBagObj = this.carryonBagObjPrev;
    if (this.pendingCarryonBaggageListPrev) {
      this.pendingCarryonBaggageList = JSON.parse(this.pendingCarryonBaggageListPrev);
    }
    if (this.approvedCarryonBaggageListPrev) {
      this.approvedCarryonBaggageList = JSON.parse(this.approvedCarryonBaggageListPrev);
    }
  }
  approveAction(param) {
    this.carryonBagObj = param;
    this.carryonBagHelper.carryonBaggage = this.carryonBagObj;
    this.carryonBagHelper.userDetails = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.carryonBagHelper.carryonBaggage.userFlag = 'app';
      this.carryonBaggageService.saveAction(this.carryonBagHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingCarryonBaggageList = data['Pending'];
            this.approvedCarryonBaggageList = data['Approved'];
            this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
            this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.carryonBaggageService.deleteAction(this.carryonBagHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingCarryonBaggageList = data['Pending'];
            this.approvedCarryonBaggageList = data['Approved'];
            this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
            this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.carryonBagHelper.carryonBaggage = this.carryonBagObj;
    this.carryonBagHelper.userDetails = this.userInfo;
    if (this.carryonBagObjPrev && this.carryonBagObjPrev.id > 0) {
      this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObjPrev;
    } else {
      this.carryonBagHelper.carryonBaggagePrev = this.carryonBagObj;
    }
    this.carryonBagHelper.carryonBaggage.userFlag = 'rej';
    if (this.rejectionJustification) {
      this.carryonBaggageService.saveAction(this.carryonBagHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingCarryonBaggageList = data['Pending'];
            this.approvedCarryonBaggageList = data['Approved'];
            this.pendingCarryonBaggageListPrev = JSON.stringify(this.pendingCarryonBaggageList);
            this.approvedCarryonBaggageListPrev = JSON.stringify(this.approvedCarryonBaggageList);
            this.statusMsg = 'Mail Sent.';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
           
          }
        }
      );
    }
    
     else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(obj) {
    let param = Object.assign({}, obj);
    this.carryonBagObjPrev = param;
    this.carryonBagObj = obj;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  checkOthers(param: CarryonBag) {
    if (param.cabinDefinition !== 'Business' && param.cabinDefinition !== 'Economy'
      && param.cabinDefinition !== 'Economy Basic' && param.cabinDefinition !== 'Economy Light' && param.cabinDefinition !== 'First'
      && param.cabinDefinition !== 'Premium Economy') {
      this.cabinDefn = param.cabinDefinition;
      this.carryonBagObj.cabinDefinition = 'Others';
    }
    if (param.route !== 'International' && param.route !== 'Domestic' && param.route !== 'Intercontinental'
      && param.route !== 'Europe' && param.route !== 'Latin America') {
      this.routeType = param.route;
      this.carryonBagObj.route = 'Others';
    }
  }
  resetList() {
    if (this.pendingCarryonBaggageListPrev) {
      this.pendingCarryonBaggageList = JSON.parse(this.pendingCarryonBaggageListPrev);
    }
    if (this.approvedCarryonBaggageListPrev) {
      this.approvedCarryonBaggageList = JSON.parse(this.approvedCarryonBaggageListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedCarryonBaggageListPrev) {
        let list = JSON.parse(this.approvedCarryonBaggageListPrev);
        this.approvedCarryonBaggageList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingCarryonBaggageListPrev) {
        let list = JSON.parse(this.pendingCarryonBaggageListPrev);
        this.pendingCarryonBaggageList = list;
        this.searchData(param);
      }
    }
  }
}
