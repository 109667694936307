import { Component, OnInit } from '@angular/core';
import { AircraftType } from 'src/app/model/aircraft-type';
import { AircraftTypeService } from 'src/app/services/aircraft-type.service';
import { User } from 'src/app/model/user';
import { AircraftTypeHelper } from 'src/app/model/aircraft-type-helper';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { ErrorModel } from 'src/app/model/error-model';
declare var $: any;
@Component({
  selector: 'app-aircraft-type',
  templateUrl: './aircraft-type.component.html',
  styleUrls: ['./aircraft-type.component.css']
})
export class AircraftTypeComponent implements OnInit {

  aircraftType = new AircraftType();
  pendingAircraftTypeList: AircraftType[] = [];
  approvedAircraftTypeList: AircraftType[] = [];
  userInfo = new User();
  aircraftTypeHelper = new AircraftTypeHelper();
  searchKey = '';
  pendingAircraftTypeListPrev: any;
  approvedAircraftTypeListPrev: any;
  p: number = 1;
  p1: number = 1;
  flag1:boolean=false;
  statusMsg: string = '';
  equipmentCodeIATAValidation: boolean;
  equipmentAircraftNameValidation: boolean;
  equipmentAircraftNamePattern: boolean;
  equipmentAircraftNamelength: boolean;
  equipmentCodeIATAlength: boolean = false;
  equipmentCodeIATAPattern: boolean = false;
  regEx = /^[a-zA-Z0-9]+$/g;
  aircraftTypePrev = new AircraftType();
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  equipmentAircraftlength: boolean;
  rejectActionCheck: boolean;
  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  constructor(private aircraftTypeService: AircraftTypeService, private router: Router) { }

  ngOnInit() {
    // $('[data-toggle="tooltip"]').tooltip();
    this.onLoad();
    this.profileInfoClose();
  }
  onLoad() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.aircraftTypeService.loadData(this.userInfo).subscribe(
      data => {
        this.pendingAircraftTypeList = data['Pending'];
        this.approvedAircraftTypeList = data['Approved'];
        this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
        this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
        this.paginationTrigger('pending');
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list, usrType?: any) {
    let obj = Object.assign({}, param);
    this.aircraftTypePrev = obj;
    this.aircraftType = param;
    this.flag1=true;
 
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.aircraftTypePrev = obj;
    this.aircraftType = param;
  }

  saveAction(aircraftForm: NgForm, status?: any) {
    let icaoRequired = false;
    let icaoNRequired = false;
    if (this.aircraftType.equipmentCodeICAO) {
      if ((this.aircraftType.equipmentCodeICAO.length <= 4 && this.aircraftType.equipmentCodeICAO.length >= 3)) {
        icaoRequired = true;
      } else {
        icaoRequired = false;
      }
    } else {
        icaoNRequired = true;
    }
    if ((this.aircraftType.equipmentCodeIATA && this.aircraftType.equipmentAircraftName &&
      this.aircraftType.equipmentCodeIATA.length === 3 && this.aircraftType.equipmentAircraftName.length <= 100) &&
      (icaoRequired || icaoNRequired)) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.aircraftType && this.aircraftType.id > 0 && this.aircraftType.userFlag === 'A') {
          this.aircraftType.parentId = this.aircraftType.id;
          this.aircraftType.id = 0;
        }
      }
      this.aircraftTypeHelper.aircraftType = this.aircraftType;
      if (this.aircraftTypePrev && this.aircraftTypePrev.id > 0) {
        this.aircraftTypeHelper.aircraftTypePrev = this.aircraftTypePrev;
      } else {
        this.aircraftTypeHelper.aircraftTypePrev = this.aircraftType;
      }
      this.aircraftTypeHelper.userInfo = this.userInfo;
      if(this.flag1){
        this.aircraftTypeHelper.aircraftType.userFlag = 'edit';
        this.flag1=false;
        }
        else
        {
          this.aircraftTypeHelper.aircraftType.userFlag = 'add';
          this.flag1=false;
        }

      this.aircraftTypeService.saveAction(this.aircraftTypeHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.pendingAircraftTypeList = data['Pending'];
            this.approvedAircraftTypeList = data['Approved'];
            this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
            this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if (!this.aircraftType.equipmentCodeIATA) {
        this.equipmentCodeIATAValidation = true;
      } else {
        this.equipmentCodeIATAValidation = false;
      }
      if (!this.aircraftType.equipmentAircraftName) {
        this.equipmentAircraftNameValidation = true;
      } else {
        this.equipmentAircraftNameValidation = false;
      }
      if (this.aircraftType.equipmentCodeIATA.length != 3) {
        this.equipmentCodeIATAlength = true;
      } else {
        this.equipmentCodeIATAlength = false;
      }
      if (this.aircraftType.equipmentCodeICAO) {
        if (this.aircraftType.equipmentCodeICAO.length <= 4 && this.aircraftType.equipmentCodeICAO.length >= 3) {
          this.equipmentAircraftNamelength = true;
        } else {
          this.equipmentAircraftNamelength = false;
        }
      } else {
        this.equipmentAircraftNamelength = false;
      }
      if (this.aircraftType.equipmentAircraftName.length > 100) {
        this.equipmentAircraftlength = true;
      } else {
        this.equipmentAircraftlength = false;
      }
    }
  }

  deleteAction() {
    this.aircraftTypeHelper.aircraftType = this.aircraftType;
    if (this.aircraftTypePrev && this.aircraftTypePrev.id > 0) {
      this.aircraftTypeHelper.aircraftTypePrev = this.aircraftTypePrev;
    } else {
      this.aircraftTypeHelper.aircraftTypePrev = this.aircraftType;
    }
    this.aircraftTypeHelper.userInfo = this.userInfo;
    this.aircraftTypeService.deleteAction(this.aircraftTypeHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingAircraftTypeList = data['Pending'];
          this.approvedAircraftTypeList = data['Approved'];
          this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
          this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }
  addDataIcon(aircraftForm: NgForm) {
    this.aircraftType = new AircraftType();
    this.aircraftTypePrev = new AircraftType();
    this.equipmentAircraftNameValidation = false;
    this.equipmentCodeIATAValidation = false;
  }
  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingAircraftTypeList.filter(function (el, idx, array) {
            if ((el.equipmentCodeIATA && el.equipmentCodeIATA.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.pendingAircraftTypeList.filter(function (el, idx, array) {
            if ((el.equipmentAircraftName && el.equipmentAircraftName.toLowerCase().includes(value)) ||
              (el.equipmentCodeIATA && el.equipmentCodeIATA.toLowerCase().includes(value)) ||
              (el.equipmentCodeICAO && el.equipmentCodeICAO.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingAircraftTypeList = opt;
      } else {
        let list = JSON.parse(this.pendingAircraftTypeListPrev);
        this.pendingAircraftTypeList = list;
      }
    } else if (param === 'approved') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedAircraftTypeList.filter(function (el, idx, array) {
            if ((el.equipmentCodeIATA && el.equipmentCodeIATA.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.approvedAircraftTypeList.filter(function (el, idx, array) {
            if ((el.equipmentAircraftName && el.equipmentAircraftName.toLowerCase().includes(value)) ||
              (el.equipmentCodeIATA && el.equipmentCodeIATA.toLowerCase().includes(value)) ||
              (el.equipmentCodeICAO && el.equipmentCodeICAO.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedAircraftTypeList = opt;
      } else {
        let list = JSON.parse(this.approvedAircraftTypeListPrev);
        this.approvedAircraftTypeList = list;
      }
    }
  }
  downloadExcel(param) {
    this.aircraftTypeService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'AircraftType.xls');
      }
    );
  }

  validationClear(param) {
    if (param === 'equipmentCodeIATA') {
      let regBoolean = this.regEx.test(this.aircraftType.equipmentCodeIATA);
      if (this.aircraftType.equipmentCodeIATA) {
        this.equipmentCodeIATAValidation = false;
      } else {
        this.equipmentCodeIATAValidation = true;
        this.equipmentCodeIATAlength = false;
      }
      if (this.aircraftType.equipmentCodeIATA.length === 3) {
        this.equipmentCodeIATAlength = false;
      } else {
        this.equipmentCodeIATAlength = true;
      }
      /* if(regBoolean) {
        this.equipmentCodeIATAPattern = false;
      } else {
        this.equipmentCodeIATAPattern = true;
      } */
    } else if (param === 'equipmentAircraftName') {
      if (this.aircraftType.equipmentAircraftName) {
        this.equipmentAircraftNameValidation = false;
        if (this.aircraftType.equipmentAircraftName.length <= 100) {
          this.equipmentAircraftlength = false;
        } else {
          this.equipmentAircraftlength = true;
        }
      } else {
        this.equipmentAircraftNameValidation = true;
        this.equipmentAircraftlength = false;
      }

    } else if (param === 'equipmentCodeICAO') {
      let regBoolean = this.regEx.test(this.aircraftType.equipmentCodeICAO);
      if (this.aircraftType.equipmentCodeICAO) {
        if (this.aircraftType.equipmentCodeICAO.length <= 4 && this.aircraftType.equipmentCodeICAO.length >= 3) {
          this.equipmentAircraftNamelength = false;
        } else {
          this.equipmentAircraftNamelength = true;
        }
      } else {
        this.equipmentAircraftNamelength = false;
      }
      /* if(regBoolean) {
        this.equipmentAircraftNamePattern = false;
      } else {
        this.equipmentAircraftNamePattern = true;
      } */
    }
  }

  cancelAction() {
    if (this.aircraftType && this.aircraftType.id > 0) {
      let obj = Object.assign({}, this.aircraftTypePrev);
      this.aircraftType = obj;
    } else {
      this.aircraftType = new AircraftType();
      this.aircraftType.equipmentAircraftName = '';
      this.aircraftType.equipmentCodeIATA = '';
      this.aircraftType.equipmentCodeICAO = '';
      this.aircraftType.id = 0;
    }
    this.equipmentAircraftNameValidation = false;
    this.equipmentCodeIATAValidation = false;
    this.equipmentCodeIATAPattern = false;
    this.equipmentCodeIATAlength = false;
    this.equipmentAircraftNamelength = false;
    this.equipmentAircraftlength = false;
  }
  closeAction() {
    this.aircraftType.id = 0;
    this.equipmentAircraftNamelength = false;
    this.equipmentAircraftlength = false;
    this.equipmentAircraftNameValidation = false;
    this.equipmentCodeIATAValidation = false;
    this.equipmentCodeIATAPattern = false;
    this.equipmentCodeIATAlength = false;
    this.aircraftType = this.aircraftTypePrev;
    if (this.pendingAircraftTypeListPrev) {
      this.pendingAircraftTypeList = JSON.parse(this.pendingAircraftTypeListPrev);
    }
    if (this.approvedAircraftTypeListPrev) {
      this.approvedAircraftTypeList = JSON.parse(this.approvedAircraftTypeListPrev);
    }
  }
  approveAction(param) {
    this.aircraftTypeHelper.aircraftType = param;
    this.aircraftTypeHelper.userInfo = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.aircraftTypeHelper.aircraftType.userFlag = 'app';
      this.aircraftTypeService.saveAction(this.aircraftTypeHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingAircraftTypeList = data['Pending'];
            this.approvedAircraftTypeList = data['Approved'];
            this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
            this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.aircraftTypeService.deleteAction(this.aircraftTypeHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAircraftTypeList = data['Pending'];
            this.approvedAircraftTypeList = data['Approved'];
            this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
            this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    if (this.rejectionJustification) {
      this.aircraftTypeHelper.aircraftType = this.aircraftType;
      if (this.aircraftTypePrev && this.aircraftTypePrev.id > 0) {
        this.aircraftTypeHelper.aircraftTypePrev = this.aircraftTypePrev;
      } else {
        this.aircraftTypeHelper.aircraftTypePrev = this.aircraftType;
      }
      this.aircraftTypeHelper.userInfo = this.userInfo;
      this.aircraftTypeHelper.aircraftType.userFlag = 'rej';
      this.aircraftTypeService.saveAction(this.aircraftTypeHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAircraftTypeList = data['Pending'];
            this.approvedAircraftTypeList = data['Approved'];
            this.pendingAircraftTypeListPrev = JSON.stringify(this.pendingAircraftTypeList);
            this.approvedAircraftTypeListPrev = JSON.stringify(this.approvedAircraftTypeList);
            this.statusMsg = 'Mail Sent.';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.aircraftTypePrev = obj;
    this.aircraftType = param;

    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingAircraftTypeListPrev) {
      this.pendingAircraftTypeList = JSON.parse(this.pendingAircraftTypeListPrev);
    }
    if (this.approvedAircraftTypeListPrev) {
      this.approvedAircraftTypeList = JSON.parse(this.approvedAircraftTypeListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedAircraftTypeListPrev) {
        let list = JSON.parse(this.approvedAircraftTypeListPrev);
        this.approvedAircraftTypeList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingAircraftTypeListPrev) {
        let list = JSON.parse(this.pendingAircraftTypeListPrev);
        this.pendingAircraftTypeList = list;
        this.searchData(param);
      }
    }
  }
}
