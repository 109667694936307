import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonScreenService extends BaseService {

  constructor(http: HttpClient, router: Router) {
    super(http, router);
   }

  loadFunction(){
    return this.getMethod( '/api/referenceGuide/login/load-data' );
  }
  changePasswordAction(param) {
    return this.postMethod( '/api/referenceGuide/v1/userDetails/add', param );
  }

}
