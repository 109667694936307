import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonScreenService } from './services/common-screen.service';
import { LoginService } from './services/login.service';
import { User } from './model/user';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ReferenceGuide';
  userInfo = new User();
  constructor(private router: Router, public loginService: LoginService) {}
  ngOnInit() {
    this.userInfo = this.loginService.userInfo;
  }

}
