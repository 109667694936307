import { Component, OnInit, ViewChild } from '@angular/core';
import { FFPProgramOverviewService } from 'src/app/services/ffp-program-overview.service';
import { FfpBenefits } from 'src/app/model/ffp-benefits';
import { User } from 'src/app/model/user';
import { FFPProgramOverviewHelper } from 'src/app/model/ffpprogram-overview-helper';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FFPProgramOverview } from '../../model/ffpprogram-overview';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { ErrorModel } from 'src/app/model/error-model';
declare var $: any;
@Component({
  selector: 'app-ffp-overview',
  templateUrl: './ffp-program-overview.component.html'
})
export class FfpProgramoverviewComponent implements OnInit {

  ffpProgram: FFPProgramOverview[] = [];
  ffpprogramObj = new FFPProgramOverview();
  ffpprogramObjPrev = new FFPProgramOverview();
  airline: AirlineSummary[] = [];
  ffpInfo: FFPProgramOverview[] = [];
  userInfo = new User();
  airlineKeysList = [];
  starTierLevelBenefit = [];
  acrequired: boolean;
  flag1:boolean=false;
  stlrequired: boolean;
  stllength: boolean;
  checkedTier = [];
  ffpProgramOverviewHelper = new FFPProgramOverviewHelper();
  airlineName = new Map();
  ffpName = new Map();
  memberAirline = new Map();
  p: number = 1;
  p1: number = 1;
  addEditAirlineName: any;
  addEditFfpName: any;
  searchKey = '';
  statusMsg: string = '';
  pendingFFPProgramviewList: FFPProgramOverview[] = [];
  approvedFFPProgramviewList: FFPProgramOverview[] = [];
  pendingFFPProgramviewListPrev: any;
  approvedFFPProgramviewListPrev: any;
  airlineCodeValidation: boolean;
  starTierLevelValidation: boolean;
  ffpTierLevellength: boolean;
  ffpProgramNamelength:boolean;
  ffpTierLevelValidation: boolean;
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  equipmentAircraftlength: boolean;
  rejectActionCheck: boolean;
  isMemeberAirline: any;
  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  ffpProgramNameValidation: boolean;
  constructor(private ffpProgramOverviewService: FFPProgramOverviewService, private router: Router) { }
  ngOnInit() {
    this.profileInfoClose();
    this.onLoadMethod();
  }
  onLoadMethod() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.ffpProgramOverviewService.loadData(this.userInfo).subscribe(
      data => {
        this.pendingFFPProgramviewList = data['Pending'];
        this.approvedFFPProgramviewList = data['Approved'];
        this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
        this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
        this.paginationTrigger('pending');
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        let airKeys = [];
        for (let index = 0; index < this.airline.length; index++) {
          airKeys.push(this.airline[index].airlineCode);
          this.airlineName.set(this.airline[index].airlineCode, this.airline[index].airlineName);
          this.memberAirline.set(this.airline[index].airlineCode, this.airline[index].isMemberAirline);
        }
        this.airlineKeysList = airKeys.sort();
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list, usrType?: any) {
    let obj = Object.assign({}, param);
    this.ffpprogramObjPrev = obj;
    this.ffpprogramObj = param;
    this.flag1=true;
    if (param.starTierLevel === 'Gold' || param.starTierLevel === 'G') {
      this.ffpprogramObj.starTierLevel = 'Gold';
    } else if (param.starTierLevel === 'Silver' || param.starTierLevel === 'S') {
      this.ffpprogramObj.starTierLevel = 'Silver';
    } else if (param.starTierLevel === 'Base' || param.starTierLevel === 'B') {
      this.ffpprogramObj.starTierLevel = 'Base';
    }
    this.setAirlineAndFFPName(this.ffpprogramObj.airlineCode);
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.ffpprogramObjPrev = obj;
    this.ffpprogramObj = param;
  }
  saveAction(ffpprogramForm: NgForm) {
    if (this.ffpprogramObj.airlineCode && this.ffpprogramObj.airlineName &&
      this.ffpprogramObj.ffpProgramName &&
      this.ffpprogramObj.ffpTierLevel && this.ffpprogramObj.starTierLevel
      && this.ffpprogramObj.ffpTierLevel.length <= 100 && this.ffpprogramObj.ffpProgramName.length <= 100) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.ffpprogramObj && this.ffpprogramObj.id > 0 && this.ffpprogramObj.userFlag === 'A') {
          this.ffpprogramObj.parentId = this.ffpprogramObj.id;
          this.ffpprogramObj.id = 0;
        }
      }
      this.ffpProgramOverviewHelper.ffpProgramOverview = this.ffpprogramObj;
      this.ffpProgramOverviewHelper.userDetails = this.userInfo;
      if (this.ffpprogramObjPrev && this.ffpprogramObjPrev.id > 0) {
        this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObjPrev;
      } else {
        this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObj;
      }
      if(this.flag1){
        this.ffpProgramOverviewHelper.ffpProgramOverview.userFlag  = 'edit';
        this.flag1=false;
        }
        else
        {
          this.ffpProgramOverviewHelper.ffpProgramOverview.userFlag  = 'add';
          this.flag1=false;
        }
 
      this.ffpProgramOverviewService.saveAction(this.ffpProgramOverviewHelper, 'ejb').subscribe(
        data => {
          if (data['Status']) {
            this.pendingFFPProgramviewList = data['Pending'];
            this.approvedFFPProgramviewList = data['Approved'];
            this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
            this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    }
    else {
      if (!this.ffpprogramObj.airlineCode) {
        this.airlineCodeValidation = true;
      }
      else {
        this.airlineCodeValidation = false;
      }
      if (!this.ffpprogramObj.ffpTierLevel) {
        this.ffpTierLevelValidation = true;
      }
      else {
        this.ffpTierLevelValidation = false;
      }
      if (!this.ffpprogramObj.starTierLevel) 
      {
        this.starTierLevelValidation= true;
      }
      else {
        this.starTierLevelValidation= false;
           }
      if (!this.ffpprogramObj.ffpProgramName) 
      {
        this.ffpProgramNameValidation = true;
      }
      else {
        this.ffpProgramNameValidation = false;
          }
      }
  }
  deleteAction() {
    this.ffpProgramOverviewHelper.ffpProgramOverview = this.ffpprogramObj;
    this.ffpProgramOverviewHelper.userDetails = this.userInfo;
    if (this.ffpprogramObjPrev && this.ffpprogramObjPrev.id > 0) {
      this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObjPrev;
    } else {
      this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObj;
    }
    this.ffpProgramOverviewService.deleteAction(this.ffpProgramOverviewHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingFFPProgramviewList = data['Pending'];
          this.approvedFFPProgramviewList = data['Approved'];
          this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
          this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }

  /*   tierBenefitsChecked(checked, benefit, idx){
      if(checked) {
        this.checkedTier.push(benefit);
      } else {
        this.checkedTier.splice(this.checkedTier.indexOf(benefit), 1);
      }
    } */
  setAirlineAndFFPName(obj: string) {
    this.addEditAirlineName = this.airlineName.get(obj);
    this.isMemeberAirline = this.memberAirline.get(obj);
    this.ffpprogramObj.airlineName = this.addEditAirlineName;
  }
  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      if (this.searchKey) {
        let airlineNameMap = new Map();
        let memberAirlineMap = new Map();
        let ffpNameMap = new Map();
        if (!this.searchAirlineTriggerPending) {
          airlineNameMap = this.airlineName;
          memberAirlineMap = this.memberAirline;
          ffpNameMap = this.ffpName;
          opt = this.pendingFFPProgramviewList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          memberAirlineMap = this.memberAirline;
          ffpNameMap = this.ffpName;
          opt = this.pendingFFPProgramviewList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.ffpProgramName && el.ffpProgramName.toLowerCase().includes(value)) ||
              (el.starTierLevel && el.starTierLevel.toLowerCase().includes(value)) ||
              (el.ffpTierLevel && el.ffpTierLevel.toLowerCase().includes(value))
            ) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingFFPProgramviewList = opt;
      } else {
        let list = JSON.parse(this.pendingFFPProgramviewListPrev);
        this.pendingFFPProgramviewList = list;
      }
    }
    if (param === 'approved') {
      if (this.searchKey) {
        let airlineNameMap = new Map();
        if (!this.searchAirlineTriggerApproved) {
          airlineNameMap = this.airlineName;
          opt = this.approvedFFPProgramviewList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          opt = this.approvedFFPProgramviewList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.ffpProgramName && el.ffpProgramName.toLowerCase().includes(value)) ||
              (el.starTierLevel && el.starTierLevel.toLowerCase().includes(value)) ||
              (el.ffpTierLevel && el.ffpTierLevel.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedFFPProgramviewList = opt;
      } else {
        let list = JSON.parse(this.approvedFFPProgramviewListPrev);
        this.approvedFFPProgramviewList = list;
      }
    }

  }
  downloadExcel(param) {
    this.ffpProgramOverviewService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'FFPProgramOverview.xls');
      }
    );
  }
  addDataIcon(ffpprogramForm: NgForm) {
    this.ffpprogramObj = new FFPProgramOverview();
    this.ffpprogramObjPrev = new FFPProgramOverview();
    this.ffpprogramObj.id = 0;
    this.ffpTierLevelValidation = false;
    this.ffpprogramObj.starTierLevel = 'Gold';
    this.starTierLevelValidation=false;
    this.ffpProgramNameValidation = false;
    ffpprogramForm.control.markAsUntouched;
    ffpprogramForm.control.markAsPristine;
    if (this.userInfo.userType === 'AUTHOR') {
      this.ffpprogramObj.airlineCode = this.userInfo.airlineId;
      this.setAirlineAndFFPName(this.ffpprogramObj.airlineCode);
    }
  }
  validationClear(param) {
    if (param === 'airlineCode') {
      if (this.ffpprogramObj.airlineCode) {
        this.airlineCodeValidation = false;
      } else {
        this.airlineCodeValidation = true;
      }
    }

    if (param === 'ffpProgramName') {
      if (this.ffpprogramObj.ffpProgramName) {
        this.ffpProgramNameValidation = false;
      } else {
        this.ffpProgramNameValidation = true;
      }
      
      if (this.ffpprogramObj.ffpProgramName.length <= 100) {
        this.ffpProgramNamelength = false;
      } else {
        this.ffpProgramNamelength = true;
      }
    }

    if (param === 'ffpTierLevel') {
      if (this.ffpprogramObj.ffpTierLevel) {
        this.ffpTierLevelValidation = false;
      } else {
        this.ffpTierLevelValidation = true;
      }
    

      if (this.ffpprogramObj.ffpTierLevel.length <= 100) {
        this.ffpTierLevellength = false;
      } else {
        this.ffpTierLevellength = true;
      }
    } 
    if (param === 'starTierLevel') {
      if (this.ffpprogramObj.starTierLevel) {
        this.starTierLevelValidation = false;
      } else {
        this.starTierLevelValidation = true;
      }
    }
  }

  cancelAction() {
    if (this.ffpprogramObj && this.ffpprogramObj.id > 0) {
      let obj = Object.assign({}, this.ffpprogramObjPrev);
      this.ffpprogramObj = obj;
    } else {
      this.ffpprogramObj = new FFPProgramOverview();
      this.ffpprogramObj.ffpTierLevel = '';
      this.ffpprogramObj.starTierLevel = 'Gold';
      this.ffpprogramObj.airlineCode = '';
      this.ffpprogramObj.ffpProgramName = '';
      this.ffpprogramObj.airlineName = '';
      this.addEditAirlineName = '';
      this.addEditFfpName = '';
      this.ffpprogramObj.id = 0;
      if (this.userInfo.userType === 'AUTHOR') {
        this.ffpprogramObj.airlineCode = this.userInfo.airlineId;
      }
    }
    this.setAirlineAndFFPName(this.ffpprogramObj.airlineCode);
    this.airlineCodeValidation = false;
    this.ffpTierLevelValidation = false;
    this.starTierLevelValidation = false;
    this.ffpTierLevellength = false;
    this.ffpProgramNamelength=false;
    this.ffpProgramNameValidation = false;

  }
  closeAction() {
    this.ffpprogramObj.id = 0;
    this.ffpprogramObj = this.ffpprogramObjPrev;
    this.starTierLevelValidation = false;
    this.ffpProgramNameValidation = false;
    this.ffpTierLevellength = false;
    this.ffpProgramNamelength=false;
    if (this.pendingFFPProgramviewListPrev) {
      this.pendingFFPProgramviewList = JSON.parse(this.pendingFFPProgramviewListPrev);
    }
    if (this.approvedFFPProgramviewListPrev) {
      this.approvedFFPProgramviewList = JSON.parse(this.approvedFFPProgramviewListPrev);
    }
  }
  approveAction(param) {
    this.ffpprogramObj = param;
    this.ffpProgramOverviewHelper.ffpProgramOverview = this.ffpprogramObj;
    this.ffpProgramOverviewHelper.userDetails = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.ffpProgramOverviewHelper.ffpProgramOverview.userFlag = 'app';
      this.ffpProgramOverviewService.saveAction(this.ffpProgramOverviewHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingFFPProgramviewList = data['Pending'];
            this.approvedFFPProgramviewList = data['Approved'];
            this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
            this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
    else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.ffpProgramOverviewService.deleteAction(this.ffpProgramOverviewHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingFFPProgramviewList = data['Pending'];
            this.approvedFFPProgramviewList = data['Approved'];
            this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
            this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.ffpProgramOverviewHelper.ffpProgramOverview = this.ffpprogramObj;
    this.ffpProgramOverviewHelper.userDetails = this.userInfo;
    if (this.ffpprogramObjPrev && this.ffpprogramObjPrev.id > 0) {
      this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObjPrev;
    } else {
      this.ffpProgramOverviewHelper.ffpProgramOverviewPrev = this.ffpprogramObj;
    }
    this.ffpProgramOverviewHelper.ffpProgramOverview.userFlag = 'rej';
    if (this.rejectionJustification) {
      this.ffpProgramOverviewService.saveAction(this.ffpProgramOverviewHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingFFPProgramviewList = data['Pending'];
            this.approvedFFPProgramviewList = data['Approved'];
            this.pendingFFPProgramviewListPrev = JSON.stringify(this.pendingFFPProgramviewList);
            this.approvedFFPProgramviewListPrev = JSON.stringify(this.approvedFFPProgramviewList);
            this.statusMsg = 'Mail sent';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.ffpprogramObjPrev = obj;
    this.ffpprogramObj = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingFFPProgramviewListPrev) {
      this.pendingFFPProgramviewList = JSON.parse(this.pendingFFPProgramviewListPrev);
    }
    if (this.approvedFFPProgramviewListPrev) {
      this.approvedFFPProgramviewList = JSON.parse(this.approvedFFPProgramviewListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedFFPProgramviewListPrev) {
        let list = JSON.parse(this.approvedFFPProgramviewListPrev);
        this.approvedFFPProgramviewList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingFFPProgramviewListPrev) {
        let list = JSON.parse(this.pendingFFPProgramviewListPrev);
        this.pendingFFPProgramviewList = list;
        this.searchData(param);
      }
    }
  }
}