import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'src/app/model/user';
import { ForgotpasswordService } from 'src/app/services/forgotpassword.service';
import { NgForm } from '@angular/forms';
import { ErrorModel } from 'src/app/model/error-model';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './forgotpassword.component.html'
  
})
export class ForgotpasswordComponent implements OnInit{
  
  user = new User();
  username:string;
  username1:string;
  usernameReq: boolean = false;
  usernameRegeVal:boolean=false;
  statusMsg: any;
  errorModel = new ErrorModel();
  emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  constructor(private router: Router,private forgotpasswordService: ForgotpasswordService) {}
  ngOnInit() {
    
  }


  resetPassword(resetPasswordForm: NgForm) {
    if(this.user.userEmailId && this.emailRegex.test(this.user.userEmailId)){
      this.usernameRegeVal = false;
    } else {
      this.usernameRegeVal = true;
    }
    if (!this.usernameRegeVal) {
      this.forgotpasswordService.resetFunction(this.user).subscribe((data)=>{ 
        this.errorModel = data['Model'];
      this.statusMsg = this.errorModel.message;
      $('#statusId').modal('toggle');

 }
        /* data => {
          if (data['Status']) {
                       this.forgotpasswordService.userInfo = data['Details'];
            sessionStorage.setItem('UserInfo', JSON.stringify(data['Details']));
             this.statusMsg = 'Your password has been sent to given mail.';
            $('#statusId').modal('toggle');
            
          } else {
             this.user.userPassword = '';
            this.user.airlineId = '';
            this.user.uiName = '';
            this.user.userId = 0;
            this.user.userName = '';
            this.user.userType = '';
           
            this.statusMsg = 'Incorrect username or password.';
            $('#statusId').modal('toggle');
          }
      
        data => {
          if (data['Status']) {

            this.user.userPassword = '';
            this.user.airlineId = '';
            this.user.uiName = '';
            this.user.userId = 0;
            this.user.userName = '';
            this.user.userType = '';
            this.statusMsg = 'Status';
            $('#statusId').modal('toggle');
          }
        }*/

      );
    } else {
      
            this.usernameReq = true;
        
      }

    this.user.userEmailId='';
  }
  emailValidation() {
    if(this.user.userEmailId && this.emailRegex.test(this.user.userEmailId)){
      this.usernameRegeVal = false;
    } else {
      this.usernameRegeVal = true;
    }

  }
  clear()
  {
    this.usernameRegeVal=false;
    this.usernameReq = false;
    this.user.userEmailId='';
    

  }
   validationClear(param) {
     if (param === 'username') {
       if(this.user.userEmailId)
       {
         this.usernameReq=false;

       }
     }

   }
}
