import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChekcedBagService extends BaseService {

  constructor(http: HttpClient, router: Router) { 
    super(http, router);
  }

  saveAction(param: any, param2){
    return this.postMethod( '/api/referenceGuide/v1/checkedBag/add/'+param2, param );
  }
  loadData(param){
    return this.postMethod( '/api/referenceGuide/v1/checkedBag/load', param );
  }
  deleteAction(param: any){
    return this.postMethod( '/api/referenceGuide/v1/checkedBag/remove', param );
  }
  downloadExcel(param, param1, param2): Observable<Blob> {
    this.resetMethod();
    return this.http.get( environment.apiUrl+'/api/referenceGuide/v1/checkedBag/download/'+param+'/'
    +param1+'/'+param2, {responseType: 'blob'} );
  }
}
