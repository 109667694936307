import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AirportAtlasService extends BaseService {

  constructor(http: HttpClient, router: Router) { 
    super(http, router);
  }

  getCountries() {
    return this.getMethod( '/api/referenceGuide/v1/airportAtlas/countries');
  }

  saveAction(param: any, rejection: any){
    return this.postMethod( '/api/referenceGuide/v1/airportAtlas/add/' + rejection, param );
  }
  loadData(param, param2){
    return this.postMethod( '/api/referenceGuide/v1/airportAtlas/load/'+param2 , param );
  }
  deleteAction(param: any){
    return this.postMethod( '/api/referenceGuide/v1/airportAtlas/remove', param );
  }
  downloadExcel(param,param2,param3): Observable<Blob> {
    this.resetMethod();
    return this.http.get( environment.apiUrl+'/api/referenceGuide/v1/airportAtlas/download/'+param +'/'+param2+'/'+param3, {responseType: 'blob'} );
  }
  
}

