export class AircraftType {
	equipmentCodeIATA: string = '';

	equipmentCodeICAO: string = '';

	equipmentAircraftName: string = '';

	id: number = 0;

	userFlag: string = '';

	createdBy: string = '';

	airlineCode: string = '';
	public parentId: number = 0;
}
