import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AirlineSummaryService extends BaseService {

  constructor(http: HttpClient, router: Router) {
    super(http, router);
   }
   saveApproveRejection(param: any, rejection: any){
    return this.postMethod( '/api/referenceGuide/v1/airlineSummary/rejectApprove/'+rejection, param );
  }

  saveAction(param: any, rejection: any,fileData : any){
    const formData = new FormData();

      formData.append('file', fileData);
      formData.append('key',JSON.stringify(param));
    return this.postMethod( '/api/referenceGuide/v1/airlineSummary/add/'+rejection, formData );
  }
  
  loadData(param){
    return this.postMethod( '/api/referenceGuide/v1/airlineSummary/load',param );
  }
  deleteAction(param: any){
    return this.postMethod( '/api/referenceGuide/v1/airlineSummary/remove', param );
  }
  downloadExcel(param,param2,param3): Observable<Blob> {
    this.resetMethod();
    return this.http.get( environment.apiUrl+'/api/referenceGuide/v1/airlineSummary/download/'+param +'/'+param2+'/'+param3, 
    {responseType: 'blob'});
  }
}