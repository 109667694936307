import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

 userInfo = new User();
  constructor(private http: HttpClient) { }

  resetFunction(param: any){
    return this.http.post( environment.apiUrl+'/api/referenceGuide/v1/userDetails/forget', param );
  }

}