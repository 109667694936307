import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { HomeScreenComponent } from './views/home-screen/home-screen.component';
import { AircraftTypeComponent } from './views/aircraft-type/aircraft-type.component';
import { AirlineSummaryComponent } from './views/airline-summary/airline-summary.component';
import { AirportAtlasComponent } from './views/airport-atlas/airport-atlas.componet';
import { CallCenterListComponent } from './views/callcenter-list/callcenter-list/callcenter-list.component';
import { CarrryonBagComponent } from './views/carryon-bag/carryon-bag.component';
import { CheckedBagComponent } from './views/checked-bag/checked-bag.component';
import { FfpBenefitsComponent } from './views/ffp-benefits/ffp-benefits.component';
import { SpecialcheckedComponent } from './views/specialchecked-bag/specialcheckedbag.componet';
import { AdduserComponent } from './views/adduser/adduser.componet';
import { FfpProgramoverviewComponent } from './views/ffp-program-overview/ffp-program-overview.component';
import { ErrorComponent } from './error/error.component';
import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {path:'forgotpassword',component:ForgotpasswordComponent},
  {path: 'login/forgotpassword',component:ForgotpasswordComponent},
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'reference-guide', component: LoginComponent },
  { path: 'referenceguideui', component: LoginComponent },
  { path: 'referenceguideweb', component: LoginComponent },
  { path: 'reference-guide-web', component: LoginComponent },
  { path: 'home', component: HomeScreenComponent, children: 
  [{ path: 'aircraftType', component: AircraftTypeComponent },
  { path: 'airlineSummary', component: AirlineSummaryComponent },
  { path: 'airportAtlas', component: AirportAtlasComponent },
  { path: 'callCenterList', component: CallCenterListComponent },
  { path: 'carryOnBag', component: CarrryonBagComponent },
  { path: 'checkedBag', component: CheckedBagComponent },
  { path: 'ffpBenefits', component: FfpBenefitsComponent },
  { path: 'ffpInformation', component: FfpProgramoverviewComponent },
  { path: 'specialCheckedBag', component: SpecialcheckedComponent },
  { path: 'userInformation', component: AdduserComponent }] },
  { path: '**', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
