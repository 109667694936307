export class CallCenterList {
	airlineCode: string = '';

	airlineName: string = '';

	issueForCall: string = '';

	orderToCall: string = '';

	contact: string = '';

	phoneNumber: string = '';

	englishSupportAvailable: string = '';

	location: string = '';

	openingHours: string = '';

	emailContact: string = '';

	public userFlag: string = '';

	createdBy: string = '';

	id: number = 0;
	public parentId: number = 0;
}
