import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FFPProgramOverviewService extends BaseService {

  constructor(http: HttpClient, router: Router) {
    super(http, router);
   }
  saveAction(param: any,rejection: any){
    return this.postMethod( '/api/referenceGuide/v1/ffpProgramOverview/add/'+rejection, param );
  }
  loadData(param){
    return this.postMethod( '/api/referenceGuide/v1/ffpProgramOverview/load',param );
  }
  deleteAction(param: any){
    return this.postMethod( '/api/referenceGuide/v1/ffpProgramOverview/remove', param );
  }
  downloadExcel(param,param2,param3): Observable<Blob> {
    this.resetMethod();
    return this.http.get( environment.apiUrl+'/api/referenceGuide/v1/ffpProgramOverview/download/'+param +'/'+param2+'/'+param3, {responseType: 'blob'} );
  }
}