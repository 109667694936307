import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdduserService extends BaseService {

  constructor(http: HttpClient, router: Router) { 
    super(http,router);
  }

  adduserFunction(param: any){
    return this.postMethod( '/api/referenceGuide/v1/userDetails/add', param );
  }

  loadUsersFunction() {
    return this.getMethod( '/api/referenceGuide/v1/userDetails/load' );
  }

  deleteUserFunction(param) {
    return this.postMethod( '/api/referenceGuide/v1/userDetails/remove', param );
  }
}