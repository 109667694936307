export class FfpBenefits {
	airlineCode: string = '';

	airlineName: string = '';

	isMemberAirline: string = '';

	ffpProgramName: string = '';

	starTierLevel: string = '';

	starTierLevelBenefits: string = '';

	id: number = 0;

	userFlag: string = '';

	public parentId: number = 0;
}
