export class AirlineSummary {
	public airlineCode: string = '';
	public airlineName: string = '';
	public airlineFormalName: string = '';
	public airlineLogo: string = '';
	public isMemberAirline: string = '';
	public isCpmAirline: string = '';
	public airlineWebsite: string = '';
	public airlineAnniversary: string = '';
	public joiningStarAlliance: string = '';
	public ticketingPlate: string = '';
	userFlag: string = '';
	public airlineImage: Blob;
	id: number = 0;
	public parentId: number = 0;

}
