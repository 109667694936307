import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AircraftTypeComponent } from './views/aircraft-type/aircraft-type.component';
import { CheckedBagComponent } from './views/checked-bag/checked-bag.component';
import { FfpBenefitsComponent, FFPReplaceSemiColonPipe } from './views/ffp-benefits/ffp-benefits.component';
import { LoginComponent } from './views/login/login.component';
import { HomeScreenComponent } from './views/home-screen/home-screen.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { FfpBenefitsService } from './services/ffp-benefits.service';
import { CommonScreenService } from './services/common-screen.service';
import { ChekcedBagService } from './services/chekced-bag.service';
import { AircraftTypeService } from './services/aircraft-type.service';
import { MaterialModules } from './material-modules';
import { MatNativeDateModule } from '@angular/material/core';
import { LogoutComponent } from './views/logout/logout.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { AirlineSummaryComponent } from './views/airline-summary/airline-summary.component';
import { FfpProgramoverviewComponent } from './views/ffp-program-overview/ffp-program-overview.component';
import { CarrryonBagComponent } from './views/carryon-bag/carryon-bag.component';
import { AirportAtlasComponent } from './views/airport-atlas/airport-atlas.componet';
import { AirportAtlasService } from './services/aiport-atlas.service';
import { AirlineSummaryService } from './services/airline-summary.service';
import { CarryonBaggageService } from './services/carryon-bag.service';
import { FFPProgramOverviewService } from './services/ffp-program-overview.service';
import { CommonModule } from '@angular/common';
import { SpecialcheckedComponent } from './views/specialchecked-bag/specialcheckedbag.componet';
import { SpecialcheckedBaggageService } from './services/specialcheckedBag.service';
import { CallCenterListComponent } from './views/callcenter-list/callcenter-list/callcenter-list.component';
import { CallCenterListService } from './services/callcenter-list.service';
import { ForgotpasswordService } from 'src/app/services/forgotpassword.service';

import { AdduserService } from './services/adduser.service';

import { AdduserComponent, ReplaceSemiColonPipe } from './views/adduser/adduser.componet';
import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    AircraftTypeComponent,
    CheckedBagComponent,
    FfpBenefitsComponent,
    LoginComponent,
    HomeScreenComponent,
    LogoutComponent,
    AirlineSummaryComponent,
    FfpProgramoverviewComponent,
    CarrryonBagComponent,
    AirportAtlasComponent,
    CallCenterListComponent,
    SpecialcheckedComponent,
    ForgotpasswordComponent,
    AdduserComponent, ReplaceSemiColonPipe, FFPReplaceSemiColonPipe, ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule , HttpClientModule,
    BrowserAnimationsModule,
    MaterialModules, MatNativeDateModule, NgxPaginationModule
  ],
  providers: [LoginService, FfpBenefitsService, CommonScreenService, ChekcedBagService, AircraftTypeService, AirportAtlasService,
    AirlineSummaryService,ForgotpasswordService, CarryonBaggageService, FFPProgramOverviewService,SpecialcheckedBaggageService,
    CallCenterListService, AdduserService,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
