import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { NgForm } from '@angular/forms';
import { SpecialCheckedBaggageHelper } from 'src/app/model/specialcheckedBag-helper';
import { SpecialCheckedBag } from 'src/app/model/specialcheckedBag';
import { SpecialcheckedBaggageService } from 'src/app/services/specialcheckedBag.service';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ErrorModel } from 'src/app/model/error-model';

declare var $: any;
@Component({
  selector: 'app-specialchecked-bag',
  templateUrl: './specialcheckedBag.component.html'
})
export class SpecialcheckedComponent implements OnInit {

  specialcheckedBagObj = new SpecialCheckedBag();
  pendingSpecialCheckedBagList: SpecialCheckedBag[] = [];
  approvedSpecialCheckedBagList: SpecialCheckedBag[] = [];
  specialCheckedBagList: SpecialCheckedBag[] = [];
  specialcheckedBagList: SpecialCheckedBag[] = [];
  p: number = 1;
  p1: number = 1;
  itemso:string;
  items:string='';
  flag:string='';
  pieceConcept:string='';
  weightConcept:string='';
  ssrInterlineable:string='';
  ssrRequired:string='';
  ssrInterl:string;
  flag1:boolean=false;
  pieceConn:string;
  userInfo = new User();
  weightConn:string;
  pendingSpecialCheckedBagListPrev: any;
  approvedSpecialCheckedBagListPrev: any;
  specialcheckedBaggageHelper = new SpecialCheckedBaggageHelper();
  searchKey = '';
  specialcheckedBaggageListPrev: SpecialCheckedBag[] = [];
  airline: AirlineSummary[] = [];
  airlineKeysList = [];
  statusMsg: string = '';
  //ailineCodeValidation: boolean;
  airlineNameValidation: boolean;
  airlineNamelength: boolean;
  pieceConceptValidation: boolean;
  pieceConceptlength: boolean;
  weightConceptValidation: boolean;
  SsrInterlineableValidation: boolean;
  SsrRequiredValidation: boolean;
  ssrReq:string;
  SsrCodeValidation: boolean;
  weightConceptlength: boolean;
  SsrInterlineablelength: boolean;
  SsrRequiredlength: boolean;
  SsrCodelength: boolean;
  packageRequiredValidation: boolean;
  itemsValidation: boolean;
  itemslength: boolean;
  packageRequiredlength: boolean;
  specialCheckedBagPrev = new SpecialCheckedBag();
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  equipmentAircraftlength: boolean;
  rejectActionCheck: boolean;
  airlineCodeValidation: boolean;
  
  airlineName = new Map();
  addEditAirlineName: any;
  acrequired: boolean;
  selectValue = ['Yes', 'No', 'Not applicable', 'Others'];
  pieceCon: any;
  weightCon: any;
  ssrInter: any;
  ssrRe: any;
  errorModel = new ErrorModel();
  cabinDefn: string = '';
  
  
  itemValues = ['Ammunition', 'Archery equipment', 'Back packs', 'Bike', 'Body board', 'Boogie board',
    'Bowling equipment', 'Bulk baggage', 'Cabin baggage in seat', 'Canoe', 'Cricket', 'Emotional Support Animals',
    'Fencing Equipment', 'Film equipment', 'Firearms', 'Fishing equipment', 'Golf equipment',
    'Horse Riding Equipment', 'Ice hockey', 'Javelin', 'Kayaks', 'Kite', 'Kite boarding', 'Kiteboards',
    'Mountain Climbing', 'Oars', 'Pets as Checked Baggage (AVIH)', 'Pets in Cabin', 'Portable Musical Instrument',
    'Rackets (Tennis, Badminton)', 'Scuba Diving', 'Scuba equipment', 'Service Animals', 'Skis', 'Surfboard',
    'Small Surfboard less than 2M', 'Snowboard', 'Surfboard', 'Wakeboard', 'Waterskis', 'Wet Cell Batteries',
    'Wheelchair non-powered', 'Wheelchair with Dry Cell Batteries', 'Wheelchair with Fitted Lithium Batteries',
    'Wheelchair with Lithium Battery', 'Wheelchair with Lithium Ion Batteries', 'Wheelchair with spillable battery',
    'Windsurfer', 'Others'];
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  constructor(private specialcheckedBagService: SpecialcheckedBaggageService, private router: Router) { }

  ngOnInit() {
    this.onLoad();
    this.profileInfoClose();
  }
  onLoad() {

    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.specialcheckedBagService.loadData(this.userInfo).subscribe(
      data => {
        this.pendingSpecialCheckedBagList = data['Pending'];
        this.approvedSpecialCheckedBagList = data['Approved'];
        this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
        this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
        this.paginationTrigger('pending');
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        let airKey = [];
        for (let index = 0; index < this.airline.length; index++) {
          airKey.push(this.airline[index].airlineCode);
          this.airlineName.set(this.airline[index].airlineCode, this.airline[index].airlineName);
        }
        this.airlineKeysList = airKey.sort();
      }

    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  editMethod(param) {
    let obj = Object.assign({}, param);
    this.specialCheckedBagPrev = obj;
    this.specialcheckedBagObj = param;
    this.setAirlineName(this.specialcheckedBagObj.airlineCode);
    this.triggerOthers(param);
    this.flag1=true;
  }
  triggerOthers(param: SpecialCheckedBag) {
    if (param.pieceConcept !== 'Yes' && param.pieceConcept !== 'Not applicable' && param.pieceConcept !== 'No') {
      this.pieceCon = param.pieceConcept;
      this.specialcheckedBagObj.pieceConcept = 'Others';
    }
    if (param.weightConcept !== 'Yes' && param.weightConcept !== 'Not applicable' && param.weightConcept !== 'No') {
      this.weightCon = param.weightConcept;
      this.specialcheckedBagObj.weightConcept = 'Others';
    }
    if (param.ssrInterlineable !== 'Yes' && param.ssrInterlineable !== 'Not applicable' && param.ssrInterlineable !== 'No') {
      this.ssrInter = param.ssrInterlineable;
      this.specialcheckedBagObj.ssrInterlineable = 'Others';
    }
    if (param.ssrRequired !== 'Yes' && param.ssrRequired !== 'Not applicable' && param.ssrRequired !== 'No') {
      this.ssrRe = param.ssrRequired;
      this.specialcheckedBagObj.ssrRequired = 'Others';
    }

    if (param.items !== 'Ammunition' && param.items !== 'Archery equipment' && param.items !== 'Back packs'
      && param.items !== 'Bike' && param.items !== 'Body board' && param.items !== 'Boogie board' &&
      param.items !== 'Bowling equipment' && param.items !== 'Bulk baggage' && param.items !== 'Cabin baggage in seat'
      && param.items !== 'Canoe' && param.items !== 'Cricket' && param.items !== 'Emotional Support Animals' &&
      param.items !== 'Fencing Equipment' && param.items !== 'Film equipment' && param.items !== 'Firearms' &&
      param.items !== 'Fishing equipment' && param.items !== 'Golf equipment' && param.items !== 'Horse Riding Equipment'
      && param.items !== 'Ice hockey' && param.items !== 'Javelin' && param.items !== 'Kayaks' &&
      param.items !== 'Kite' && param.items !== 'Kite boarding' && param.items !== 'Kiteboards' &&
      param.items !== 'Mountain Climbing' && param.items !== 'Oars' && param.items !== 'Pets as Checked Baggage (AVIH)'
      && param.items !== 'Pets in Cabin' && param.items !== 'Portable Musical Instrument' && param.items !== 'Rackets (Tennis, Badminton)'
      && param.items !== 'Scuba Diving' && param.items !== 'Scuba equipment' && param.items !== 'Service Animals'
      && param.items !== 'Skis' && param.items !== 'Surfboard' && param.items !== 'Small Surfboard less than 2M'
      && param.items !== 'Snowboard' && param.items !== 'Surfboard' && param.items !== 'Wakeboard' &&
      param.items !== 'Waterskis' && param.items !== 'Wet Cell Batteries' && param.items !== 'Wheelchair non-powered'
      && param.items !== 'Wheelchair with Dry Cell Batteries' && param.items !== 'Wheelchair with Fitted Lithium Batteries'
      && param.items !== 'Wheelchair with Lithium Battery' && param.items !== 'Wheelchair with Lithium Ion Batteries'
      && param.items !== 'Wheelchair with spillable battery' && param.items !== 'Windsurfer') {
      this.cabinDefn = param.items;
      this.specialcheckedBagObj.items = 'Others';
    }
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.specialCheckedBagPrev = obj;
    this.specialcheckedBagObj = param;
  }
  setAirlineName(obj: string) {
    if (obj) {
      this.addEditAirlineName = this.airlineName.get(obj);
      this.specialcheckedBagObj.airlineName = this.addEditAirlineName;
      this.acrequired = false;
    } else {
      this.acrequired = true;
    }
  }


  saveAction(specialcheckedBagForm: NgForm, status?: any) {
    if (this.specialcheckedBagObj.pieceConcept === 'Others') {
      this.specialcheckedBagObj.pieceConcept = this.pieceCon;
    }
    if (this.specialcheckedBagObj.weightConcept === 'Others') {
      this.specialcheckedBagObj.weightConcept = this.weightCon;
    }
    if (this.specialcheckedBagObj.ssrInterlineable === 'Others') {
      this.specialcheckedBagObj.ssrInterlineable = this.ssrInter;
    }
    if (this.specialcheckedBagObj.ssrRequired === 'Others') {
      this.specialcheckedBagObj.ssrRequired = this.ssrRe;
    }
    if (this.specialcheckedBagObj.items === 'Others') {
      this.specialcheckedBagObj.items = this.cabinDefn;
    }

    if ((this.specialcheckedBagObj.airlineCode && this.specialcheckedBagObj.airlineCode.length < 3
      && this.specialcheckedBagObj.items && this.specialcheckedBagObj.items.length <= 200) && ((
        (this.specialcheckedBagObj.pieceConcept && this.specialcheckedBagObj.pieceConcept.length <= 100)
        || (this.specialcheckedBagObj.weightConcept && this.specialcheckedBagObj.weightConcept.length <= 100)
        || (this.specialcheckedBagObj.ssrInterlineable && this.specialcheckedBagObj.ssrInterlineable.length <= 100)
        || (this.specialcheckedBagObj.ssrCode && this.specialcheckedBagObj.ssrCode.length <= 200)
        || (this.specialcheckedBagObj.ssrRequired && this.specialcheckedBagObj.ssrRequired.length <= 100)
        || (this.specialcheckedBagObj.packageRequired && this.specialcheckedBagObj.packageRequired.length <= 500)
        || (this.specialcheckedBagObj.airlineName && this.specialcheckedBagObj.airlineName.length <= 100)) ||
        !(this.specialcheckedBagObj.pieceConcept || this.specialcheckedBagObj.weightConcept ||
          this.specialcheckedBagObj.ssrInterlineable || this.specialcheckedBagObj.ssrCode || this.specialcheckedBagObj.ssrRequired ||
          this.specialcheckedBagObj.packageRequired || this.specialcheckedBagObj.airlineName))) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.specialcheckedBagObj && this.specialcheckedBagObj.id > 0 && this.specialcheckedBagObj.userFlag === 'A') {
          this.specialcheckedBagObj.parentId = this.specialcheckedBagObj.id;
          this.specialcheckedBagObj.id = 0;
        }
      }
      this.specialcheckedBaggageHelper.specialCheckedBaggage = this.specialcheckedBagObj;
      this.specialcheckedBaggageHelper.userInfo = this.userInfo;
      if (this.specialCheckedBagPrev && this.specialCheckedBagPrev.id > 0) {
        this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialCheckedBagPrev;
      } else {
        this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialcheckedBagObj;
      }
      
      if(this.flag1){
        this.specialcheckedBagObj.userFlag = 'edit';
        this.flag1=false;
        }
        else
        {
          this.specialcheckedBagObj.userFlag = 'add';
          this.flag1=false;
        }
       
      
      this.specialcheckedBagService.saveAction(this.specialcheckedBaggageHelper, 'rej').subscribe(
        data => {
          if (data['Status']) {
            this.pendingSpecialCheckedBagList = data['Pending'];
            this.approvedSpecialCheckedBagList = data['Approved'];
            this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
            this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
            $('#addedit').modal('toggle');
          }
        }
      );
    }
    else {
      if (!this.specialcheckedBagObj.airlineCode) {
        this.airlineCodeValidation = true;
      } else {
        this.airlineCodeValidation = false;
      }
      if (!this.specialcheckedBagObj.items) {
        this.itemsValidation = true;
      } else {
        this.itemsValidation = false;
      }
    }
  }
  deleteAction() {
    this.specialcheckedBaggageHelper.specialCheckedBaggage = this.specialcheckedBagObj;
    this.specialcheckedBaggageHelper.userInfo = this.userInfo;
    if (this.specialCheckedBagPrev && this.specialCheckedBagPrev.id > 0) {
      this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialCheckedBagPrev;
    } else {
      this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialcheckedBagObj;
    }
    this.specialcheckedBagService.deleteAction(this.specialcheckedBaggageHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingSpecialCheckedBagList = data['Pending'];
          this.approvedSpecialCheckedBagList = data['Approved'];
          this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
          this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }

  addDataIcon(specialcheckedBagForm: NgForm) {
    this.specialcheckedBagObj = new SpecialCheckedBag();
    this.specialCheckedBagPrev = new SpecialCheckedBag();
    this. airlineCodeValidation= false;
    this.airlineNameValidation = false;
    this.itemsValidation = false;
    this.packageRequiredValidation = false;
    this.weightConceptValidation = false;
    this.SsrCodeValidation = false;
    this.SsrInterlineableValidation = false;
    this.SsrRequiredValidation = false;
    if (this.userInfo.userType === 'AUTHOR') {
      this.specialcheckedBagObj.airlineCode = this.userInfo.airlineId;
      this.setAirlineName(this.specialcheckedBagObj.airlineCode);
    }
  }

  searchData(param) {

    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      let airlineNameMap = new Map();
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingSpecialCheckedBagList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          opt = this.pendingSpecialCheckedBagList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.items && el.items.toLowerCase().includes(value)) ||
              (el.pieceConcept && el.pieceConcept.toLowerCase().includes(value)) ||
              (el.weightConcept && el.weightConcept.toLowerCase().includes(value)) ||
              (el.ssrCode && el.ssrCode.toLowerCase().includes(value)) ||
              (el.ssrInterlineable && el.ssrInterlineable.toLowerCase().includes(value)) ||
              (el.ssrRequired && el.ssrRequired.toLowerCase().includes(value)) ||
              (el.packageRequired && el.packageRequired.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingSpecialCheckedBagList = opt;
      } else {
        let list = JSON.parse(this.pendingSpecialCheckedBagListPrev);
        this.pendingSpecialCheckedBagList = list;
      }
    }
    else if (param === 'approved') {
      let airlineNameMap = new Map();
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedSpecialCheckedBagList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          opt = this.approvedSpecialCheckedBagList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.items && el.items.toLowerCase().includes(value)) ||
              (el.pieceConcept && el.pieceConcept.toLowerCase().includes(value)) ||
              (el.weightConcept && el.weightConcept.toLowerCase().includes(value)) ||
              (el.ssrCode && el.ssrCode.toLowerCase().includes(value)) ||
              (el.ssrInterlineable && el.ssrInterlineable.toLowerCase().includes(value)) ||
              (el.ssrRequired && el.ssrRequired.toLowerCase().includes(value)) ||
              (el.packageRequired && el.packageRequired.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedSpecialCheckedBagList = opt;
      } else {
        let list = JSON.parse(this.approvedSpecialCheckedBagListPrev);
        this.approvedSpecialCheckedBagList = list;
      }
    }
  }
  validationClear(param) {
    if (param === 'airlineCode')
      if (this.specialcheckedBagObj.airlineCode) {
        this.airlineCodeValidation = false;
      }
      else {
        this.airlineCodeValidation = true;
      }
    if (param === 'airlineName') {
      if (this.specialcheckedBagObj.airlineName) {
        this.airlineNameValidation = false;
      }
      else {
        this.airlineNameValidation = true;
      }
      if (this.specialcheckedBagObj.airlineName.length <= 100) {
        this.airlineNamelength = false;
      } else {
        this.airlineNamelength = true;
      }
    }
    else if (param === 'items') {
      if (this.specialcheckedBagObj.items) {
        this.itemsValidation = false;
      } else {
        this.itemsValidation = true;
      }
      if (this.specialcheckedBagObj.items.length <= 100) {
        this.itemslength = false;
      } else {
        this.itemslength = true;
      }
    }
    else if (param === 'pieceConcept') {
      if (this.specialcheckedBagObj.pieceConcept) {
        this.pieceConceptValidation = false;
      }
      else {
        this.pieceConceptValidation = true;
      }
      if (this.specialcheckedBagObj.pieceConcept.length <= 100) {
        this.pieceConceptlength = false;
      } else {
        this.pieceConceptlength = true;
      }
    }
    else if (param === 'weightConcept') {
      if (this.specialcheckedBagObj.weightConcept) {
        this.weightConceptValidation = false;
      }
      else {
        this.weightConceptValidation = true;
      }

      if (this.specialcheckedBagObj.weightConcept.length <= 100) {
        this.weightConceptlength = false;
      } else {
        this.weightConceptlength = true;
      }

    }
    else if (param === 'ssrInterlineable') {
      if (this.specialcheckedBagObj.ssrInterlineable) {
        this.SsrInterlineableValidation = false;
      }
      else {
        this.SsrInterlineableValidation = true;
      }

      if (this.specialcheckedBagObj.ssrInterlineable.length <= 100) {
        this.SsrInterlineablelength = false;
      } else {
        this.SsrInterlineablelength = true;
      }

    }
    else if (param === 'ssrRequired') {
      if (this.specialcheckedBagObj.ssrRequired) {
        this.SsrRequiredValidation = false;
      }
      else {
        this.SsrRequiredValidation = true;
      }

      if (this.specialcheckedBagObj.ssrRequired.length <= 100) {
        this.SsrRequiredlength = false;
      } else {
        this.SsrRequiredlength = true;
      }

    }
    else if (param === 'ssrCode') {
      if (this.specialcheckedBagObj.ssrCode) {
        this.SsrCodeValidation = false;
      }
      else {
        this.SsrCodeValidation = true;
      }

      if (this.specialcheckedBagObj.ssrCode.length <= 200) {
        this.SsrCodelength = false;
      } else {
        this.SsrCodelength = true;
      }

    }
    else if (param === 'packageRequired') {
      if (this.specialcheckedBagObj.packageRequired) {
        this.packageRequiredValidation = false;
      }
      else {
        this.packageRequiredValidation = true;
      }

      if (this.specialcheckedBagObj.packageRequired.length <= 500) {
        this.packageRequiredlength = false;
      } else {
        this.packageRequiredlength = true;
      }

    }
  }

  cancelAction() {
    if (this.specialcheckedBagObj && this.specialcheckedBagObj.id > 0) {
      let obj = Object.assign({}, this.specialCheckedBagPrev);
      this.specialcheckedBagObj = obj;
    } else {
      this.specialcheckedBagObj = new SpecialCheckedBag();
      this.specialcheckedBagObj.airlineCode = '';
      this.specialcheckedBagObj.airlineName = '';
      this.specialcheckedBagObj.items = '';
      this.specialcheckedBagObj.pieceConcept = '';
      this.specialcheckedBagObj.weightConcept = '';
      this.specialcheckedBagObj.ssrCode = '';
      this.specialcheckedBagObj.ssrInterlineable = '';
      this.specialcheckedBagObj.ssrRequired = '';
      this.specialcheckedBagObj.packageRequired = '';
      this.addEditAirlineName = '';
      this.specialcheckedBagObj.id = 0;
      this.itemso='';
      this.pieceConn='';
      this.weightConn='';
      this.ssrInterl='';
      this.ssrReq='';
      this.items='';
  this.pieceConcept='';
  this.weightConcept='';
  this.ssrInterlineable='';
  this.ssrRequired='';
  this.pieceCon='';
  this.weightCon='';
  this.ssrInter='';
  this.ssrRe='';
  this.cabinDefn='';
      if (this.userInfo.userType === 'AUTHOR') {
        this.specialcheckedBagObj.airlineCode = this.userInfo.airlineId;
      }
    }
    this.setAirlineName(this.specialcheckedBagObj.airlineCode);

    this.airlineNameValidation = false;
    this.itemsValidation = false;
    this.airlineCodeValidation=false;
    this.pieceConceptValidation = false;
    this.weightConceptValidation = false;
    this.SsrInterlineableValidation = false;
    this.SsrRequiredValidation = false;
    this.SsrCodeValidation = false;
    this.packageRequiredValidation = false;
    this.airlineNamelength = false;
    this.itemslength = false;
    this.packageRequiredlength = false;
    this.pieceConceptlength = false;
    this.weightConceptlength = false;
    this.SsrCodelength = false;
    this.SsrInterlineablelength = false;
    this.SsrRequiredValidation = false;

  }
  closeAction() {
    this.specialcheckedBagObj.id = 0;
    this.airlineCodeValidation = false;
    this.airlineNameValidation = false;
    this.airlineNamelength = false;
    this.itemsValidation = false;
    this.itemslength = false;
    this.pieceConceptValidation = false;
    this.pieceConceptlength = false;
    this.weightConceptValidation = false;
    this.weightConceptlength = false;
    this.SsrCodeValidation = false;
    this.SsrCodelength = false;
    this.SsrInterlineableValidation = false;
    this.SsrRequiredValidation = false;
    this.SsrInterlineablelength = false;
    this.SsrRequiredlength = false;
    this.packageRequiredValidation = false;
    this.packageRequiredlength = false;
    this.pieceCon='';
    this.weightCon='';
    this.ssrInter='';
    this.ssrRe='';
    this.cabinDefn='';
    this.itemso='';
    this.pieceConn='';
    this.weightConn='';
    this.ssrInterl='';
    this.ssrReq='';
    this.items='';
    this.pieceConcept='';
    this.weightConcept='';
    this.ssrInterlineable='';
    this.ssrRequired='';
    this.specialcheckedBagObj = this.specialCheckedBagPrev;
    if (this.pendingSpecialCheckedBagListPrev) {
      this.pendingSpecialCheckedBagList = JSON.parse(this.pendingSpecialCheckedBagListPrev);
    }
    if (this.approvedSpecialCheckedBagListPrev) {
      this.approvedSpecialCheckedBagList = JSON.parse(this.approvedSpecialCheckedBagListPrev);
    }

  }
  approveAction(param) {
    this.specialcheckedBagObj = param;
    this.specialcheckedBaggageHelper.specialCheckedBaggage = this.specialcheckedBagObj;
    this.specialcheckedBaggageHelper.userInfo = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.specialcheckedBaggageHelper.specialCheckedBaggage.userFlag = 'app';
      this.specialcheckedBagService.saveAction(this.specialcheckedBaggageHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingSpecialCheckedBagList = data['Pending'];
            this.approvedSpecialCheckedBagList = data['Approved'];
            this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
            this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else
      if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
        (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
        this.specialcheckedBagService.deleteAction(this.specialcheckedBaggageHelper).subscribe(
          data => {
            if (data['Status']) {
              this.pendingSpecialCheckedBagList = data['Pending'];
              this.approvedPagination = data['Approved'];
              this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
              this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
              this.statusMsg = 'Data Deleted Successfully.';
              $('#statusId').modal('toggle');
            }
          }
        );
      }
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  rejectAction() {
    if (this.rejectionJustification) {
      this.specialcheckedBaggageHelper.specialCheckedBaggage = this.specialcheckedBagObj;
      this.specialcheckedBaggageHelper.userInfo = this.userInfo;
      if (this.specialCheckedBagPrev && this.specialCheckedBagPrev.id > 0) {
        this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialCheckedBagPrev;
      } else {
        this.specialcheckedBaggageHelper.specialCheckedBaggagePrev = this.specialcheckedBagObj;
      }
      this.specialcheckedBaggageHelper.specialCheckedBaggage.userFlag = 'rej';
      this.specialcheckedBagService.saveAction(this.specialcheckedBaggageHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingSpecialCheckedBagList = data['Pending'];
            this.approvedSpecialCheckedBagList = data['Approved'];
            this.pendingSpecialCheckedBagListPrev = JSON.stringify(this.pendingSpecialCheckedBagList);
            this.approvedSpecialCheckedBagListPrev = JSON.stringify(this.approvedSpecialCheckedBagList);
            this.statusMsg = 'Mail Sent.';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }

  downloadExcel(param) {
    this.specialcheckedBagService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        //let blob = new Blob([data], { type: 'application/octet-stream' });
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'SpecialCheckedBaggage.xls');
      }
    );
  }

  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.specialCheckedBagPrev = obj;
    this.specialcheckedBagObj = param;
    $('#rejectionId').modal('toggle');
  }

  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingSpecialCheckedBagListPrev) {
      this.pendingSpecialCheckedBagList = JSON.parse(this.pendingSpecialCheckedBagListPrev);
    }
    if (this.approvedSpecialCheckedBagListPrev) {
      this.approvedSpecialCheckedBagList = JSON.parse(this.approvedSpecialCheckedBagListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedSpecialCheckedBagListPrev) {
        let list = JSON.parse(this.approvedSpecialCheckedBagListPrev);
        this.approvedSpecialCheckedBagList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingSpecialCheckedBagListPrev) {
        let list = JSON.parse(this.pendingSpecialCheckedBagListPrev);
        this.pendingSpecialCheckedBagList = list;
        this.searchData(param);
      }
    }
  }
}


