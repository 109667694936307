export class FFPProgramOverview {
	public airlineCode: string = '';

	public ffpProgramName: string = '';

	public starTierLevel: string = '';

	public ffpTierLevel: string = '';

	public airlineName: string = '';

	public isMemberAirline: string = '';
	id: number = 0;
	public userFlag: string = '';

	public parentId: number = 0;

}
