import { Component, OnInit } from '@angular/core';
import { AirportAtlas } from 'src/app/model/airport-atlas';
import { User } from 'src/app/model/user';
import { AirportAtlasHelper } from 'src/app/model/airport-atlas-helper';
import { NgForm } from '@angular/forms';
import { AirportAtlasService } from 'src/app/services/aiport-atlas.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { ErrorModel } from 'src/app/model/error-model';

declare var $: any;
@Component({
  selector: 'app-airport-atlas',

  templateUrl: './airport-atlas.component.html'


})
export class AirportAtlasComponent implements OnInit {


  airportAtlas = new AirportAtlas();
  pendingAirportAtlasList: AirportAtlas[] = [];
  approvedAirportAtlasList: AirportAtlas[] = [];
  airportAtlasList: AirportAtlas[] = [];
  userInfo = new User();
  airportAtlasHelper = new AirportAtlasHelper();
  searchKey = '';
  pendingAirportAtlasListPrev: any;
  approvedAirportAtlasListPrev: any;
  //airportAtlaListPrev: any;
  p: number = 1;
  p1: number = 1;
  statusMsg: string = '';
  iataAirportCodeValidation: boolean;
  iataAirportCodelength: boolean;
  iataAirportCodePattern: boolean;
  icaoAirportCodelength: boolean;
  icaoAirportCodePattern: boolean;
  iataCityCodelength: boolean;
  iataCityCodePattern: boolean;
  airportNameValidation: boolean;
  airportNamelength: boolean;
  countryCodeValidation: boolean;
  countryCodelength: boolean;
  countryNameValidation: boolean;
  countryNamelength: boolean;
  cityCodelength: boolean;
  cityNameValidation: boolean;
  cityNamelength: boolean;
  timezonelength: boolean;
  airportLongitudelength: boolean;
  airportLatitudelength: boolean;
  regEx = /^[a-zA-Z0-9\\s]*[a-zA-Z0-9][a-zA-Z0-9\\s]+$/g;
  airportAtlasPrev = new AirportAtlas();
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  //airportAtlasListPrev: any;
  //airportLongValidation: boolean;
  airportLatitudeValidation: boolean;
  airportLongitudeValidation: boolean;
  rejectActionCheck: boolean;
  countryNames = [];
  flag1:boolean=false;
  countryNameCheck: boolean;
  countryName: string = '';
  countryNameSelect: boolean;
  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  constructor(private airportAtlasService: AirportAtlasService, private router: Router) { }

  ngOnInit() {
    //$('[data-toggle="tooltip"]').tooltip();
    // this.profileInfoClose();
    this.countryNameCheck = false;
    this.onLoad();
  }
  onLoad() {
    this.airportAtlasService.getCountries().subscribe(
      data => {
        if (data['Countries']) {
          this.countryNames = data['Countries'];
        }
      }
    );
  }


  onChangeLoad() {
    this.countryNameCheck = false;
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    if (this.countryName) {
      this.countryNameSelect = true;
      this.airportAtlasService.loadData(this.userInfo, this.countryName).subscribe(
        data => {
          this.pendingAirportAtlasList = data['Pending'];
          this.approvedAirportAtlasList = data['Approved'];
          this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
          this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
          this.countryNameCheck = true;
          this.paginationTrigger('pending');

          //this.airportAtlasList = data['AirportAtlasList'];
          //this.airportAtlasList = Object.assign([], this.airportAtlasList);
          //this.airportAtlasListPrev = JSON.stringify(this.airportAtlasList);

        }
      );
    } else {
      this.pendingAirportAtlasList = [];
      this.approvedAirportAtlasList = [];
      this.countryNameSelect = true;
    }
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list?: any, usrType?: any) {
    let obj = Object.assign({}, param);
    this.airportAtlasPrev = obj;
    this.airportAtlas = param;
    this.flag1=true;
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.airportAtlasPrev = obj;
    this.airportAtlas = param;
  }



  saveAction(airportAtlasForm: NgForm, status?: any) {
    if ((this.airportAtlas.iataAirportCode && this.airportAtlas.airportName && this.airportAtlas.countryCode
      && this.airportAtlas.countryName && this.airportAtlas.cityName && this.airportAtlas.airportLongitude
      && this.airportAtlas.airportLatitude &&
      this.airportAtlas.iataAirportCode.length == 3 &&
      this.airportAtlas.countryCode.length == 2 &&
      this.airportAtlas.countryName.length <= 100 &&
      this.airportAtlas.cityName.length <= 100 &&
      this.airportAtlas.airportLongitude.length <= 100 &&
      this.airportAtlas.airportLatitude.length <= 100 &&
      this.airportAtlas.airportName.length <= 200) && (
        ((this.airportAtlas.cityCode && this.airportAtlas.cityCode.length == 3)
          || (this.airportAtlas.icaoAirportCode && this.airportAtlas.icaoAirportCode.length <= 7)
          || (this.airportAtlas.iataCityCode && this.airportAtlas.iataCityCode.length == 3)
          || (this.airportAtlas.timezone && this.airportAtlas.timezone.length <= 100)) || (!(this.airportAtlas.cityCode ||
            this.airportAtlas.icaoAirportCode || this.airportAtlas.iataCityCode || this.airportAtlas.timezone)))) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.airportAtlas && this.airportAtlas.id > 0 && this.airportAtlas.userFlag === 'A') {
          this.airportAtlas.parentId = this.airportAtlas.id;
          this.airportAtlas.id = 0;
        }
      }
      this.airportAtlasHelper.airportAtlas = this.airportAtlas;
      if (this.airportAtlasPrev && this.airportAtlasPrev.id > 0) {
        this.airportAtlasHelper.airportAtlasPrev = this.airportAtlasPrev;
      } else {
        this.airportAtlasHelper.airportAtlasPrev = this.airportAtlas;
      }
      this.airportAtlasHelper.userInfo = this.userInfo;
      if(this.flag1){
        this.airportAtlasHelper.airportAtlas.userFlag= 'edit';
        this.flag1=false;
        }
        else
        {
          this.airportAtlasHelper.airportAtlas.userFlag= 'add';
          this.flag1=false;
        }
  
      this.airportAtlasService.saveAction(this.airportAtlasHelper, 'ejb').subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirportAtlasList = data['Pending'];
            this.approvedAirportAtlasList = data['Approved'];
            this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
            this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if (!this.airportAtlas.iataAirportCode) {
        this.iataAirportCodeValidation = true;
      } else {
        this.iataAirportCodeValidation = false;
      }
      if (!this.airportAtlas.airportName) {
        this.airportNameValidation = true;
      } else {
        this.airportNameValidation = false;
      }
      if (!this.airportAtlas.countryCode) {
        this.countryCodeValidation = true;
      } else {
        this.countryCodeValidation = false;
      }
      if (!this.airportAtlas.countryName) {
        this.countryNameValidation = true;
      } else {
        this.countryNameValidation = false;
      }
      if (!this.airportAtlas.cityName) {
        this.cityNameValidation = true;
      } else {
        this.cityNameValidation = false;
      }
      if (!this.airportAtlas.airportLongitude) {
        this.airportLongitudeValidation = true;
      } else {
        this.airportLongitudeValidation = false;
      }
      if (!this.airportAtlas.airportLatitude) {
        this.airportLatitudeValidation = true;
      } else {
        this.airportLatitudeValidation = false;
      }
    }
  }

  deleteAction() {
    this.airportAtlasHelper.airportAtlas = this.airportAtlas;
    this.airportAtlasHelper.userInfo = this.userInfo;
    if (this.airportAtlasPrev && this.airportAtlasPrev.id > 0) {
      this.airportAtlasHelper.airportAtlasPrev = this.airportAtlasPrev;
    } else {
      this.airportAtlasHelper.airportAtlasPrev = this.airportAtlas;
    }
    this.airportAtlasService.deleteAction(this.airportAtlasHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingAirportAtlasList = data['Pending'];
          this.approvedAirportAtlasList = data['Approved'];
          this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
          this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }


  addDataIcon(airportAtlasForm: NgForm) {
    this.airportAtlas = new AirportAtlas();
    this.airportAtlasPrev = new AirportAtlas();
    this.iataAirportCodeValidation = false;
    this.airportNameValidation = false;
    this.countryCodeValidation = false;
    this.countryNameValidation = false;
    this.cityNameValidation = false;
    this.airportLongitudeValidation = false;
    this.airportLatitudeValidation = false;
    this.airportAtlas.id = 0;
    airportAtlasForm.control.markAsUntouched;
    airportAtlasForm.control.markAsPristine;
  }

  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingAirportAtlasList.filter(function (el, idx, array) {
            if ((el.iataAirportCode && el.iataAirportCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.pendingAirportAtlasList.filter(function (el, idx, array) {
            if ((el.iataAirportCode && el.iataAirportCode.toLowerCase().includes(value)) ||
              (el.icaoAirportCode && el.icaoAirportCode.toLowerCase().includes(value)) ||
              (el.iataCityCode && el.iataCityCode.toLowerCase().includes(value)) ||
              (el.airportName && el.airportName.toLowerCase().includes(value)) ||
              (el.countryCode && el.countryCode.toLowerCase().includes(value)) ||
              (el.countryName && el.countryName.toLowerCase().includes(value)) ||
              (el.cityCode && el.cityCode.toLowerCase().includes(value)) ||
              (el.cityName && el.cityName.toLowerCase().includes(value)) ||
              (el.timezone && el.timezone.toLowerCase().includes(value)) ||
              (el.airportLongitude && el.airportLongitude.toLowerCase().includes(value)) ||
              (el.airportLatitude && el.airportLatitude.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingAirportAtlasList = opt;
      } else {
        let list = JSON.parse(this.pendingAirportAtlasListPrev);
        this.pendingAirportAtlasList = list;
      }
    } else if (param === 'approved') {

      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedAirportAtlasList.filter(function (el, idx, array) {
            if ((el.iataAirportCode && el.iataAirportCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.approvedAirportAtlasList.filter(function (el, idx, array) {
            if ((el.iataAirportCode && el.iataAirportCode.toLowerCase().includes(value)) ||
              (el.icaoAirportCode && el.icaoAirportCode.toLowerCase().includes(value)) ||
              (el.iataCityCode && el.iataCityCode.toLowerCase().includes(value)) ||
              (el.airportName && el.airportName.toLowerCase().includes(value)) ||
              (el.countryCode && el.countryCode.toLowerCase().includes(value)) ||
              (el.countryName && el.countryName.toLowerCase().includes(value)) ||
              (el.cityCode && el.cityCode.toLowerCase().includes(value)) ||
              (el.cityName && el.cityName.toLowerCase().includes(value)) ||
              (el.timezone && el.timezone.toLowerCase().includes(value)) ||
              (el.airportLongitude && el.airportLongitude.toLowerCase().includes(value)) ||
              (el.airportLatitude && el.airportLatitude.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedAirportAtlasList = opt;
      } else {
        let list = JSON.parse(this.approvedAirportAtlasListPrev);
        this.approvedAirportAtlasList = list;
      }
    }
  }
  downloadExcel(param) {
    this.airportAtlasService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId + '::' + this.countryName).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'AirportAtlas.xls');
      }
    );
  }


  validationClear(param) {
    if (param === 'iataAirportCode') {
      let regBoolean = this.regEx.test(this.airportAtlas.iataAirportCode);
      if (this.airportAtlas.iataAirportCode) {
        this.iataAirportCodeValidation = false;
      } else {
        this.iataAirportCodeValidation = true;
      }
      if (this.airportAtlas.iataAirportCode.length == 3) {
        this.iataAirportCodelength = false;
      } else {
        this.iataAirportCodelength = true;
      }
      /* if(regBoolean) {
          this.iataAirportCodePattern = false;
        } else {
          this.iataAirportCodePattern = true;
        } */
    }

    if (param === 'icaoAirportCode') {
      let regBoolean = this.regEx.test(this.airportAtlas.icaoAirportCode);
      if (this.airportAtlas.icaoAirportCode.length <= 7) {
        this.icaoAirportCodelength = false;
      } else {
        this.icaoAirportCodelength = true;
      }
	    /* if(regBoolean) {
        this.icaoAirportCodePattern = false;
      } else {
        this.icaoAirportCodePattern = true;
      } */
    }
    if (param === 'iataCityCode') {
      let regBoolean = this.regEx.test(this.airportAtlas.iataCityCode);
      if (this.airportAtlas.iataCityCode.length == 3) {
        this.iataCityCodelength = false;
      } else {
        this.iataCityCodelength = true;
      }
	    /* if(regBoolean) {
        this.iataCityCodePattern = false;
      } else {
        this.iataCityCodePattern = true;
      } */
    }

    if (param === 'airportName') {
      if (this.airportAtlas.airportName) {
        this.airportNameValidation = false;
      } else {
        this.airportNameValidation = true;
      }
      if (this.airportAtlas.airportName.length <= 200) {
        this.airportNamelength = false;
      } else {
        this.airportNamelength = true;
      }

    }
    if (param === 'countryCode') {
      if (this.airportAtlas.countryCode) {
        this.countryCodeValidation = false;
      } else {
        this.countryCodeValidation = true;
      }
      if (this.airportAtlas.countryCode.length == 2) {
        this.countryCodelength = false;
      } else {
        this.countryCodelength = true;
      }

    }

    if (param === 'countryName') {
      if (this.airportAtlas.countryName) {
        this.countryNameValidation = false;
      } else {
        this.countryNameValidation = true;
      }
      if (this.airportAtlas.countryName.length <= 100) {
        this.countryNamelength = false;
      } else {
        this.countryNamelength = true;
      }
    }
    if (param === 'cityCode') {

      if (this.airportAtlas.cityCode.length == 3) {
        this.cityCodelength = false;
      } else {
        this.cityCodelength = true;
      }
    }

    if (param === 'cityName') {
      if (this.airportAtlas.cityName) {
        this.cityNameValidation = false;
      } else {
        this.cityNameValidation = true;
      }
      if (this.airportAtlas.cityName.length <= 100) {
        this.cityNamelength = false;
      } else {
        this.cityNamelength = true;
      }
    }

    if (param === 'timezone') {

      if (this.airportAtlas.timezone.length <= 100) {
        this.timezonelength = false;
      } else {
        this.timezonelength = true;
      }
    }
    if (param === 'airportLongitude') {
      if (this.airportAtlas.airportLongitude) {
        this.airportLongitudeValidation = false;
      } else {
        this.airportLongitudeValidation = true;
      }
      if (this.airportAtlas.airportLongitude.length <= 100) {
        this.airportLongitudelength = false;
      } else {
        this.airportLongitudelength = true;
      }
    }

    if (param === 'airportLatitude') {
      if (this.airportAtlas.airportLatitude) {
        this.airportLatitudeValidation = false;
      } else {
        this.airportLatitudeValidation = true;
      }
      if (this.airportAtlas.airportLatitude.length <= 100) {
        this.airportLatitudelength = false;
      } else {
        this.airportLatitudelength = true;
      }
    }
  }

  cancelAction() {
    if (this.airportAtlas && this.airportAtlas.id > 0) {
      let obj = Object.assign({}, this.airportAtlasPrev);
      this.airportAtlas = obj;
    } else {
      this.airportAtlas = new AirportAtlas();
      this.airportAtlas.iataAirportCode = '';
      this.airportAtlas.icaoAirportCode = '';
      this.airportAtlas.iataCityCode = '';
      this.airportAtlas.airportName = '';
      this.airportAtlas.countryCode = '';
      this.airportAtlas.countryName = '';
      this.airportAtlas.cityCode = '';
      this.airportAtlas.cityName = '';
      this.airportAtlas.timezone = '';
      this.airportAtlas.airportLongitude = '';
      this.airportAtlas.airportLatitude = '';
      this.airportAtlas.id = 0;
    }

    this.iataAirportCodeValidation = false;
    this.iataAirportCodelength = false;
    this.iataAirportCodePattern = false;
    this.icaoAirportCodelength = false;
    this.icaoAirportCodePattern = false;
    this.iataCityCodelength = false;
    this.iataCityCodePattern = false;
    this.airportNameValidation = false;
    this.airportNamelength = false;
    this.countryCodeValidation = false;
    this.countryCodelength = false;
    this.countryNameValidation = false;
    this.countryNamelength = false;
    this.cityCodelength = false;
    this.cityNameValidation = false;
    this.cityNamelength = false;
    this.timezonelength = false;
    this.airportLongitudelength = false;
    this.airportLatitudelength = false;
    this.airportLongitudeValidation = false;
    this.airportLatitudeValidation = false;
  }
  closeAction() {
    this.airportAtlas.id = 0;
    this.iataAirportCodeValidation = false;
    this.iataAirportCodelength = false;
    this.iataAirportCodePattern = false;
    this.icaoAirportCodelength = false;
    this.icaoAirportCodePattern = false;
    this.iataCityCodelength = false;
    this.iataCityCodePattern = false;
    this.airportNameValidation = false;
    this.airportNamelength = false;
    this.countryCodeValidation = false;
    this.countryCodelength = false;
    this.countryNameValidation = false;
    this.countryNamelength = false;
    this.cityCodelength = false;
    this.cityNameValidation = false;
    this.cityNamelength = false;
    this.timezonelength = false;
    this.airportLongitudelength = false;
    this.airportLatitudelength = false;
    this.airportLongitudeValidation = false;
    this.airportLatitudeValidation = false;

    //this.airportAtlasList = JSON.parse(this.airportAtlasListPrev);
    this.airportAtlas = this.airportAtlasPrev;
    if (this.pendingAirportAtlasListPrev) {
      this.pendingAirportAtlasList = JSON.parse(this.pendingAirportAtlasListPrev);
    }
    if (this.approvedAirportAtlasListPrev) {
      this.approvedAirportAtlasList = JSON.parse(this.approvedAirportAtlasListPrev);
    }
  }
  approveAction(param) {
    this.airportAtlas = param;
    this.airportAtlasHelper.airportAtlas = param;
    this.airportAtlasHelper.userInfo = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.airportAtlasHelper.airportAtlas.userFlag = 'app';
      this.airportAtlasService.saveAction(this.airportAtlasHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirportAtlasList = data['Pending'];
            this.approvedAirportAtlasList = data['Approved'];
            this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
            this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.airportAtlasService.deleteAction(this.airportAtlasHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirportAtlasList = data['Pending'];
            this.approvedAirportAtlasList = data['Approved'];
            this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
            this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.airportAtlasHelper.airportAtlas = this.airportAtlas;
    this.airportAtlasHelper.userInfo = this.userInfo;
    if (this.airportAtlasPrev && this.airportAtlasPrev.id > 0) {
      this.airportAtlasHelper.airportAtlasPrev = this.airportAtlasPrev;
    } else {
      this.airportAtlasHelper.airportAtlasPrev = this.airportAtlas;
    }
    this.airportAtlasHelper.airportAtlas.userFlag = 'rej';
    if (this.rejectionJustification) {
      this.airportAtlasHelper.airportAtlas = this.airportAtlas;
      this.airportAtlasHelper.userInfo = this.userInfo;
      this.airportAtlasHelper.airportAtlas.userFlag = 'rej';

      this.airportAtlasService.saveAction(this.airportAtlasHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirportAtlasList = data['Pending'];
            this.approvedAirportAtlasList = data['Approved'];
            this.pendingAirportAtlasListPrev = JSON.stringify(this.pendingAirportAtlasList);
            this.approvedAirportAtlasListPrev = JSON.stringify(this.approvedAirportAtlasList);
            this.statusMsg = 'Mail Sent.';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }

  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.airportAtlasPrev = obj;
    this.airportAtlas = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingAirportAtlasListPrev) {
      this.pendingAirportAtlasList = JSON.parse(this.pendingAirportAtlasListPrev);
    }
    if (this.approvedAirportAtlasListPrev) {
      this.approvedAirportAtlasList = JSON.parse(this.approvedAirportAtlasListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedAirportAtlasListPrev) {
        let list = JSON.parse(this.approvedAirportAtlasListPrev);
        this.approvedAirportAtlasList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingAirportAtlasListPrev) {
        let list = JSON.parse(this.pendingAirportAtlasListPrev);
        this.pendingAirportAtlasList = list;
        this.searchData(param);
      }
    }
  }
}



