export class AirportAtlas {
	public iataAirportCode: string = '';

	public icaoAirportCode: string = '';

	public iataCityCode: string = '';

	public airportName: string = '';

	public countryCode: string = '';

	public countryName: string = '';

	public cityCode: string = '';

	public cityName: string = '';

	public timezone: string = '';

	public airportLongitude: string = '';

	public airportLatitude: string = '';

	public userFlag: string;
	id: number;
	createdBy: string = '';
	airlineCode: string = '';
	public parentId: number = 0;

}


