export class CarryonBag {
	public airlineCode: string = '';

	public cabinDefinition: string = '';

	public route: string = '';

	public baggagePieceWeightConcept: string = '';

	public baggagePieces: string = '';

	public baggageMaxDimensionsCms: string = '';

	public baggageMaxWeightKg: string = '';

	public baggageStarGoldAdditionalAllowance: string = '';

	public personalItems: string = '';

	public infantNoSeat: string = '';

	public infantWithSeat: string = '';

	public comments: string = '';

	public id: number = 0;

	public userFlag: string = '';
	public parentId: number = 0;
}

