import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { FfpBenefitsService } from 'src/app/services/ffp-benefits.service';
import { FfpBenefits } from 'src/app/model/ffp-benefits';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/model/user';
import { FfpBenefitsHelper } from 'src/app/model/ffp-benefits-helper';
import { NgForm } from '@angular/forms';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { FFPProgramOverview } from 'src/app/model/ffpprogram-overview';
import { ErrorModel } from 'src/app/model/error-model';
import { param } from 'jquery';
declare var $: any;
@Component({
  selector: 'app-ffp-benefits',
  templateUrl: './ffp-benefits.component.html',
  styleUrls: ['./ffp-benefits.component.css']
})
export class FfpBenefitsComponent implements OnInit {

  ffpBenefits: FfpBenefits[] = [];
  ffpBenefitsObj = new FfpBenefits();
  airline: AirlineSummary[] = [];
  ffpInfo: FFPProgramOverview[] = [];
  userInfo = new User();
  airlineKeysList = [];
  starTierLevelBenefit = [];

  tierBenefitsList = ['Airport Lounge Access', 'Gold Track (Priority Security and Immigration)',
    'Extra Baggage Allowance', 'Priority Airport Check-in', 'Priority Boarding', 'Priority Baggage Handling',
    'Priority Reservation Waitlist', 'Priority Airport Stand-by', 'Gold/Fast Track Security'];
  checkedBenefits = [];
  checkedBenefit: string = '';
  ffpBenefitsHelper = new FfpBenefitsHelper();
  airlineName = new Map();
  ffpName = new Map();
  memberAirline = new Map();
  p: number = 1;
  p1: number = 1;
  addEditAirlineName: any;
  addEditFfpName: any;
  searchKey = '';
  ffpBenefitsListPrev: any;
  statusMsg: string = '';
  acrequired: boolean;
  aclength: boolean;
  stlrequired: boolean;
  stllength: boolean;
  stlbrequired: boolean;
  flag1:boolean=false;
  stlblength: boolean;
  ffpBenefitsPendingList: any;
  ffpBenefitsApprovedList: any;
  ffpBenefitsPendingListPrev: string;
  ffpBenefitsApprovedListPrev: string;
  starTierLevelBenefitsReq: boolean = false;
  starTierLevelReq: boolean = false;
  airlineCodeReq: boolean = false;
  rejectActionCheck: boolean = false;
  rejectionJustification: string = '';
  ffpBenefitsObjPrev = new FfpBenefits();
  benefitsLevel = [1];
  isMemberAirline: any;
  errorModel = new ErrorModel();
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  starTierLevelBenefitsChk = [];
  constructor(private serObj: FfpBenefitsService) { }

  ngOnInit() {
    this.onLoadMethod();
  }

  onLoadMethod() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.serObj.loadData(this.userInfo).subscribe(
      data => {
        this.ffpBenefitsPendingList = data['Pending'];
        this.ffpBenefitsApprovedList = data['Approved'];
        this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
        this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        this.ffpInfo = JSON.parse(sessionStorage.getItem('FFPInfo'));
        let airKeys = [];
        for (let index = 0; index < this.airline.length; index++) {
          airKeys.push(this.airline[index].airlineCode);
          this.airlineName.set(this.airline[index].airlineCode, this.airline[index].airlineName);
          this.memberAirline.set(this.airline[index].airlineCode, this.airline[index].isMemberAirline);
        }
        for (let i = 0; i < this.ffpInfo.length; i++) {
          this.ffpName.set(this.ffpInfo[i].airlineCode, this.ffpInfo[i].ffpProgramName);
        }
        this.airlineKeysList = airKeys.sort();
      }

    );
  }

  editMethod(param) {
    this.checkedBenefits = [];
    let obj = Object.assign({}, param);
    this.ffpBenefitsObjPrev = obj;
    this.ffpBenefitsObj = param;
    this.setAirlineAndFFPName(this.ffpBenefitsObj.airlineCode);
    this.setCheckedItems(this.ffpBenefitsObj);
    this.flag1=true;
 
    if (param.starTierLevel === 'Gold' || param.starTierLevel === 'G') {
      this.ffpBenefitsObj.starTierLevel = 'Gold';
    } else if (param.starTierLevel === 'Silver' || param.starTierLevel === 'S') {
      this.ffpBenefitsObj.starTierLevel = 'Silver';
    } else if (param.starTierLevel === 'Base' || param.starTierLevel === 'B') {
      this.ffpBenefitsObj.starTierLevel = 'Base';
    }

    for (let i = 0; i < this.checkedBenefits.length; i++) {
      if (i === this.checkedBenefits.length - 1) {
        this.checkedBenefit += (i + 1) + '. ' + this.checkedBenefits[i];
      } else {
        this.checkedBenefit += (i + 1) + '. ' + this.checkedBenefits[i] + ', ';
      }
    }
     this.checkedBenefits = this.checkedBenefits.sort();
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.ffpBenefitsObjPrev = obj;
    this.ffpBenefitsObj = param;
  }

  saveAction(ffpBenefitsForm: NgForm, status?: any) {
    this.ffpBenefitsObj.starTierLevelBenefits = this.checkedBenefit;
    if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
      if (this.ffpBenefitsObj && this.ffpBenefitsObj.id > 0 && this.ffpBenefitsObj.userFlag === 'A') {
        this.ffpBenefitsObj.parentId = this.ffpBenefitsObj.id;
        this.ffpBenefitsObj.id = 0;
      }
    }
    this.ffpBenefitsHelper.ffpBenefits = this.ffpBenefitsObj;
    if (this.ffpBenefitsObjPrev && this.ffpBenefitsObjPrev.id > 0) {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObjPrev;
    } else {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObj;
    }
    this.ffpBenefitsHelper.userDetails = this.userInfo;
  
    if(this.flag1){
      this.ffpBenefitsHelper.ffpBenefits.userFlag  = 'edit';
      this.flag1=false;
      }
      else
      {
        this.ffpBenefitsHelper.ffpBenefits.userFlag  = 'add';
        this.flag1=false;
      }
    if (ffpBenefitsForm.valid && this.ffpBenefitsObj.starTierLevelBenefits) {
      this.serObj.saveAction(this.ffpBenefitsHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.ffpBenefitsPendingList = data['Pending'];
            this.ffpBenefitsApprovedList = data['Approved'];
            this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
            this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if (!this.ffpBenefitsObj.airlineCode) {
        this.airlineCodeReq=true;
      }
      else{
        this.airlineCodeReq=false;
      }
      if(this.checkedBenefit.length==0)
      {
        this.starTierLevelBenefitsReq=true;
      }
      else
      {
        this.starTierLevelBenefitsReq=false;

      }

    }
    this.checkedBenefit='';
  }

  deleteAction() {
    this.ffpBenefitsHelper.ffpBenefits = this.ffpBenefitsObj;
    this.ffpBenefitsHelper.userDetails = this.userInfo;
    if (this.ffpBenefitsObjPrev && this.ffpBenefitsObjPrev.id > 0) {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObjPrev;
    } else {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObj;
    }
    this.serObj.deleteAction(this.ffpBenefitsHelper).subscribe(
      data => {
        if (data['Status']) {
          this.ffpBenefitsPendingList = data['Pending'];
          this.ffpBenefitsApprovedList = data['Approved'];
          this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
          this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }

  tierBenefitsChecked(checked, val, idx) {
    this.starTierLevelBenefitsReq=false;
    this.checkedBenefit = '';
    if (checked) {
      this.checkedBenefits.push(val);
    } else {
      this.checkedBenefits.splice(this.checkedBenefits.indexOf(val), 1);
    }
    for (let i = 0; i < this.checkedBenefits.length; i++) {
      if (i === this.checkedBenefits.length - 1) {
        this.checkedBenefit += (i + 1) + '. ' + this.checkedBenefits[i];
      } else {
        this.checkedBenefit += (i + 1) + '. ' + this.checkedBenefits[i] + ', ';
      }
    }
    this.checkedBenefits = this.checkedBenefits.sort();
  }
  setCheckedItems(param: FfpBenefits) {
    let benefits = param.starTierLevelBenefits.split(', ');
    for (let i = 0; i < this.tierBenefitsList.length; i++) {
      this.starTierLevelBenefitsChk[i] = false;
      for (let j = 0; j < benefits.length; j++) {
        let addedBenefit = benefits[j].replace(/[0-9]. /g,"");
        if (addedBenefit.trim() === this.tierBenefitsList[i].trim()) {
          this.starTierLevelBenefitsChk[i] = true;
          this.checkedBenefits.push(benefits[j].replace(/[0-9]. /g,""));
        }
      }
    }
    this.ffpBenefitsObj.starTierLevelBenefits='';
  }
  setAirlineAndFFPName(obj: string) {
    if (obj) {
      this.addEditAirlineName = this.airlineName.get(obj);
      this.addEditFfpName = this.ffpName.get(obj);
      this.isMemberAirline = this.memberAirline.get(obj);
      this.ffpBenefitsObj.airlineName = this.addEditAirlineName;
      this.ffpBenefitsObj.ffpProgramName = this.addEditFfpName;
      this.acrequired = false;
    } else {
      this.acrequired = true;
    }
    this.airlineCodeReq=false;
  }

  searchData(param) {
    let opt = [];
    if (param === 'pending') {
      if (this.searchKey) {
        let value = this.searchKey.toLowerCase();
        let airlineNameMap = new Map();
        let memberAirlineMap = new Map();
        let ffpNameMap = new Map();
        if (!this.searchAirlineTriggerPending) {
          opt = this.ffpBenefitsPendingList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().trim().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          memberAirlineMap = this.memberAirline;
          ffpNameMap = this.ffpName;
          opt = this.ffpBenefitsPendingList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().trim().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.airlineCode && (ffpNameMap.get(el.airlineCode.trim()) && ffpNameMap.get(el.airlineCode.trim()).includes(value))) ||
              (el.starTierLevelBenefits && el.starTierLevelBenefits.toLowerCase().trim().includes(value)) ||
              (el.starTierLevel && el.starTierLevel.toLowerCase().trim().includes(value))
            /* || memberAirlineMap.get(el.airlineCode.trim()).toLowerCase().includes(value) */) {
              return el;
            }
          });
        }
        this.p = 1;
        this.ffpBenefitsPendingList = opt;
      } else {
        let list = JSON.parse(this.ffpBenefitsPendingListPrev);
        this.ffpBenefitsPendingList = list;
      }
    } else if (param === 'approved') {
      if (this.searchKey) {
        let value = this.searchKey.toLowerCase();
        let airlineNameMap = new Map();
        let memberAirlineMap = new Map();
        let ffpNameMap = new Map();
        if (!this.searchAirlineTriggerApproved) {
          opt = this.ffpBenefitsApprovedList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().trim().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          memberAirlineMap = this.memberAirline;
          ffpNameMap = this.ffpName;
          opt = this.ffpBenefitsApprovedList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().trim().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.airlineCode && (ffpNameMap.get(el.airlineCode.trim()) && ffpNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.starTierLevelBenefits && el.starTierLevelBenefits.toLowerCase().trim().includes(value)) ||
              (el.starTierLevel && el.starTierLevel.toLowerCase().trim().includes(value))
            /* || memberAirlineMap.get(el.airlineCode.trim()).toLowerCase().includes(value) */) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.ffpBenefitsApprovedList = opt;
      } else {
        let list = JSON.parse(this.ffpBenefitsApprovedListPrev);
        this.ffpBenefitsApprovedList = list;
      }
    }
  }
  downloadExcel(param) {
    this.serObj.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'FFP Benefits.xls');
      }
    );
  }
  addDataIcon(ffpBenefitsForm: NgForm) {
    this.addEditAirlineName = '';
    this.addEditFfpName = '';
    this.isMemberAirline = '';
    this.ffpBenefitsObj = new FfpBenefits();
    this.ffpBenefitsObjPrev = new FfpBenefits();
    this.checkedBenefits = [];
    this.starTierLevelBenefitsReq = false;
    this.starTierLevelReq = false;
    this.airlineCodeReq = false;
    this.ffpBenefitsObj.starTierLevel = 'Gold';
    if (this.userInfo.userType === 'AUTHOR') {
      this.ffpBenefitsObj.airlineCode = this.userInfo.airlineId;
      this.setAirlineAndFFPName(this.ffpBenefitsObj.airlineCode);
    }
    for (let i = 0; i < this.tierBenefitsList.length; i++) {
      this.starTierLevelBenefitsChk[i] = false;
    }
    // this.starTierLevelBenefits = [];
    this.benefitsLevel = [1];
  }
  approveAction(param) {
    this.ffpBenefitsHelper.ffpBenefits = param;
    this.ffpBenefitsHelper.userDetails = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.ffpBenefitsHelper.ffpBenefits.userFlag = 'app';
      this.serObj.saveAction(this.ffpBenefitsHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.ffpBenefitsPendingList = data['Pending'];
            this.ffpBenefitsApprovedList = data['Approved'];
            this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
            this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.serObj.deleteAction(this.ffpBenefitsHelper).subscribe(
        data => {
          if (data['Status']) {
            this.ffpBenefitsPendingList = data['Pending'];
            this.ffpBenefitsApprovedList = data['Approved'];
            this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
            this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
            this.statusMsg = 'Data deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.ffpBenefitsHelper.ffpBenefits = this.ffpBenefitsObj;
    this.ffpBenefitsHelper.userDetails = this.userInfo;
    if (this.ffpBenefitsObjPrev && this.ffpBenefitsObjPrev.id > 0) {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObjPrev;
    } else {
      this.ffpBenefitsHelper.ffpBenefitsPrev = this.ffpBenefitsObj;
    }
    this.ffpBenefitsHelper.ffpBenefits.userFlag = 'rej';
    if (this.rejectionJustification) {
      this.serObj.saveAction(this.ffpBenefitsHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.ffpBenefitsPendingList = data['Pending'];
            this.ffpBenefitsApprovedList = data['Approved'];
            this.ffpBenefitsPendingListPrev = JSON.stringify(this.ffpBenefitsPendingList);
            this.ffpBenefitsApprovedListPrev = JSON.stringify(this.ffpBenefitsApprovedList);
            $('#rejectionId').modal('toggle');
            this.statusMsg = 'Mail Sent';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.ffpBenefitsObjPrev = obj;
    this.ffpBenefitsObj = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  cancelAction() {
    if (this.ffpBenefitsObj && this.ffpBenefitsObj.id > 0) {
      let obj = Object.assign({}, this.ffpBenefitsObjPrev);
      this.ffpBenefitsObj = obj;
      this.setCheckedItems(obj);
    } else {
      for (let i = 0; i < this.tierBenefitsList.length; i++) {
        this.starTierLevelBenefitsChk[i] = false;
      }
      this.ffpBenefitsObj = new FfpBenefits();
      if (this.userInfo.userType === 'AUTHOR') {
        this.ffpBenefitsObj.airlineCode = this.userInfo.airlineId;
      }
      this.ffpBenefitsObj.starTierLevel = 'Gold';
      this.addEditAirlineName = '';
      this.addEditFfpName = '';
      this.isMemberAirline = '';
    }
    this.setAirlineAndFFPName(this.ffpBenefitsObj.airlineCode);
    this.starTierLevelBenefitsReq = false;
    this.starTierLevelReq = false;
    this.airlineCodeReq = false;
  }
  closeAction() {
    this.ffpBenefitsObj = new FfpBenefits();
    this.starTierLevelBenefitsReq = false;
    this.starTierLevelReq = false;
    this.airlineCodeReq = false;
    if (this.ffpBenefitsPendingListPrev) {
      this.ffpBenefitsPendingList = JSON.parse(this.ffpBenefitsPendingListPrev);
    }
    if (this.ffpBenefitsApprovedListPrev) {
      this.ffpBenefitsApprovedList = JSON.parse(this.ffpBenefitsApprovedListPrev);
    }
  }
  /* addBenefitsRow() {
    this.benefitsLevel.push(1);
  } */
  
  triggerValidation(param) 
  
  {
    if(param==='airlineCode')
    {

      if(this.ffpBenefitsObj.airlineCode)
      {
        this.airlineCodeReq=false;
      }
      else{
        this.airlineCodeReq=true;
      }
    }
    /* if(param==='starTierLevelBenefits')
    {

      
      
        this.starTierLevelBenefitsReq=false;
      }
      else{
        this.starTierLevelBenefitsReq=true;
      } */
    }
     

  

  // removeBenefitsRow(i) {
  //   this.benefitsLevel.pop();
  // }
  resetList() {
    if (this.ffpBenefitsPendingListPrev) {
      this.ffpBenefitsPendingList = JSON.parse(this.ffpBenefitsPendingListPrev);
    }
    if (this.ffpBenefitsApprovedListPrev) {
      this.ffpBenefitsApprovedList = JSON.parse(this.ffpBenefitsApprovedListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.ffpBenefitsApprovedListPrev) {
        let list = JSON.parse(this.ffpBenefitsApprovedListPrev);
        this.ffpBenefitsApprovedList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.ffpBenefitsPendingListPrev) {
        let list = JSON.parse(this.ffpBenefitsPendingListPrev);
        this.ffpBenefitsPendingList = list;
        this.searchData(param);
      }
    }
  }
}

@Pipe({ name: 'ffpreplaceUnderscore' })
export class FFPReplaceSemiColonPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/, /g, "\n") : value;
  }
}