import { Component, OnInit } from '@angular/core';
import { CallCenterList } from 'src/app/model/callcenter-list';
import { CallCenterListService } from 'src/app/services/callcenter-list.service';
import { User } from 'src/app/model/user';
import { CallCenterListHelper } from 'src/app/model/callcenter-list-helper';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { ErrorModel } from 'src/app/model/error-model';
declare var $: any;

@Component({
  selector: 'app-callcenter-list',
  templateUrl: './callcenter-list.component.html',
})
export class CallCenterListComponent implements OnInit {

  callcenterlistObj = new CallCenterList();
  callcenterlistPrev = new CallCenterList();
  callCenterList: CallCenterList[] = [];
  userInfo = new User();
  callcenterlistHelper = new CallCenterListHelper();
  searchKey = '';
  callcenterListPrev: any;
  // callcenterListPrev: CallCenterList[] = [];
  statusMsg: string = '';
  airlineCodeValidation: boolean;
  airlineCodelength: boolean;
  airlineNameValidation: boolean;
  airlineNamelength: boolean;
  issueForCallValidation: boolean;
  issueForCalllength: boolean;
  orderToCallValidation: boolean;
  orderToCalllength: boolean;
  flag1:boolean=false;
  contactValidation: boolean;
  contactlength: boolean;
  phoneNumberValidation: boolean;
  phoneNumberlength: boolean;
  locationValidation: boolean;
  locationlength: boolean;
  openingHoursValidation: boolean;
  openingHourslength: boolean;
  emailContactalidation: boolean;
  emailContactlength: boolean;
  englishSupportAvailableValidation: boolean;
  englishSupportAvailablelength: boolean;
  p: number = 1;
  p1: number = 1;
  pendingcallcenterList: any;
  approvedcallcenterList: any;
  pendingcallcenterListPrev: string;
  approvedcallcenterListPrev: string;
  //callcenterlistPrev: : CarryonBag[] = [];
  airlineCodePattern: boolean;
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  equipmentAircraftlength: boolean;
  airline: AirlineSummary[] = [];
  rejectActionCheck: boolean;
  airlineKeysList = [];
  emailContactValidation: boolean;
  airlineName = new Map();
  addEditAirlineName: any;
  acrequired: boolean;
  errorModel = new ErrorModel();
  issueToCallList = ['IRROPS', 'Major IRROPs', 'RES/TKT ISSUES', 'Others'];
  contactList = ['7 and less days: Operations', '8 days and more: Schedule Department', 'AC OCC1', 'ARPT',
    'ATOCC', 'Airport Ticket Office', 'CSOI', 'CTO', 'Cairo Airport Sales Office Terminal 3', 'Call Center (Europe)',
    'Call Center (Global)', 'Call Centre', 'Contact Center', 'Customer Protection Center (CPC)', 'Ekram Abdallah',
    'Guest Care Unit (GCU VIE)', 'Hade Singh', 'Head of Premium Services', 'Help Desk', 'IRROPS support',
    'Integrated Operations Control Centre (IOCC)', 'LAX Passenger Service', 'Nicolai Wrigg / MEMCO', 'OCC',
    'OCC (Marketing Coordinator)', 'OCC (Mgr. Commercial Operations)', 'OCC - Operations Control',
    'OCC Duty Manager', 'Operations Control Centre (OCC)', 'Passenger Care Center (PCC ZRH)',
    'Passenger Service ZAG', 'Remote Competence Center BKK', 'Remote Competence Center OPO',
    'Res&Tickets  technical support', 'Res&Tickets - Call Centre', 'Reservations Support',
    'Star Alliance Airlines Official ServiceDesk', 'Station Help Desk', 'Support Specialists',
    'Thai Contact Centre', 'Ticket Desk', 'Ticketing help desk - Star Alliance line', 'call center',
    'e-Ticket Help Desk', 'sales call center', 'Passenger Recovery Control (PRC)', 'Others'];
  issueForCall: string = '';
  
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  userEmailRegexVal: boolean = false;
  constructor(private callcenterlistService: CallCenterListService, private router: Router) { }

  ngOnInit() {
    /*$('[data-toggle="tooltip"]').tooltip();*/
    this.onLoad();
    this.profileInfoClose();
  }
  onLoad() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.callcenterlistService.loadData(this.userInfo).subscribe(
      data => {
        this.pendingcallcenterList = data['Pending'];
        this.approvedcallcenterList = data['Approved'];
        this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterList);
        this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
        this.paginationTrigger('pending');
        this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        let airKey = [];
        for (let index = 0; index < this.airline.length; index++) {
          airKey.push(this.airline[index].airlineCode);
          this.airlineName.set(this.airline[index].airlineCode, this.airline[index].airlineName);
        }
        this.airlineKeysList = airKey.sort();
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list) {
    let obj = Object.assign({}, param);
    this.callcenterlistPrev = obj;
    this.callcenterlistObj = param;
    if (param.englishSupportAvailable === 'Y') {
      this.callcenterlistObj.englishSupportAvailable = 'Y';
    } else if (param.englishSupportAvailable === 'N') {
      this.callcenterlistObj.englishSupportAvailable = 'N';
    }
    this.setAirlineName(this.callcenterlistObj.airlineCode);
    this.triggerOthers(param);
    this.flag1=true;
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.callcenterlistPrev = obj;
    this.callcenterlistObj = param;
  }

  saveAction(CallCenterListForm: NgForm, status?: any) {
    if (this.callcenterlistObj.issueForCall === 'Others') {
      this.callcenterlistObj.issueForCall = this.issueForCall;
    }
 
    if ((this.callcenterlistObj.airlineCode && this.callcenterlistObj.airlineName
      && this.callcenterlistObj.airlineName.length <= 100 && this.callcenterlistObj.issueForCall
      && this.callcenterlistObj.issueForCall.length <= 100 && this.callcenterlistObj.orderToCall
      && this.callcenterlistObj.orderToCall.length <= 1 && this.callcenterlistObj.contact &&
      this.callcenterlistObj.contact.length <= 200 ) &&
      (((this.callcenterlistObj.phoneNumber && this.callcenterlistObj.phoneNumber.length <= 100) ||
        (this.callcenterlistObj.location && this.callcenterlistObj.location.length <= 100) ||
        (this.callcenterlistObj.englishSupportAvailable && this.callcenterlistObj.englishSupportAvailable.length <= 100) ||
        (this.callcenterlistObj.openingHours && this.callcenterlistObj.openingHours.length <= 200) ||
        (this.callcenterlistObj.emailContact && this.callcenterlistObj.emailContact.length <= 100)) ||
        !((this.callcenterlistObj.phoneNumber) ||
          (this.callcenterlistObj.location) ||
          (this.callcenterlistObj.englishSupportAvailable) ||
          (this.callcenterlistObj.openingHours) ||
          (this.callcenterlistObj.emailContact)))) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.callcenterlistObj && this.callcenterlistObj.id > 0 && this.callcenterlistObj.userFlag === 'A') {
          this.callcenterlistObj.parentId = this.callcenterlistObj.id;
          this.callcenterlistObj.id = 0;
        }
      }
      if(this.flag1){
        this.callcenterlistObj.userFlag = 'edit';
        this.flag1=false;
        }
        else
        {
          this.callcenterlistObj.userFlag = 'add';
          this.flag1=false;
        }
      
      this.callcenterlistHelper.callCenterList = this.callcenterlistObj;
      if (this.callcenterlistPrev && this.callcenterlistPrev.id > 0) {
        this.callcenterlistHelper.callCenterListPrev = this.callcenterlistPrev;
      } else {
        this.callcenterlistHelper.callCenterListPrev = this.callcenterlistObj;
      }
      this.callcenterlistHelper.userInfo = this.userInfo;

      this.callcenterlistService.saveAction(this.callcenterlistHelper, 'erj').subscribe(
        data => {
          if (data['Status']) {
            this.pendingcallcenterList = data['Pending'];
            this.approvedcallcenterList = data['Approved'];
            this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterList);
            this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if (!this.callcenterlistObj.airlineCode) {
        this.airlineCodeValidation = true;
      } else {
        this.airlineCodeValidation = false;
      }
      if (!this.callcenterlistObj.airlineName) {
        this.airlineNameValidation = true;
      } else {
        this.airlineNameValidation = false;
      }
      if (!this.callcenterlistObj.issueForCall) {
        this.issueForCallValidation = true;
      } else {
        this.issueForCallValidation = false;
      }
      if (!this.callcenterlistObj.orderToCall) {
        this.orderToCallValidation = true;
      } else {
        this.orderToCallValidation = false;
      }
      if (!this.callcenterlistObj.contact) {
        this.contactValidation = true;
      } else {
        this.contactValidation = false;
      }
      if (!this.callcenterlistObj.phoneNumber) {
        this.phoneNumberValidation = true;
      } else {
        this.phoneNumberValidation = false;
      }
      if (!this.callcenterlistObj.englishSupportAvailable) {
        this.englishSupportAvailableValidation = true;
      } else {
        this.englishSupportAvailableValidation = false;
      }
      if (!this.callcenterlistObj.location) {
        this.locationValidation = true;
      } else {
        this.locationValidation = false;
      }
      if (!this.callcenterlistObj.openingHours) {
        this.openingHoursValidation = true;
      } else {
        this.openingHoursValidation = false;
      }
      if (!this.callcenterlistObj.emailContact) {
        this.userEmailRegexVal = false;
        this.emailContactValidation = true;
      } else {
        this.emailContactValidation = false;
      }

    }
  }

  setAirlineName(obj: string) {
    if (obj) {
      this.addEditAirlineName = this.airlineName.get(obj);
      this.callcenterlistObj.airlineName = this.addEditAirlineName;
      this.acrequired = false;
    } else {
      this.acrequired = true;
    }
  }

  deleteAction() {
    this.callcenterlistHelper.callCenterList = this.callcenterlistObj;
    this.callcenterlistHelper.userInfo = this.userInfo;
    if (this.callcenterlistPrev && this.callcenterlistPrev.id > 0) {
      this.callcenterlistHelper.callCenterListPrev = this.callcenterlistPrev;
    } else {
      this.callcenterlistHelper.callCenterListPrev = this.callcenterlistObj;
    }
    this.callcenterlistService.deleteAction(this.callcenterlistHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingcallcenterList = data['Pending'];
          this.approvedcallcenterList = data['Approved'];
          this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterList);
          this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');

        }
      }
    );
  }
  addDataIcon(callcenterForm: NgForm) {
    this.callcenterlistObj = new CallCenterList();
    this.callcenterlistPrev = new CallCenterList();
    this.addEditAirlineName = '';
    callcenterForm.control.markAsUntouched;
    callcenterForm.control.markAsPristine;
    this.callcenterlistObj.orderToCall = '';
    this.callcenterlistObj.issueForCall = '';
    this.callcenterlistObj.contact = '';
    this.callcenterlistObj.englishSupportAvailable = '';
    if (this.userInfo.userType === 'AUTHOR') {
      this.callcenterlistObj.airlineCode = this.userInfo.airlineId;
      this.setAirlineName(this.callcenterlistObj.airlineCode);
    }
  }
  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'pending') {
      let airlineNameMap = new Map();
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingcallcenterList.filter(function (el, idx, array) {

            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          opt = this.pendingcallcenterList.filter(function (el, idx, array) {

            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.issueForCall && el.issueForCall.toLowerCase().includes(value)) ||
              (el.orderToCall && el.orderToCall.toLowerCase().includes(value)) ||
              (el.contact && el.contact.toLowerCase().includes(value)) ||
              (el.phoneNumber && el.phoneNumber.toLowerCase().includes(value)) ||
              (el.englishSupportAvailable && el.englishSupportAvailable.toLowerCase().includes(value)) ||
              (el.location && el.location.toLowerCase().includes(value)) ||
              (el.openingHours && el.openingHours.toLowerCase().includes(value)) ||
              (el.emailContact && el.emailContact.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p = 1;
        this.pendingcallcenterList = opt;
      } else {
        let list = JSON.parse(this.pendingcallcenterListPrev);
        this.pendingcallcenterList = list;
      }
    } else if (param === 'approved') {
      let airlineNameMap = new Map();
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedcallcenterList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          airlineNameMap = this.airlineName;
          opt = this.approvedcallcenterList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineCode && (airlineNameMap.get(el.airlineCode.trim()) && airlineNameMap.get(el.airlineCode.trim()).toLowerCase().includes(value))) ||
              (el.issueForCall && el.issueForCall.toLowerCase().includes(value)) ||
              (el.orderToCall && el.orderToCall.toLowerCase().includes(value)) ||
              (el.contact && el.contact.toLowerCase().includes(value)) ||
              (el.phoneNumber && el.phoneNumber.toLowerCase().includes(value)) ||
              (el.englishSupportAvailable && el.englishSupportAvailable.toLowerCase().includes(value)) ||
              (el.location && el.location.toLowerCase().includes(value)) ||
              (el.openingHours && el.openingHours.toLowerCase().includes(value)) ||
              (el.emailContact && el.emailContact.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedcallcenterList = opt;
      } else {
        let list = JSON.parse(this.approvedcallcenterListPrev);
        this.approvedcallcenterList = list;
      }
    }
  }

  downloadExcel(param) {
    this.callcenterlistService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        //let blob = new Blob([data], { type: 'application/octet-stream' });
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'CallCenterList.xls');
      }

    );
  }
  validationClear(param) {
    if (param === 'airlineCode') {
      if (this.callcenterlistObj.airlineCode) {
        this.airlineCodeValidation = false;
      } else {
        this.airlineCodeValidation = true;
      }
      if (this.callcenterlistObj.airlineCode.length <= 3) {
        this.airlineCodelength = false;
      } else {
        this.airlineCodelength = true;
      }
    }
    if (param === 'airlineName') {
      if (this.callcenterlistObj.airlineName) {
        this.airlineNameValidation = false;
      } else {
        this.airlineNameValidation = true;
      }
      if (this.callcenterlistObj.airlineName.length <= 100) {
        this.airlineNamelength = false;
      } else {
        this.airlineNamelength = true;
      }
    }
    if (param === 'issueForCall') {
      if (this.callcenterlistObj.issueForCall) {
        this.issueForCallValidation = false;
      } else {
        this.issueForCallValidation = true;
      }
      if (this.callcenterlistObj.issueForCall.length <= 100) {
        this.issueForCalllength = false;
      } else {
        this.issueForCalllength = true;
      }
    }
    if (param === 'orderToCall') {
      if (this.callcenterlistObj.orderToCall) {
        this.orderToCallValidation = false;
      } else {
        this.orderToCallValidation = true;
      }
      if (this.callcenterlistObj.orderToCall.length <= 1) {
        this.orderToCalllength = false;
      } else {
        this.orderToCalllength = true;
      }
    }
    if (param === 'contact') {
      if (this.callcenterlistObj.contact) {
        this.contactValidation = false;
      } else {
        this.contactValidation = true;
      }
      if (this.callcenterlistObj.contact.length <= 200) {
        this.contactlength = false;
      } else {
        this.contactlength = true;
      }
    }
    if (param === 'phoneNumber') {
      if (this.callcenterlistObj.phoneNumber) {
        this.phoneNumberValidation = false;
      } else {
        this.phoneNumberValidation = true;
      }
      if (this.callcenterlistObj.phoneNumber.length <= 100) {
        this.phoneNumberlength = false;
      } else {
        this.phoneNumberlength = true;
      }
    }
    if (param === 'englishSupportAvailable') {
      if (this.callcenterlistObj.englishSupportAvailable) {
        this.englishSupportAvailableValidation = false;
      } else {
        this.englishSupportAvailableValidation = true;
      }
      if (this.callcenterlistObj.englishSupportAvailable.length <= 100) {
        this.englishSupportAvailablelength = false;
      } else {
        this.englishSupportAvailablelength = true;
      }
    }
    if (param === 'location') {
      if (this.callcenterlistObj.location) {
        this.locationValidation = false;
      } else {
        this.locationValidation = true;
      }
      if (this.callcenterlistObj.location.length <= 100) {
        this.locationlength = false;
      } else {
        this.locationlength = true;
      }
    }
    if (param === 'openingHours') {
      if (this.callcenterlistObj.openingHours) {
        this.openingHoursValidation = false;
      } else {
        this.openingHoursValidation = true;
      }
      if (this.callcenterlistObj.openingHours.length <= 200) {
        this.openingHourslength = false;
      } else {
        this.openingHourslength = true;
      }
    }
    if (param === 'emailContact') {
      if (this.callcenterlistObj.emailContact) {
        this.emailContactValidation = false;
      } else {
        this.emailContactValidation = true;
        this.userEmailRegexVal = false;
      }
      if (this.callcenterlistObj.emailContact.length <= 100) {
        this.emailContactlength = false;
      } else {
        this.emailContactlength = true;
        this.userEmailRegexVal = false;
      }
    }
  }

  cancelAction() {
    if (this.callcenterlistObj && this.callcenterlistObj.id > 0) {
      let obj = Object.assign({}, this.callcenterlistPrev);
      this.callcenterlistObj = obj;
    } else {
      this.callcenterlistObj = new CallCenterList()
      this.callcenterlistObj.airlineCode = '';
      this.callcenterlistObj.airlineName = '';
      this.callcenterlistObj.issueForCall = '';
      this.issueForCall='';
      this.callcenterlistObj.orderToCall = '';
      this.callcenterlistObj.contact = '';
      this.callcenterlistObj.phoneNumber = '';
      this.callcenterlistObj.englishSupportAvailable = '';
      this.callcenterlistObj.location = '';
      this.callcenterlistObj.openingHours = '';
      this.callcenterlistObj.emailContact = '';
      this.callcenterlistObj.id = 0;
      this.addEditAirlineName = '';
      if (this.userInfo.userType === 'AUTHOR') {
        this.callcenterlistObj.airlineCode = this.userInfo.airlineId;
      }
    }
    this.setAirlineName(this.callcenterlistObj.airlineCode);
    this.airlineCodeValidation = false;
    this.airlineCodelength = false;
    this.airlineNameValidation = false;
    this.airlineNamelength = false;
    this.issueForCallValidation = false;
    this.issueForCalllength = false;
    this.orderToCallValidation = false;
    this.orderToCalllength = false;
    this.contactValidation = false;
    this.contactlength = false;
    this.phoneNumberValidation = false;
    this.phoneNumberlength = false;
    this.englishSupportAvailableValidation = false;
    this.englishSupportAvailablelength = false;
    this.locationValidation = false;
    this.locationlength = false;
    this.openingHoursValidation = false;
    this.openingHourslength = false;
    this.emailContactValidation = false;
    this.emailContactlength = false;
    this.userEmailRegexVal = false;
  }
  closeAction() {
    this.issueForCall='';
    this.callcenterlistObj.id = 0;
    this.airlineCodeValidation = false;
    this.airlineCodePattern = false;
    this.airlineCodelength = false;
    this.airlineNameValidation = false;
    this.airlineNamelength = false;
    this.issueForCallValidation = false;
    this.issueForCalllength = false;
    this.orderToCallValidation = false;
    this.orderToCalllength = false;
    this.contactValidation = false;
    this.contactlength = false;
    this.phoneNumberValidation = false;
    this.phoneNumberlength = false;
    this.englishSupportAvailableValidation = false;
    this.englishSupportAvailablelength = false;
    this.locationValidation = false;
    this.locationlength = false;
    this.openingHoursValidation = false;
    this.openingHourslength = false;
    this.emailContactValidation = false;
    this.emailContactlength = false;
    this.userEmailRegexVal = false;
    this.callcenterlistObj = this.callcenterlistPrev;
    if (this.pendingcallcenterListPrev) {
      this.pendingcallcenterList = JSON.parse(this.pendingcallcenterListPrev);
    }
    if (this.approvedcallcenterListPrev) {
      this.approvedcallcenterList = JSON.parse(this.approvedcallcenterListPrev);
    }
  }
  approveAction(param) {
    this.callcenterlistObj = param;
    this.callcenterlistHelper.callCenterList = this.callcenterlistObj;
    this.callcenterlistHelper.userInfo = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.callcenterlistHelper.callCenterList.userFlag = 'app';
      this.callcenterlistService.saveAction(this.callcenterlistHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingcallcenterList = data['Pending'];
            this.approvedcallcenterList = data['Approved'];
            this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterList);
            this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    } else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.callcenterlistService.deleteAction(this.callcenterlistHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingcallcenterList = data['Pending'];
            this.approvedcallcenterList = data['Approved'];
            this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterListPrev);
            this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
  }
  rejectAction() {
    this.callcenterlistHelper.callCenterList = this.callcenterlistObj;
    this.callcenterlistHelper.userInfo = this.userInfo;
    if (this.callcenterlistPrev && this.callcenterlistPrev.id > 0) {
      this.callcenterlistHelper.callCenterListPrev = this.callcenterlistPrev;
    } else {
      this.callcenterlistHelper.callCenterListPrev = this.callcenterlistObj;
    }
    this.callcenterlistHelper.callCenterList.userFlag = 'rej';
    if (this.rejectionJustification) {
      this.callcenterlistService.saveAction(this.callcenterlistHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingcallcenterList = data['Pending'];
            this.approvedcallcenterList = data['Approved'];
            this.pendingcallcenterListPrev = JSON.stringify(this.pendingcallcenterList);
            this.approvedcallcenterListPrev = JSON.stringify(this.approvedcallcenterList);
            this.statusMsg = 'Mail Sent.';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    } else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.callcenterlistPrev = obj;
    this.callcenterlistObj = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingcallcenterListPrev) {
      this.pendingcallcenterList = JSON.parse(this.pendingcallcenterListPrev);
    }
    if (this.approvedcallcenterListPrev) {
      this.approvedcallcenterList = JSON.parse(this.approvedcallcenterListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedcallcenterListPrev) {
        let list = JSON.parse(this.approvedcallcenterListPrev);
        this.approvedcallcenterList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingcallcenterListPrev) {
        let list = JSON.parse(this.pendingcallcenterListPrev);
        this.pendingcallcenterList = list;
        this.searchData(param);
      }
    }
  }
  triggerOthers(param: CallCenterList) {
    if (param.issueForCall !== 'IRROPS' && param.issueForCall !== 'Major IRROPs' && param.issueForCall !== 'RES/TKT ISSUES') {
      this.issueForCall = param.issueForCall;
      this.callcenterlistObj.issueForCall = 'Others';
    } else {
      this.callcenterlistObj.issueForCall = param.issueForCall;
    }
   /*  if (param.contact !== '7 and less days: Operations' && param.contact !== '8 days and more: Schedule Department'
      && param.contact !== 'AC OCC1' && param.contact !== 'ARPT' && param.contact !== 'ATOCC' &&
      param.contact !== 'Airport Ticket Office' && param.contact !== 'CSOI' && param.contact !== 'CTO' &&
      param.contact !== 'Cairo Airport Sales Office Terminal 3' && param.contact !== 'Call Center (Europe)'
      && param.contact !== 'Call Center (Global)' && param.contact !== 'Call Centre' && param.contact !== 'Contact Center'
      && param.contact !== 'Customer Protection Center (CPC)' && param.contact !== 'Ekram Abdallah' &&
      param.contact !== 'Guest Care Unit (GCU VIE)' && param.contact !== 'Hade Singh' && param.contact !== 'Head of Premium Services'
      && param.contact !== 'Help Desk' && param.contact !== 'IRROPS support' && param.contact !== 'Integrated Operations Control Centre (IOCC)'
      && param.contact !== 'LAX Passenger Service' && param.contact !== 'Nicolai Wrigg / MEMCO' && param.contact !== 'OCC'
      && param.contact !== 'OCC (Marketing Coordinator)' && param.contact !== 'OCC (Mgr. Commercial Operations)' &&
      param.contact !== 'OCC - Operations Control' && param.contact !== 'OCC Duty Manager' && param.contact !== 'Operations Control Centre (OCC)'
      && param.contact !== 'Passenger Care Center (PCC ZRH)' && param.contact !== 'Passenger Service ZAG' &&
      param.contact !== 'Remote Competence Center BKK' && param.contact !== 'Remote Competence Center OPO' &&
      param.contact !== 'Res&Tickets  technical support' && param.contact !== 'Res&Tickets - Call Centre' &&
      param.contact !== 'Reservations Support' && param.contact !== 'Star Alliance Airlines Official ServiceDesk' &&
      param.contact !== 'Station Help Desk' && param.contact !== 'Support Specialists' && param.contact !== 'Thai Contact Centre'
      && param.contact !== 'Ticket Desk' && param.contact !== 'Ticketing help desk - Star Alliance line' &&
      param.contact !== 'call center' && param.contact !== 'e-Ticket Help Desk' && param.contact !== 'sales call center'
      && param.contact !== 'Passenger Recovery Control (PRC)') {
      this.contactAfterCall = param.contact;
      this.callcenterlistObj.contact = 'Others';
    } */
  }
  emailValidation() {
    if (this.callcenterlistObj.emailContact && this.emailRegex.test(this.callcenterlistObj.emailContact)) {
      this.userEmailRegexVal = false;
    } else {
      this.userEmailRegexVal = true;
    }
  }
}
