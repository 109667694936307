import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { NgForm } from '@angular/forms';
import { AirlineSummary } from 'src/app/model/airline-summary';
import { AirlineSummaryHelper } from 'src/app/model/airline-summary-helper';
import { AirlineSummaryService } from 'src/app/services/airline-summary.service'
import { Router } from '@angular/router';
import { error } from 'util';
import { ErrorModel } from 'src/app/model/error-model';
declare var $: any;
@Component({
  selector: 'app-airline-summary',
  templateUrl: './airline-summary.component.html'
})
export class AirlineSummaryComponent implements OnInit {

  airlineSummaryObj = new AirlineSummary();
  airlineSummaryList: AirlineSummary[] = [];
  userInfo = new User();
  p: number = 1;
  p1: number = 1;
  airlineSummaryHelper = new AirlineSummaryHelper();
  searchKey = '';
  airlineSummaryListPrev: AirlineSummary[] = [];
  pendingAirlineSummaryList: AirlineSummary[] = [];
  approvedAirlineSummaryList: AirlineSummary[] = [];
  pendingAirlineSummaryListPrev: any;
  approvedAirlineSummaryListPrev: any;
  statusMsg: string = '';
  pendingPagination: boolean = true;
  approvedPagination: boolean = false;
  rejectionJustification: string = '';
  equipmentAircraftlength: boolean;
  airlineWebsiteValidation: boolean;
  rejectActionCheck: boolean;
  airlineNameValidation: boolean;
  airlineCodeValidation: boolean;
  airlineNamelength: boolean;
  airlineNamePattern: boolean;
  flag1:boolean=false;
  airlineLogoValidation: boolean;
  airlineLogolength: boolean;
  airlineLogoPattern: boolean;
  isMemberAirlineValidation: boolean;
  isMemberAirlinelength: boolean;
  isMemberAirlinePattern: boolean;
  isCpmAirlineValidation: boolean;
  isCpmAirlinelength: boolean;
  isCpmAirlinePattern: boolean;
  airlineFormalNamelength: boolean;
  airlineFormalNameValidation: boolean;
  airlineWebsitelength: boolean;
  airlineAnniversarylength: boolean;
  joiningStarAlliancelength: boolean;
  airlineCodelength: boolean;
  public currentDate: Date;
  airlineSummaryObjPrev = new AirlineSummary();
  fileData: File = null;
  regEx = /^[a-zA-Z0-9]+$/g;
  ticketingRegex = /^[0-9]+$/;
  ticketingRegexVal: boolean = false;
  ticketingPlatelength: boolean;
  fileName: any;
  errorModel = new ErrorModel();
  airlineLogoFileValidation: boolean;
  airlineLogoFileSizeValidation: boolean;
  searchAirlineTriggerApproved: boolean = false;
  searchAirlineTriggerPending: boolean = false;
  constructor(private airlineSummaryService: AirlineSummaryService, private router: Router) { }

  airlineKeysList = [];
  airline: AirlineSummary[] = [];

  ngOnInit() {
    this.onLoad();
    //this.currentDate = new Date();
  }
  onLoad() {

    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.airlineSummaryService.loadData(this.userInfo).subscribe(
      data => {
        this.pendingAirlineSummaryList = data['Pending'];
        this.approvedAirlineSummaryList = data['Approved'];
        this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
        this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
        this.paginationTrigger('pending');
        //  this.airline = JSON.parse(sessionStorage.getItem('Airline'));
        this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        /*       let airKeys = [];
              for (let index = 0; index < this.airline.length; index++) {
                airKeys.push(this.airline[index].airlineCode);
              }
              this.airlineKeysList = airKeys.sort(); */
      }
    );
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display = 'block';
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display = 'none';
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  editMethod(param, list, usrType?: any) {
    let obj = Object.assign({}, param);
    this.airlineSummaryObjPrev = obj;
    this.airlineSummaryObj = param;
    this.setMemberAndCPM(param);
    this.flag1=true;
   
  }
  setMemberAndCPM(param: AirlineSummary) {
    if(param.isMemberAirline==='Y'||param.isMemberAirline==='Yes'){
      this.airlineSummaryObj.isMemberAirline = 'Y';
    } else if(param.isMemberAirline==='N'||param.isMemberAirline==='No'){
      this.airlineSummaryObj.isMemberAirline = 'N';
    }
    if(param.isCpmAirline==='Y'||param.isCpmAirline==='Yes'){
      this.airlineSummaryObj.isCpmAirline = 'Y';
    } else if(param.isCpmAirline==='N'||param.isCpmAirline==='No'){
      this.airlineSummaryObj.isCpmAirline = 'N';
    }
  }
  deleteConfirmation(param) {
    let obj = Object.assign({}, param);
    this.airlineSummaryObjPrev = obj;
    this.airlineSummaryObj = param;
  }
  fileUpload(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (this.fileData.type != 'image/jpeg' && this.fileData.type != 'image/jpg' && this.fileData.type != 'image/png') {
      this.airlineLogoFileValidation = true;
    } else {
      this.airlineLogoFileValidation = false;
    }
    if (this.fileData.size === 1000000) {
      this.airlineLogoFileSizeValidation = true;
    } else {
      this.airlineLogoFileSizeValidation = false;
    }
  }

  saveAction(airlinesummaryForm: NgForm, status?: any) {
    if ((this.airlineSummaryObj.airlineCode && this.airlineSummaryObj.airlineName && this.airlineSummaryObj.airlineLogo &&
      this.airlineSummaryObj.airlineName.length <= 100 && this.airlineSummaryObj.airlineCode.length == 2 &&
      this.airlineSummaryObj.isMemberAirline && this.airlineSummaryObj.isMemberAirline.length <= 3 &&
      this.airlineSummaryObj.isCpmAirline && this.airlineSummaryObj.isCpmAirline.length <= 3) &&
      (((this.airlineSummaryObj.airlineWebsite && this.airlineSummaryObj.airlineWebsite.length <= 100)
        || !(this.airlineSummaryObj.airlineWebsite)))
    ) {
      if (this.userInfo && this.userInfo.userType === 'AUTHOR') {
        if (this.airlineSummaryObj && this.airlineSummaryObj.id > 0 && this.airlineSummaryObj.userFlag === 'A') {
          this.airlineSummaryObj.parentId = this.airlineSummaryObj.id;
          this.airlineSummaryObj.id = 0;
        }
      }
      
      this.airlineSummaryHelper.airlineSummary = this.airlineSummaryObj;
      if (this.airlineSummaryObjPrev && this.airlineSummaryObjPrev.id > 0) {
        this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObjPrev;
      } else {
        this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObj;
      }
      this.airlineSummaryHelper.userInfo = this.userInfo;
    
      if(this.flag1){
        this.airlineSummaryHelper.airlineSummary.userFlag  = 'edit';
        this.flag1=false;
        }
        else
        {
          this.airlineSummaryHelper.airlineSummary.userFlag  = 'add';
          this.flag1=false;
        }
      
        
      this.airlineSummaryService.saveAction(this.airlineSummaryHelper, 'erj', this.fileData).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirlineSummaryList = data['Pending'];
            this.approvedAirlineSummaryList = data['Approved'];
            this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
            this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
            $('#addedit').modal('toggle');
            this.statusMsg = 'Data Saved Successfully.';
            $('#statusId').modal('toggle');
          } else if (data['Duplicate']) {
            this.errorModel = data['Model'];
            $('#addedit').modal('toggle');
            this.statusMsg = this.errorModel.message;
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if (!this.airlineSummaryObj.airlineCode) {
        this.airlineCodeValidation = true;
      }
      else {
        this.airlineCodeValidation = false;
      }
      if (!this.airlineSummaryObj.airlineName) {
        this.airlineNameValidation = true;
      } else {
        this.airlineNameValidation = false;
      }
      if (!this.airlineSummaryObj.isMemberAirline) {
        this.isMemberAirlineValidation = true;
      } else {
        this.isMemberAirlineValidation = false;
      }
      if (!this.airlineSummaryObj.isCpmAirline) {
        this.isCpmAirlineValidation = true;
      } else {
        this.isCpmAirlineValidation = false;
      }
      if (!this.airlineSummaryObj.airlineLogo ) {
        this.airlineLogoValidation= true;
      } else {
        this.airlineLogoValidation= false;
      }

    }
  }

  deleteAction() {
    this.airlineSummaryHelper.airlineSummary = this.airlineSummaryObj;
    this.airlineSummaryHelper.userInfo = this.userInfo;
    if (this.airlineSummaryObjPrev && this.airlineSummaryObjPrev.id > 0) {
      this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObjPrev;
    } else {
      this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObj;
    }
    this.airlineSummaryService.deleteAction(this.airlineSummaryHelper).subscribe(
      data => {
        if (data['Status']) {
          this.pendingAirlineSummaryList = data['Pending'];
          this.approvedAirlineSummaryList = data['Approved'];
          this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
          this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
          $('#deleteId').modal('toggle');
          this.statusMsg = 'Data Deleted Successfully.';
          $('#statusId').modal('toggle');
        }
      }
    );
  }
  addDataIcon(airlinesummaryForm: NgForm) {
    this.airlineSummaryObj = new AirlineSummary();
    this.airlineSummaryObjPrev = new AirlineSummary();
  
    this.airlineLogoValidation = false;
    this.airlineNameValidation = false;
    this.isCpmAirlineValidation = false;
    this.isMemberAirlineValidation = false;
    this.fileName = '';
    this.fileData = null;
    this.airlineLogoFileValidation = false;
    this.airlineLogoFileSizeValidation = false;
    airlinesummaryForm.control.markAsUntouched;
    airlinesummaryForm.control.markAsPristine;
    this.airlineSummaryObj.isCpmAirline = 'Y';
    this.airlineSummaryObj.isMemberAirline = 'Y';
    this.airlineCodeValidation =false;
    if (this.userInfo.userType === 'AUTHOR') {
      this.airlineSummaryObj.airlineCode = this.userInfo.airlineId;
    }
  }
  searchData(param) {
    let value = this.searchKey.toLowerCase();
    let opt = [];
    if (param === 'approved') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerApproved) {
          opt = this.approvedAirlineSummaryList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.approvedAirlineSummaryList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineName && el.airlineName.toLowerCase().includes(value)) ||
              (el.airlineFormalName != null && el.airlineFormalName && el.airlineFormalName.toLowerCase().includes(value)) ||
              (el.isMemberAirline != null && el.isMemberAirline && el.isMemberAirline.toLowerCase().includes(value)) ||
              (el.isCpmAirline != null && el.isCpmAirline && el.isCpmAirline.toLowerCase().includes(value)) ||
              (el.airlineWebsite != null && el.airlineWebsite && el.airlineWebsite.toLowerCase().includes(value)) ||
              (el.airlineAnniversary != null && el.airlineAnniversary && el.airlineAnniversary.toLowerCase().includes(value)) ||
              (el.joiningStarAlliance != null && el.joiningStarAlliance && el.joiningStarAlliance.toLowerCase().includes(value))||
              (el.ticketingPlate != null && el.ticketingPlate && el.ticketingPlate.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.p1 = 1;
        this.approvedAirlineSummaryList = opt;
      } else {
        let list = JSON.parse(this.approvedAirlineSummaryListPrev);
        this.approvedAirlineSummaryList = list;
      }
    }
    else if (param === 'pending') {
      if (this.searchKey) {
        if (!this.searchAirlineTriggerPending) {
          opt = this.pendingAirlineSummaryList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value))) {
              return el;
            }
          });
        } else {
          opt = this.pendingAirlineSummaryList.filter(function (el, idx, array) {
            if ((el.airlineCode && el.airlineCode.toLowerCase().includes(value)) ||
              (el.airlineName && el.airlineName.toLowerCase().includes(value)) ||
              (el.airlineFormalName != null && el.airlineFormalName && el.airlineFormalName.toLowerCase().includes(value)) ||
              (el.isMemberAirline != null && el.isMemberAirline && el.isMemberAirline.toLowerCase().includes(value)) ||
              (el.isCpmAirline != null && el.isCpmAirline && el.isCpmAirline.toLowerCase().includes(value)) ||
              (el.airlineWebsite != null && el.airlineWebsite && el.airlineWebsite.toLowerCase().includes(value)) ||
              (el.airlineAnniversary != null && el.airlineAnniversary && el.airlineAnniversary.toLowerCase().includes(value)) ||
              (el.joiningStarAlliance != null && el.joiningStarAlliance && el.joiningStarAlliance.toLowerCase().includes(value))||
              (el.ticketingPlate != null && el.ticketingPlate && el.ticketingPlate.toLowerCase().includes(value))) {
              return el;
            }
          });
        }
        this.pendingAirlineSummaryList = opt;
        this.p = 1;
      } else {
        let list = JSON.parse(this.pendingAirlineSummaryListPrev);
        this.pendingAirlineSummaryList = list;
      }
    }
  }
  downloadExcel(param) {
    this.airlineSummaryService.downloadExcel(param, this.userInfo.userType, this.userInfo.airlineId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'AirlineSummary.xls');
      }
    );
  }
  validationClear(param) {
    if (param === 'isCpmAirline') {
      if (this.airlineSummaryObj.isCpmAirline) {
        this.isCpmAirlineValidation = false;
      } else {
        this.isCpmAirlineValidation = true;
      }
      if (this.airlineSummaryObj.isCpmAirline.length <= 1) {
        this.isCpmAirlinelength = false;
      } else {
        this.isCpmAirlinelength = true;
      }
    }
    if (param === 'airlineLogo') {
      if (this.airlineSummaryObj.airlineLogo) {
        this.airlineLogoValidation = false;
      } else {
        this.airlineLogoValidation = true;
      }
    }
    if (param === 'isMemberAirline') {
      if (this.airlineSummaryObj.isMemberAirline) {
        this.isMemberAirlineValidation = false;
      } else {
        this.isMemberAirlineValidation = true;
      }
      if (this.airlineSummaryObj.isMemberAirline.length <= 1) {
        this.isMemberAirlinelength = false;
      } else {
        this.isMemberAirlinelength = true;
      }
    }
    if (param === 'airlineName') {
      let regBoolean = this.regEx.test(this.airlineSummaryObj.airlineName);
      if (this.airlineSummaryObj.airlineName) {
        this.airlineNameValidation = false;
      } else {
        this.airlineNameValidation = true;
      }
      if (this.airlineSummaryObj.airlineName.length <= 100) {
        this.airlineNamelength = false;
      } else {
        this.airlineNamelength = true;
      }
      if (regBoolean) {
        this.airlineNamePattern = false;
      } else {
        this.airlineNamePattern = true;
      }
    }
    if (param === 'airlineFormalName') {
      if (this.airlineSummaryObj.airlineFormalName) {
        this.airlineFormalNameValidation = false;
      } else {
        this.airlineFormalNameValidation = true;
      }

      if (this.airlineSummaryObj.airlineFormalName.length <= 100) {
        this.airlineFormalNamelength = false;
      } else {
        this.airlineFormalNamelength = true;
      }
    }
    if (param === 'airlineWebsite') {
      if (this.airlineSummaryObj.airlineWebsite) {
        this.airlineWebsiteValidation = false;
      } else {
        this.airlineWebsiteValidation = true;
      } 

      if (this.airlineSummaryObj.airlineWebsite.length <= 100) {
        this.airlineWebsitelength = false;
      } else {
        this.airlineWebsitelength = true;
      }
    }
    if (param === 'airlineAnniversary') {
      if (this.airlineSummaryObj.airlineAnniversary.length <= 100) {
        this.airlineAnniversarylength = false;
      } else {
        this.airlineAnniversarylength = true;
      }
    }
    if (param === 'joiningStarAlliance') {
      if (this.airlineSummaryObj.joiningStarAlliance.length <= 100) {
        this.joiningStarAlliancelength = false;
      } else {
        this.joiningStarAlliancelength = true;
      }
    }

    if (param === 'airlineCode') {
      if (this.airlineSummaryObj.airlineCode) {
        this.airlineCodeValidation = false;
      } else {
        this.airlineCodeValidation = true;
      }
      if (this.airlineSummaryObj.airlineCode.length == 2) {
        this.airlineCodelength = false;
      }
      else {
        this.airlineCodelength = true;
      }
    }
    if (param === 'ticketingPlate') {
      if (this.airlineSummaryObj.ticketingPlate.length <= 100) {
      
        this.ticketingPlatelength = false;
      } else {
        this.ticketingPlatelength = true;
        this.ticketingRegexVal=false;
      }
    }
  }

  cancelAction() {
    if (this.airlineSummaryObj && this.airlineSummaryObj.id > 0) {
      let obj = Object.assign({}, this.airlineSummaryObjPrev);
      this.airlineSummaryObj = obj;
    } else {
      this.airlineSummaryObj = new AirlineSummary();
      this.airlineSummaryObj.airlineCode = '';
      this.airlineSummaryObj.airlineName = '';
      this.airlineSummaryObj.airlineFormalName = '';
      this.airlineSummaryObj.airlineLogo = '';
      this.airlineSummaryObj.isMemberAirline = '';
      this.airlineSummaryObj.isCpmAirline = '';
      this.airlineSummaryObj.airlineWebsite = '';
      this.airlineSummaryObj.airlineAnniversary = '';
      this.airlineSummaryObj.joiningStarAlliance = '';
      this.airlineSummaryObj.ticketingPlate = '';
      this.airlineSummaryObj.id = 0;
    }
    this.airlineLogoValidation = false;
    this.airlineNameValidation = false;
    this.airlineLogoPattern = false;
    this.airlineLogolength = false;
    this.airlineNamelength = false;
    this.airlineNamePattern = false;
    this.ticketingRegexVal=false;
    this.isMemberAirlineValidation = false;
    this.isMemberAirlinelength = false;
    this.isMemberAirlinePattern = false;
    this.isCpmAirlineValidation = false;
    this.isCpmAirlinelength = false;
    this.airlineLogoFileValidation = false;
    this.airlineLogoFileSizeValidation = false;
    this.isCpmAirlinePattern = false;
    this.ticketingPlatelength = false;
    this.airlineCodeValidation =false;
    this.airlineFormalNamelength=false;
    this.airlineWebsitelength = false;
    this.airlineCodelength = false;
    if (this.userInfo.userType === 'AUTHOR') {
      this.airlineSummaryObj.airlineCode = this.userInfo.airlineId;
    }
  }
  closeAction() {
    this.airlineSummaryObj.id = 0;
    this.airlineLogoValidation = false;
    this.airlineNameValidation = false;
    this.airlineLogoPattern = false;
    this.airlineLogolength = false;
    this.airlineNamelength = false;
    this.airlineNamePattern = false;
    this.isMemberAirlineValidation = false;
    this.isMemberAirlinelength = false;
    this.ticketingRegexVal=false;
    this.isMemberAirlinePattern = false;
    this.isCpmAirlineValidation = false;
    this.isCpmAirlinelength = false;
    this.isCpmAirlinePattern = false;
    this.airlineLogoFileValidation = false;
    this.airlineLogoFileSizeValidation = false;
    this.ticketingPlatelength = false;
    this.airlineSummaryObj = this.airlineSummaryObjPrev;
    this.airlineCodeValidation =false;
    this.airlineFormalNamelength=false;
    this.airlineWebsitelength = false;
    if (this.pendingAirlineSummaryListPrev) {
      this.pendingAirlineSummaryList = JSON.parse(this.pendingAirlineSummaryListPrev);
    }
    if (this.approvedAirlineSummaryListPrev) {
      this.approvedAirlineSummaryList = JSON.parse(this.approvedAirlineSummaryListPrev);
    }
  }
  approveAction(param) {
    this.airlineSummaryObj = param;
    this.airlineSummaryHelper.airlineSummary = this.airlineSummaryObj;
    this.airlineSummaryHelper.userInfo = this.userInfo;
    if (param.userFlag === 'AAP' || param.userFlag === 'UAP' || param.userFlag === 'ARP' ||
      param.userFlag === 'URP' || (this.userInfo.userType === 'APPROVER' && param.userFlag === 'DAP')) {
      this.airlineSummaryHelper.airlineSummary.userFlag = 'app';
      this.airlineSummaryService.saveApproveRejection(this.airlineSummaryHelper, 'app').subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirlineSummaryList = data['Pending'];
            this.approvedAirlineSummaryList = data['Approved'];
            this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
            this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
            this.statusMsg = 'Approved Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
    else if ((this.userInfo.userType === 'SUPERADMIN' || this.userInfo.userType === 'REVIEWER') &&
      (param.userFlag === 'DRP' || param.userFlag === 'DAP')) {
      this.airlineSummaryService.deleteAction(this.airlineSummaryHelper).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirlineSummaryList = data['Pending'];
            this.approvedAirlineSummaryList = data['Approved'];
            this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
            this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
            this.statusMsg = 'Data Deleted Successfully.';
            $('#statusId').modal('toggle');
          }
        }
      );
    }
    /*  this.airlineSummaryHelper.airlineSummary = param;
     this.airlineSummaryHelper.userInfo = this.userInfo;
     this.airlineSummaryObj.userFlag = 'app';
     this.airlineSummaryService.saveAction(this.airlineSummaryHelper,'app').subscribe(
       data => {
         if(data['Status']){
           this.pendingAirlineSummaryList = data['Pending'];
           this.approvedAirlineSummaryList = data['Approved'];
           this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
           this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
           $('#addedit').modal('toggle');
           this.statusMsg = 'Approved Successfully.';
           $('#statusId').modal('toggle');
         }
       }
     ); */
  }
  rejectAction() {
    if (this.rejectionJustification) {
      this.airlineSummaryHelper.airlineSummary = this.airlineSummaryObj;
      this.airlineSummaryHelper.userInfo = this.userInfo;
      if (this.airlineSummaryObjPrev && this.airlineSummaryObjPrev.id > 0) {
        this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObjPrev;
      } else {
        this.airlineSummaryHelper.airlineSummaryPrev = this.airlineSummaryObj;
      }
      this.airlineSummaryHelper.airlineSummary.userFlag = 'rej';
      this.airlineSummaryService.saveApproveRejection(this.airlineSummaryHelper, this.rejectionJustification).subscribe(
        data => {
          if (data['Status']) {
            this.pendingAirlineSummaryList = data['Pending'];
            this.approvedAirlineSummaryList = data['Approved'];
            this.pendingAirlineSummaryListPrev = JSON.stringify(this.pendingAirlineSummaryList);
            this.approvedAirlineSummaryListPrev = JSON.stringify(this.approvedAirlineSummaryList);
            this.statusMsg = 'Mail Sent';
            $('#statusId').modal('toggle');
            $('#rejectionId').modal('toggle');
          }
        }
      );
    }
    else {
      this.rejectActionCheck = true;
    }
    this.rejectionJustification='';
  }
  navigateToHome() {
    this.router.navigateByUrl('/home');
  }
  paginationTrigger(param) {
    if (param === 'pending') {
      this.pendingPagination = true;
      this.approvedPagination = false;
    } else if (param === 'approved') {
      this.pendingPagination = false;
      this.approvedPagination = true;
    }
  }
  rejectActionTrigger(param) {
    let obj = Object.assign({}, param);
    this.airlineSummaryObjPrev = obj;
    this.airlineSummaryObj = param;
    $('#rejectionId').modal('toggle');
  }
  rejectionCheck() {
    if (this.rejectionJustification) {
      this.rejectActionCheck = false;
    } else {
      this.rejectActionCheck = true;
    }
  }
  resetList() {
    if (this.pendingAirlineSummaryListPrev) {
      this.pendingAirlineSummaryList = JSON.parse(this.pendingAirlineSummaryListPrev);
    }
    if (this.approvedAirlineSummaryListPrev) {
      this.approvedAirlineSummaryList = JSON.parse(this.approvedAirlineSummaryListPrev);
    }
  }
  triggerSearchAirline(param) {
    if (param === 'approved') {
      if (this.approvedAirlineSummaryListPrev) {
        let list = JSON.parse(this.approvedAirlineSummaryListPrev);
        this.approvedAirlineSummaryList = list;
        this.searchData(param);
      }
    }
    if (param === 'pending') {
      if (this.pendingAirlineSummaryListPrev) {
        let list = JSON.parse(this.pendingAirlineSummaryListPrev);
        this.pendingAirlineSummaryList = list;
        this.searchData(param);
      }
    }
  }

ticketingValidation() {
  if (this.airlineSummaryObj.ticketingPlate&& this.ticketingRegex.test(this.airlineSummaryObj.ticketingPlate)) {
    this.ticketingRegexVal = false;
  } else {
    this.ticketingRegexVal = true;
  }
}
}