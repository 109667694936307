export class SpecialCheckedBag {
	public airlineCode: string = '';

	public airlineName: string = '';

	public items: string = '';

	public pieceConcept: string = '';

	public weightConcept: string = '';

	public ssrInterlineable: string = '';

	public ssrRequired: string = '';

	public ssrCode: string = '';

	public packageRequired: string = '';

	public action: string = '';

	public id: number = 0;

	userFlag: string = '';

	createdBy: string = '';

	public parentId: number = 0;
}