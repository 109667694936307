import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public apiUrl: string;
  timer: any;
  constructor(public http: HttpClient, public router: Router) { }

  public postMethod(url, param){
    this.resetMethod();
    return this.http.post( environment.apiUrl+url, param );
  }
  public getMethod (url, param?: any){
    this.resetMethod();
    return this.http.get( environment.apiUrl+url );
  }

  public resetMethod(){
    // clearTimeout(this.timer);
    setTimeout(this.alertFunc, 1800000);
    // setTimeout(this.alertModalTrigger, 3000);
  }

  public alertModalTrigger() {
    $('#sessionId').modal('toggle');
  }

  public alertFunc(param){
    if (param === 'Ok') {
      $('#sessionId').modal('toggle');
      this.resetMethod();
    } else if (param ==='cancel') {
      this.router.navigateByUrl('logout');
    }
  }
}
