import { Component, OnInit } from '@angular/core';
import { CommonScreenService } from 'src/app/services/common-screen.service';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';
declare var $: any;
@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.css']
})
export class HomeScreenComponent implements OnInit {

  aircraftType: boolean;
  ffpBenefits: boolean;
  checkedBag: boolean;
  airlineSummary: boolean;
  carryonbag: boolean;
  ffpinfo: boolean;
  aircraftAtlas: boolean;
  specialCheckedBag: boolean;
  userInfo = new User();
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  oldPwdCheck: boolean;
  confirmPwd: boolean;
  statusMsg: string = '';
  uiNames = [];
  constructor(public commonScreenService: CommonScreenService, public router: Router) {
    // $('body').click(this.profileInfoClose())
    /* $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    }); */
    /* $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    });
     $('[data-toggle="tooltip"]').tooltip({
      trigger : 'hover'
    }); */
    // $('[data-toggle="tooltip"]').tooltip({placement: 'right', html: true, trigger: 'hover'});
  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    if(this.userInfo){
      this.onLoad();
      this.profileInfoClose();
    } else {
      this.router.navigateByUrl('/login');
      sessionStorage.clear();
    }
  }
  profileInfoOpen() {
    document.getElementById('profileInfoId').style.display='block';
    setTimeout(this.profileInfoClose,5000);
  }
  profileInfoClose() {
    document.getElementById('profileInfoId').style.display='none';
  }
  onLoad(){
    this.triggerUIs(this.userInfo);
    this.commonScreenService.loadFunction().subscribe(
      data => {
        sessionStorage.setItem('Airline', JSON.stringify(data['Summary']));
        sessionStorage.setItem('FFPInfo', JSON.stringify(data['FFPOverview']));
      }
    );
  }
  triggerUIs(userInfo: User) {
    let uinames = [];
    uinames = userInfo.uiName.split(';');
    for (let i = 0; i < uinames.length; i++) {
      if(uinames[i]==='ALL'){
        this.uiNames[0]='ALL';
      } else if(uinames[i]==='Aircraft Type'){
        this.uiNames[1]='Aircraft Type';
      } else if(uinames[i]==='Airline Summary'){
        this.uiNames[2]='Airline Summary';
      } else if(uinames[i]==='Airport Atlas'){
        this.uiNames[3]='Airport Atlas';
      } else if(uinames[i]==='Call Center List'){
        this.uiNames[4]='Call Center List';
      } else if(uinames[i]==='Carry-On Bag'){
        this.uiNames[5]='Carry-On Bag';
      } else if(uinames[i]==='Checked Bag'){
        this.uiNames[6]='Checked Bag';
      } else if(uinames[i]==='FFP Benefits'){
        this.uiNames[7]='FFP Benefits';
      } else if(uinames[i]==='FFP Overview'){
        this.uiNames[8]='FFP Overview';
      } else if(uinames[i]==='Special Checked Baggage'){
        this.uiNames[9]='Special Checked Baggage';
      }
    }
  }
  
  navigateTo(param) {
    // $('[data-toggle="tooltip"]').tooltip('hide'); 
    if( param === 'aircrafttype' ){
      this.router.navigateByUrl('/home/aircraftType');
    } else if ( param === 'checkedbag' ) {
      this.router.navigateByUrl('/home/checkedBag');
    } else if ( param === 'ffpbenefits' ) {
      this.router.navigateByUrl('/home/ffpBenefits');
    } else if ( param === 'airlineSummary' ) {
      this.router.navigateByUrl('/home/airlineSummary');
    } else if ( param === 'carryonbag' ) {
      this.router.navigateByUrl('/home/carryOnBag');
    } else if ( param === 'ffpinfo' ) {
      this.router.navigateByUrl('/home/ffpInformation');
    } else if ( param === 'aircraftatlas' ) {
      this.router.navigateByUrl('/home/airportAtlas');
    } else if ( param === 'splcheckedbag' ) {
      this.router.navigateByUrl('/home/specialCheckedBag');
    } else if ( param === 'callcenter' ) {
      this.router.navigateByUrl('/home/callCenterList');
    } else if ( param === 'userInfo' ) {
      this.router.navigateByUrl('/home/userInformation');
    }
  }
  Ok() {
    this.commonScreenService.alertFunc('Ok');
  }
  close() {
    this.commonScreenService.alertFunc('cancel');
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
  changePasswordAction() {
    if(this.userInfo.userPassword === this.oldPassword && this.newPassword === this.confirmPassword){
      this.userInfo.userPassword = this.newPassword;
      this.commonScreenService.changePasswordAction(this.userInfo).subscribe(
        data => {
          if(data['Status']){
            this.statusMsg = 'Password changed successfully.';
            $('#changePasswordId').modal('toggle');
            $('#statusId').modal('toggle');
          }
        }
      );
    } else {
      if(this.userInfo.userPassword !== this.oldPassword ){
        this.oldPwdCheck = true;
      } else {
        this.oldPwdCheck = false;
      }
      if(this.newPassword !== this.confirmPassword) {
        this.confirmPwd = true;
      } else {
        this.confirmPwd = false;
      }
    }
  }
  navigateToLogin() {
    this.router.navigateByUrl('');
  }
}
